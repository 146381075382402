import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      order = 'asc',
      sort = '',
      search = '',
    } = options;

    const params = {
      page,
      order,
      sort,
      search,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/uris`,
      params,
    });
  },
  createItem(uri) {
    return axios({
      method: 'post',
      url: `${apiUrl}/uris`,
      data: {
        ...uri,
      },
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/uris/${id}`,
    });
  },
  getActivityLogs({ page = 1, perPage = maxItemsPerPage, id }) {
    const params = {
      page,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/uris/${id}/activities`,
      params,
    });
  },
};
