import Vue from 'vue';
import isPlainObject from 'lodash/isPlainObject';

export const canFlatten = (obj) => !Object.keys(obj).some(key => typeof obj[key] === 'function');

const identityFilter = () => true;

export const flatten = ( obj, canFlattenObj = identityFilter, path = '', accumulator = {} ) =>
  Object.keys(obj).reduce((flattened, key) => {
    const keyPath = `${path ? path + '.' : ''}${key}`;

    if (isPlainObject(obj[key]) && canFlatten(obj[key])) {
      flatten(obj[key], canFlattenObj, keyPath, flattened);
    } else {
      // eslint-disable-next-line no-param-reassign
      flattened[keyPath] = obj[key];
    }

    return flattened;
  }, accumulator);

export const cloneJson = (obj) => JSON.parse(JSON.stringify(obj));

export const dotSelector = (data, selector, defaultValue = null,) =>
  selector.split('.').reduce((traversed, current) => traversed && traversed[current], data || {}) || defaultValue;

export function assignReactive( source, assignments ) {
  let i;
  // eslint-disable-next-line no-restricted-syntax
  for (i in assignments) {
    if (Array.isArray(source[i]) || (typeof source[i] === 'object' && !!source[i])) {
      assignReactive(source[i], assignments[i]);
    } else {
      Vue.set(source, i, assignments[i]);
    }
  }
  return source;
}

// fast alternative to lodash/isEqual for flat objects
export function equalObjFlat(obj1, obj2) {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  // eslint-disable-next-line no-restricted-syntax,no-unused-vars
  for (const key of keys1) {
    if (!(key in obj2) || obj1[key] !== obj2[key]) return false;
  }

  return true;
}

export function assignPrefixed(target, source, prefix) {
  if (source) {
    // eslint-disable-next-line no-restricted-syntax,no-unused-vars
    for (const [key, value] of Object.entries(source)) {
      // eslint-disable-next-line no-param-reassign
      target[prefix + key] = value;
    }
  }

  return target;
}
