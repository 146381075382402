<template>
  <div data-component="enhanced-editor" :class="getComponentClass()">
    <div v-if="hasLabel" class="label">
      {{ label }}
    </div>
    <base-text-editor
      :value="value"
      :config="config"
      :height="height"
      :disabled="disabled"
      @input="onChange"
      @tbw-blur="onBlur"
      @tbw-focus="onFocus"
    />
    <div v-if="hasErrors" class="error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        default: null,
        required: true,
        validator(value) {
          return (
            value === null || typeof value === 'string' || value instanceof String
          );
        },
      },
      config: {
        type: Object,
        default: () => ({}),
      },
      height: {
        type: Number,
        default: 300,
      },
      label: {
        type: String,
        default: '',
      },
      errorMessages: {
        type: Array,
        default() {
          return [];
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isFocused: false,
      };
    },
    computed: {
      hasErrors() {
        return this.errorMessages.length > 0;
      },
      errorMessage() {
        return this.errorMessages[0];
      },
      hasLabel() {
        return this.label !== '';
      },
    },
    methods: {
      onChange(value) {
        this.$emit('input', value);
      },
      onBlur() {
        this.$emit('blur');
        this.isFocused = false;
      },
      onFocus() {
        this.$emit('focus');
        this.isFocused = true;
      },
      getComponentClass() {
        return {
          'v-text-field': true,
          'v-input--has-state': true,
          'error--text': this.hasErrors,
          'v-input--is-focused': this.isFocused,
        };
      },
    },
  };
</script>

<style lang="scss">
@import '../assets/styles/theme';

[data-component='enhanced-editor'] {
  position: relative;
  margin: 0 0 24px 0;
  padding: 0;

  &.error--text {
    .trumbowyg-box {
      border-color: map-get($colors, error);;
    }
  }

  .label {
    font-size: $text-size-sm;
  }

  .error-message {
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: $text-size-sm;
    color: map-get($colors, error);
  }
}
</style>
