<template>
  <div data-component-group="tabs" data-component="auth">
    <div class="wrapper">
      <v-list class="list">
        <v-flex class="d-inline-flex">
          <template v-for="item in items">
            <v-list-item
              :key="item.name"
              :class="getListClass(item)"
              @click="redirect(item.redirect)"
            >
              <v-icon>
                {{ item.icon }}
              </v-icon>

              <v-list-item-content>
                <!-- eslint-disable -->
                <v-list-item-title v-html="item.label" />
                <!-- eslint-enable -->
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-flex>
      </v-list>
    </div>
  </div>
</template>

<script>
  import items from '../../config/authTabs';

  const { location } = window;

  export default {
    data() {
      return {
        items,
      };
    },
    created() {
      const { path } = this.$route;
      this.items = this.updateItems(this.items, path);
    },
    methods: {
      updateItems: (items, path) =>
        items.map((item) => {
          const selectedPaths = item.paths.filter(
            (itemPath) => path.indexOf(itemPath) > -1,
          );

          return {
            ...item,
            isSelected: selectedPaths.length > 0,
          };
        }),
      getListClass(item) {
        return {
          active: item.isSelected,
          'list-tile': true,
        };
      },
      redirect(path) {
        if (location.pathname === path) {
          return false;
        }

        this.$router.push(path).catch((err) => err);
      },
    },
  };
</script>

<style lang="scss" scoped>
@import '../../assets/styles/theme';

[data-component-group='tabs'][data-component='auth'] {
  position: relative;
  width: 100%;

  .wrapper {
    text-align: center;

    .list {
      width: 100%;
      display: inline-block;
      background-color: transparent;
      padding: 0;

      :not(:first-child) {
        margin: 0 0 0 $spacing-xs;
      }

      > .flex {
        justify-content: center;
        align-items: center;
        width: auto;
      }

      .list-tile {
        width: 270px;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        background-color: map-deep-get($colors, grey, darken-3);

        .v-list-item__content > .v-btn {
          min-width: 32px;
        }

        &.active {
          background-color: map-deep-get($colors, blue-grey, lighten-5);

          .v-icon,
          .v-list-item__title {
            color: map-deep-get($colors, grey, darken-3);
          }

          .v-list-item__title {
            font-weight: 600;
          }
        }

        .v-icon,
        .v-list-item__title {
          color: map-deep-get($colors, shades, 'white');
        }

        .v-icon:not(.arrow) {
          @media (max-width: map-get($breakpoints, sm)) {
            display: none;
          }
        }

        .v-list-item__title {
          margin: 0;
          text-align: left;

          @media (max-width: map-get($breakpoints, xs)) {
            font-size: $text-size-sm;
          }
        }

        @media (max-width: map-get($breakpoints, sm)) {
          :first-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          :last-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }
}
</style>
