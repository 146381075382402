import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const paymentsApi = apiClient.merchy.payments;

/**
 * @description Is valid
 * @param payments
 * @returns {boolean}
 */
const isValid = (payments) => {
  const { activeItem, items, itemsMeta, statuses } = payments;

  return (
    isObj(payments) &&
    isObj(activeItem) &&
    isArr(items) &&
    isObj(itemsMeta) &&
    isArr(statuses)
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial payments state');
  }

  const { activeItem, items, itemsMeta, statuses } = initialState;
  return {
    activeItem,
    items,
    itemsMeta,
    statuses,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  activeItem: ({ activeItem }) => activeItem,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
  statuses: ({ statuses }) => statuses,
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') =>
  store.dispatch('alerts/set', {
    data,
    alertType,
  });

const actions = {
  update: (context, query) => {
    return paymentsApi.updateItem(query).then((res) => {
      const { id } = query;

      handleAlerts(
        {
          data: {
            message: `Discount for payment with ID "${id}" has been updated successfully.`,
          },
        },
        res.data.status,
      );

      return res.data;
    })
  },
  getItem: ({ commit, state }, query) =>
    paymentsApi.getItem(query).then((res) => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...res.data.data,
        },
      };

      return commit('SET', nextState);
    }),
  getItems: ({ commit, state }, query) =>
    paymentsApi.getItems(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = data.map((item) => {
        const obj = {...item}
        if (item?.contract?.account?.legal_business_name) {
          obj.legal_business_name = item.contract.account.legal_business_name;
        } else {
          obj.legal_business_name = 'N/A';
        }
        return obj;
      });

      const nextState = {
        ...state,
        items: nextItems,
        itemsMeta: meta,
      };

      return commit('SET', nextState);
    }),
  getStatuses: ({ commit, state }) =>
    paymentsApi.getStatuses().then((res) => {
      const nextStatuses = _.sortBy(res.data.data, (status) =>
        status.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        statuses: nextStatuses,
      };

      return commit('SET', nextState);
    }),
  setActiveItem: ({ commit, state }, payment) => {
    const nextState = {
      ...state,
      activeItem: {
        ...state.activeItem,
        ...payment,
      },
    };

    return commit('SET', nextState);
  },
  reset: ({ commit }) => commit('SET', initialState.payments),
  set: ({ commit }, payments) => {
    commit('SET', payments);
  },
};

const mutations = {
  SET(state, payments) {
    /* eslint-disable no-param-reassign */
    state.activeItem = payments.activeItem;
    state.items = payments.items;
    state.itemsMeta = payments.itemsMeta;
    state.statuses = payments.statuses;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
