import { eachKey, isObj, isStr } from './index';

/**
 * @description Add alerts
 * @param res
 * @param alertType
 */
const addAlerts = (res, alertType = 'error') => {
  const data = res.data || { message: res.message };
  let nextAlerts = [];
  const hasInternalData = isObj(data.data);

  if (!hasInternalData || !isObj(data.data.validations)) {
    nextAlerts = [
      {
        type: 'general',
        alertType,
        params: res.params,
        message:
          !hasInternalData || !isStr(data.data.message)
            ? data.message
            : data.data.message,
      },
    ];
  } else {
    eachKey(data.data.validations, (type, messages) => {
      nextAlerts.push({
        type,
        alertType,
        params: res.params,
        message: messages[0],
      });
    });
  }

  return nextAlerts;
};

export default addAlerts;
