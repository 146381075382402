<template>
  <div data-component="edit-dialog">
    <UrlSwitch :path="to" :active="isOn" />

    <base-dialog
      :content-class="dialogClass"
      :max-width="width"
      :is-on="isOn"
      :is-fullscreen="isFullscreen"
      :on-close="onClose"
    >
      <template slot="title">
        <div class="item-title">
          <div :class="getTriggerClass()">
            <v-icon>{{ icon }}</v-icon>

            <h1>{{ title }}</h1>
          </div>

          <span class="item-url">
            <CopyToClipboard :text="itemUrl" />
          </span>
        </div>

        <v-btn class="trigger close-dialog elevation-0" @click="onCloseDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </template>

      <div slot="content" class="slot-wrap container content grid-list-lg">
        <slot name="content" />
      </div>
    </base-dialog>
  </div>
</template>

<script>
  import UrlSwitch from '../UrlSwitch';
  import CopyToClipboard from '../CopyToClipboard';
  import { isStr, isFunc } from '../../utils';

  const { location } = window;

  export default {
    components: {
      UrlSwitch,
      CopyToClipboard,
    },
    props: {
      icon: {
        type: String,
        default: 'edit',
      },
      title: {
        type: String,
        default() {
          return this.$t('edit_item');
        },
      },
      to: {
        type: String,
        default: '/',
      },
      width: {
        type: Number,
        default: 640,
      },
      contentClass: {
        type: String,
        default: '',
      },
      isError: {
        type: Boolean,
        default: false,
      },
      isOn: {
        type: Boolean,
        default: false,
      },
      isFullscreen: {
        type: Boolean,
        default: false,
      },
      onClose: {
        type: Function,
        default: null,
      },
    },
    computed: {
      dialogClass() {
        const { contentClass } = this;
        const baseClass = 'edit-item-dialog';

        return this.hasContentClass ? `${baseClass} ${contentClass}` : baseClass;
      },
      itemUrl() {
        return `${location.origin}${this.to}`;
      },
      hasContentClass() {
        const { contentClass } = this;
        return isStr(contentClass) && contentClass !== '';
      },
      hasOnClose() {
        return isFunc(this.onClose);
      },
    },
    methods: {
      onCloseDialog() {
        if (this.hasOnClose) {
          this.onClose();
        }
      },
      getTriggerClass() {
        return {
          'view-item': true,
          'is-blocked': this.isError,
        };
      },
    },
  };
</script>

<style lang="scss">
@import '../../assets/styles/theme';

.edit-item-dialog > .modal__backdrop > .modal__content {
  width: 640px;
  max-width: 100%;
  margin: 0 auto;
  background: map-deep-get($colors, grey, lighten-5);

  > .modal__header {
    margin: 0 auto;
    color: map-deep-get($colors, shades, 'white');

    .item-title {
      .view-item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding: 0;
        margin: 0;
        cursor: default;

        .v-icon {
          font-size: $text-size-xl + 0.5rem;
          color: map-deep-get($colors, shades, 'white');
        }

        h1 {
          font-weight: 500;
          margin-left: $spacing-md;
        }

        &.is-blocked {
          color: map-get($colors, error);

          .v-icon {
            color: map-get($colors, error);
          }

          .title-wrapper h1 {
            color: map-get($colors, error);
          }
        }
      }

      .item-url {
        display: block;
        margin: 0 0 0 (-$spacing-lg);

        .v-btn {
          height: auto;
          text-transform: none;
          transition: 0.15s ease-in-out;

          .v-icon {
            transition: 0.15s ease-in-out;
          }
        }
      }
    }

    .trigger.close-dialog {
      width: 60px;
      min-width: auto;
      height: 60px;
      border-radius: 50%;
      padding: 0;
      margin: 0 $spacing-xs 0 0;
      background-color: transparent;

      .v-icon {
        margin: 0;
        font-size: $text-size-xl + 0.5rem;
        color: map-deep-get($colors, shades, 'white');
      }

      &:before {
        background-color: transparent;
      }
    }
  }

  > .modal__body {
    > * {
      padding: initial;
    }
  }
}
</style>
