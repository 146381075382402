// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const cannedRepliesApi = apiClient.merchy.cannedReplies;

/**
 * @description Is valid
 * @param cannedReplies
 * @returns {boolean}
 */
const isValid = (cannedReplies) => {
  const { activeItem, items, itemsMeta } = cannedReplies;

  return (
    isObj(cannedReplies) && isObj(activeItem) && isArr(items) && isObj(itemsMeta)
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial cannedReplies state');
  }

  const { activeItem, items, itemsMeta } = initialState;
  return {
    activeItem,
    items,
    itemsMeta,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  activeItem: ({ activeItem }) => activeItem,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
};

/**
 * @description Handle alerts
 * @param message
 * @param alertType
 */
const handleAlerts = (message, alertType = 'success') => {
  const alert = {
    data: {
      message,
    },
  };

  store.dispatch('alerts/set', {
    data: alert,
    alertType,
  });
};

const actions = {
  create: (context, comment) =>
    cannedRepliesApi.createItem(comment).then((res) => {
      handleAlerts('Comment created successfully.');
      return res.data.data;
    }),
  update: ({ commit, state }, comment) =>
    cannedRepliesApi.updateItem(comment).then((res) => {
      const { activeItem } = state;
      const nextComment = res.data.data;
      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextComment,
        },
      };

      commit('SET', nextState);

      handleAlerts('Comment updated successfully.');
      return nextComment;
    }),
  remove: (context, comment) =>
    cannedRepliesApi.removeItem(comment).then((res) => {
      handleAlerts('Comment removed successfully.');
      return res.data.data;
    }),
  getItem: ({ commit, state }, query) =>
    cannedRepliesApi.getItem(query).then((res) => {
      const { activeItem } = state;
      const nextActiveItem = res.data.data;
      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextActiveItem,
        },
      };

      commit('SET', nextState);
      return nextActiveItem;
    }),
  getItems: ({ commit, state }, query) =>
    cannedRepliesApi.getItems(query).then((res) => {
      const { data, meta } = res.data;
      const itemsData = {
        items: data,
        itemsMeta: meta,
      };
      const nextState = {
        ...state,
        ...itemsData,
      };

      commit('SET', nextState);
      return itemsData;
    }),
  setActiveItem: ({ commit, state }, nextActiveItem) => {
    const nextState = {
      ...state,
      activeItem: {
        ...state.activeItem,
        ...nextActiveItem,
      },
    };

    commit('SET', nextState);
    return nextActiveItem;
  },
  reset: ({ commit }) => commit('SET', initialState.cannedReplies),
  set: ({ commit }, cannedReplies) => {
    commit('SET', cannedReplies);
  },
};

const mutations = {
  SET(state, cannedReplies) {
    /* eslint-disable no-param-reassign */
    state.items = cannedReplies.items;
    state.itemsMeta = cannedReplies.itemsMeta;
    state.activeItem = cannedReplies.activeItem;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
