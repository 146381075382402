// eslint-disable-next-line import/no-cycle
import store from '../../store';
import { isNonEmptyArr, isNonEmptyStr } from '../../utils';

export default [
  {
    path: '/register',
    name: 'register',
    meta: {
      loadState: true,
      auth: false,
      isAuthPage: true,
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/auth/ConfirmBusiness'
      ),
  },
  {
    path: '/register/new/:token',
    name: 'register-new',
    meta: {
      loadState: true,
      auth: false,
      isAuthPage: true,
      isTokenProtected: true,
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/auth/Register'
      ),
    beforeEnter: (to, from, next) => {
      const { token } = to.params;

      return store
        .dispatch('auth/isValidRegistrationToken', token)
        .then((res) => {
          const { businesses } = res.data.data;

          if (!isNonEmptyArr(businesses.length)) {
            return next();
          }

          return next(`/register/second/${token}`);
        })
        .catch(() => next('/login'));
    },
  },
  {
    path: '/register/invited/:token',
    name: 'register-invited',
    meta: {
      loadState: true,
      auth: false,
      isAuthPage: true,
      isTokenProtected: true,
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/auth/Invite'
      ),
    beforeEnter: (to, from, next) => {
      const { token } = to.params;

      return store
        .dispatch('auth/isValidInvitationToken', token)
        .then((res) => {
          const { business } = res.data.data;

          if (isNonEmptyStr(business)) {
            return next();
          }

          return next('/login');
        })
        .catch(() => next('/login'));
    },
  },
  {
    path: '/register/second/:token',
    name: 'register-second',
    meta: {
      loadState: true,
      auth: false,
      isAuthPage: true,
      isTokenProtected: true,
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/auth/JoinAccount'
      ),
    beforeEnter: (to, from, next) => {
      const { token } = to.params;

      return store
        .dispatch('auth/isValidRegistrationToken', token)
        .then((res) => {
          const { businesses } = res.data.data;

          if (isNonEmptyArr(businesses)) {
            return next();
          }

          return next(`/register/new/${token}`);
        })
        .catch(() => next('/login'));
    },
  },
];
