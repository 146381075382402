import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const contractsApi = apiClient.merchy.contracts;

/**
 * @description Is valid
 * @param contracts
 * @returns {boolean}
 */
const isValid = (contracts) => {
  const { activeItem, items, itemsMeta, expiringItems, expiringItemsMeta, contractTypes, statuses, paymentTypes } = contracts;

  const isValidActiveItem = isObj(activeItem)
    && isObj(activeItem.log)
    && isArr(activeItem.log.items)
    && isObj(activeItem.log.meta)
    && isArr(activeItem.prices);

  return isObj(contracts)
    && isArr(items)
    && isObj(itemsMeta)
    && isArr(expiringItems)
    && isObj(expiringItemsMeta)
    && isArr(contractTypes)
    && isArr(statuses)
    && isArr(paymentTypes)
    && isValidActiveItem;
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = initialState => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial contracts state');
  }

  const { activeItem, items, itemsMeta, expiringItems, expiringItemsMeta, contractTypes, statuses, paymentTypes } = initialState;
  return {
    activeItem,
    items,
    itemsMeta,
    expiringItems,
    expiringItemsMeta,
    contractTypes,
    statuses,
    paymentTypes
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  activeItem: ({ activeItem }) => activeItem,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
  expiringItems: ({ expiringItems }) => expiringItems,
  expiringItemsMeta: ({ expiringItemsMeta }) => expiringItemsMeta,
  expiringItemsData: ({ expiringItems, expiringItemsMeta }) => ({
    items: expiringItems,
    itemsMeta: expiringItemsMeta
  }),
  contractTypes: ({ contractTypes }) => contractTypes,
  statuses: ({ statuses }) => statuses,
  paymentTypes: ({ paymentTypes }) => paymentTypes
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') => (
  store.dispatch('alerts/set', {
    data,
    alertType
  })
);

const actions = {
  create: (context, query) => (
    contractsApi.createItem(query).then(() => (
      handleAlerts({
        data: {
          message: 'Contract created successfully.'
        }
      }, 'success')
    ))
  ),
  update: ({ commit, state }, payment) => (
    contractsApi.updateItem(payment).then((res) => {
      const { activeItem } = state;
      const nextContract = res.data.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextContract
        }
      };

      commit('SET', nextState);

      return handleAlerts({
        data: {
          message: 'Contract updated successfully.'
        }
      }, 'success');
    })
  ),
  getRedirect: ({ commit, state }, query) => (
    contractsApi.getRedirect(query).then((res) => {
      const { activeItem } = state;
      const nextActiveItem = {
        ...activeItem,
        ...res.data.data
      };

      const nextState = {
        ...state,
        activeItem: nextActiveItem
      };

      commit('SET', nextState);

      return nextActiveItem;
    })
  ),
  getItem: ({ commit, state }, query) => (
    contractsApi.getItem(query).then((res) => {
      const { activeItem } = state;
      const nextActiveItem = {
        ...activeItem,
        ...res.data.data
      };

      const nextState = {
        ...state,
        activeItem: nextActiveItem
      };

      commit('SET', nextState);

      return nextActiveItem;
    })
  ),
  getItems: ({ commit, state }, query) => {
    return contractsApi.getItems(query).then((res) => {
      const {data, meta} = res.data;

      const nextItems = {
        items: data,
        itemsMeta: meta
      };

      const nextState = {
        ...state,
        ...nextItems
      };

      commit('SET', nextState);

      return nextItems;
    })
  },
  getDomains: (context, query) =>
    contractsApi.getDomains(query).then((res) => res.data.data),
  getExpiringItems: ({ commit, state }, query) => (
    contractsApi.getItems(query).then((res) => {
      const { data, meta } = res.data;

      const nextState = {
        ...state,
        expiringItems: data,
        expiringItemsMeta: meta
      };

      return commit('SET', nextState);
    })
  ),
  getContractTypes: ({ commit, state }) => (
    contractsApi.getContractTypes().then((res) => {
      const nextContractTypes = res.data.data;
      const nextState = {
        ...state,
        contractTypes: nextContractTypes
      };

      return commit('SET', nextState);
    })
  ),
  getStatuses: ({ commit, state }) => (
    contractsApi.getStatuses().then((res) => {
      const nextStatuses = _.sortBy(res.data.data, status => status.name.toLowerCase());
      const nextState = {
        ...state,
        statuses: nextStatuses
      };

      return commit('SET', nextState);
    })
  ),
  getPaymentTypes: ({ commit, state }) => (
    contractsApi.getPaymentTypes().then((res) => {
      const nextPaymentTypes = _.sortBy(res.data.data, status => status.name.toLowerCase());
      const nextState = {
        ...state,
        paymentTypes: nextPaymentTypes
      };

      return commit('SET', nextState);
    })
  ),
  setActiveItem: ({ commit, state }, contract) => {
    const nextState = {
      ...state,
      activeItem: {
        ...state.activeItem,
        ...contract
      }
    };

    return commit('SET', nextState);
  },
  updateDiscount: (context, query) => (
    contractsApi.updateDiscount(query)
  ),
  setBilling: ({ commit, state }, query) => (
    contractsApi.setBilling(query).then((res) => {
      const { activeItem } = state;
      const nextContract = res.data.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextContract
        }
      };

      commit('SET', nextState);

      return handleAlerts({
        data: {
          message: 'Contract billing updated successfully.'
        }
      }, 'success');
    })
  ),
  setDomain: ({ commit, state }, query) => (
    contractsApi.setDomain(query).then((res) => {
      const { activeItem } = state;
      const nextDomain = res.data.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          domain: nextDomain
        }
      };

      commit('SET', nextState);

      return handleAlerts({
        data: {
          message: 'Contract tracking updated successfully.'
        }
      }, 'success');
    })
  ),
  getActivityLogs: ({ commit, state }, query) => (
    contractsApi.getActivityLogs(query).then((res) => {
      const { activeItem } = state;
      const { data, meta } = res.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          log: {
            items: data,
            meta
          }
        }
      };

      return commit('SET', nextState);
    })
  ),
  addCapAmount: ({ commit, state }, query) => {
    return contractsApi.addCapAmount(query).then((res) => {
        const { activeItem } = state;
        const newCapAmount = res.data.data;
        const nextState = {
          ...state,
          activeItem: {
            ...activeItem,
            capping_discount: {
            amount: newCapAmount.amount,
            expire_at: newCapAmount.expire_at
            }
          }
        };
        commit('SET', nextState);

        handleAlerts({
          data: {
            message: activeItem.capping_discount ? 'Capping amount updated successfully.' : 'Capping amount added successfully'
          }
        }, 'success');

        return res.data.data;
      })
  },
  removeCapAmount: ({ commit, state }, id) => {
    return contractsApi.removeCapAmount(id).then(res => {
        const { activeItem } = state;

        const nextState = {
          ...state,
          activeItem: {
            ...activeItem,
            capping_discount: null,
          }
        };
        commit('SET', nextState);

        handleAlerts({
          data: {
            message: 'Capping amount removed successfully.'
          }
        }, 'success');

        return res;
      })
  },
  getPrices: ({ commit, state }, query) => (
    contractsApi.getPrices(query).then((res) => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          prices: res.data.data
        }
      };

      return commit('SET', nextState);
    })
  ),
  addPrice: ({ commit, state }, query) => (
    contractsApi.addPrice(query).then(res => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          prices: res.data.data
        }
      };

      commit('SET', nextState);

      return handleAlerts({
        data: {
          message: 'Contract price added successfully.'
        }
      }, 'success');
    })
  ),
  removePrice: ({ commit, state }, query) => (
    contractsApi.removePrice(query).then(res => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          prices: res.data.data
        }
      };

      commit('SET', nextState);

      return handleAlerts({
        data: {
          message: 'Contract price removed successfully.'
        }
      }, 'success');
    })
  ),
  reset: ({ commit }) => (
    commit('SET', initialState.contracts)
  ),
  set: ({ commit }, contracts) => {
    commit('SET', contracts);
  }
};

const mutations = {
  SET(state, contracts) {
    /* eslint-disable no-param-reassign */
    state.activeItem = contracts.activeItem;
    state.items = contracts.items;
    state.itemsMeta = contracts.itemsMeta;
    state.expiringItems = contracts.expiringItems;
    state.expiringItemsMeta = contracts.expiringItemsMeta;
    state.contractTypes = contracts.contractTypes;
    state.statuses = contracts.statuses;
    state.paymentTypes = contracts.paymentTypes;
  }
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
