<template>
  <div data-component-group="dialog" data-component="confirm">
    <base-dialog :content-class="contentClass" :is-on="isOn">
      <template slot="title">
        <span>
          {{ text }}
        </span>
      </template>

      <template slot="actions">
        <button-custom color="secondary" @click="onCancel">
          {{ cancelLabel }}
        </button-custom>

        <button-custom color="primary" :loading="isLoading" @click="onConfirm">
          {{ confirmLabel }}
        </button-custom>
      </template>
    </base-dialog>
  </div>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        required: true,
      },
      cancelLabel: {
        type: String,
        default() {
          return this.$t('cancel');
        },
      },
      confirmLabel: {
        type: String,
        default() {
          return this.$t('ok');
        },
      },
      contentClass: {
        type: String,
        default: 'confirm-dialog',
      },
      isOn: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      onCancel: {
        type: Function,
        required: true,
      },
      onConfirm: {
        type: Function,
        required: true,
      },
    },
  };
</script>

<style lang="scss">
@import '../../assets/styles/theme';

.confirm-dialog > .modal__backdrop > .modal__content {
  width: 420px;
  max-width: 100%;
  margin: 0 auto;
  background: map-deep-get($colors, grey, lighten-5);

  > .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 auto;
    color: map-deep-get($colors, shades, 'black');
    background-color: map-deep-get($colors, shades, 'white');

    span {
      font-size: $text-size-lg;
      font-weight: 500;
    }
  }

  > .modal__footer {
    display: flex;
    justify-content: flex-end;
    padding: $spacing-sm;
    background-color: map-deep-get($colors, shades, 'white');

    .v-btn {
      margin: 0;

      &:last-child {
        margin: 0 0 0 $spacing-sm;
      }
    }
  }
}
</style>
