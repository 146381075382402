<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :style="`fill: url('#${randomId1}')`" fill-rule="evenodd" clip-rule="evenodd" d="M12.5488 1.87366C12.5488 1.56613 12.612 1.27583 12.7061 1C11.3437 1.80804 8.51406 3.86122 8.51406 6.98883C8.51406 12.1251 12.4958 12.461 12.4958 15.4502C12.4958 16.7125 11.58 17.4027 10.3721 17.4027C8.38267 17.4027 5.98551 14.3802 7.45279 10.5035C7.45336 10.5035 4.00226 12.0829 4.00226 15.5147C4.00226 19.8641 7.77535 23 12.4298 23C17.0849 23 20.8579 19.4742 20.8579 15.1248C20.8579 8.47533 14.589 5.7459 13.0817 3.47695C12.7501 3.02482 12.5488 2.47371 12.5488 1.87366Z"/>
    <path :style="`fill: url('#${randomId2}')`" fill-rule="evenodd" clip-rule="evenodd" d="M4.42236 17.9704C5.95781 21.3815 10.6214 22.4034 12.9027 20.1949C14.775 18.382 13.6118 15.6978 11.8129 13.4949C12.2218 14.0529 12.5008 14.6584 12.5008 15.4494C12.5008 16.7162 11.5834 17.4089 10.3728 17.4089C8.37966 17.4089 5.97759 14.3759 7.44736 10.4856C7.44736 10.4856 4.74937 11.7245 4.12077 14.3884C4.0904 14.8056 4.04873 15.326 4 15.9035C4.03814 16.6332 4.18505 17.3245 4.42236 17.9704Z"/>
    <defs>
      <linearGradient :id="randomId1" x1="9.85699" y1="4.07108" x2="24.2497" y2="26.0428" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFA02F"/>
        <stop offset="1" stop-color="#E00034"/>
      </linearGradient>
      <linearGradient :id="randomId2" x1="10.8352" y1="20.6323" x2="5.0985" y2="6.02856" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFA02F"/>
        <stop offset="1" stop-color="#E00034"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
// random ids are fixing the issue of the same id for multiple components.
// the issue is that when the first image with the same id is hidden, the
// gradient is disappearing for all the other images with the same id.
export default {
  data() {
    return {
      randomId1: `fire1-${Math.random()}`,
      randomId2: `fire2-${Math.random()}`,
    };
  },
};
</script>
