export default [
  {
    path: '/invoices',
    name: 'invoices',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'invoice',
          actions: ['list-all'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import( '../../views/invoices/Index'),
  },
  {
    path: '/invoices/:id(\\d+)',
    name: 'invoice-details',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'invoice',
          actions: ['view'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/invoices/Single'
      ),
  },
  {
    path: '/invoice/download/:token/:source/:emailId?',
    name: 'invoice-download',
    meta: {
      loadState: true,
      auth: false,
      isAuthPage: false,
      permissions: [],
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/invoices/Download'
      ),
  },
];
