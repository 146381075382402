// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
import initialState from '../initialState';

const tasksApi = apiClient.merchy.tasks;

/**
 * @description Is valid
 * @param tasks
 * @returns {boolean}
 */
const isValid = (tasks) => {
  const { items, itemsMeta, filters, statuses, types, actions } = tasks;

  return (
    isArr(items) &&
    isObj(itemsMeta) &&
    isArr(filters) &&
    isArr(statuses) &&
    isArr(types) &&
    isArr(actions)
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial tasks state');
  }

  const { items, itemsMeta, filters, statuses, types, actions } = initialState;
  return {
    items,
    itemsMeta,
    filters,
    statuses,
    types,
    actions,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  filters: ({ filters }) => filters,
  statuses: ({ statuses }) => statuses,
  types: ({ types }) => types,
  actions: ({ actions }) => actions,
};

const actions = {
  getItems: ({ commit, state }, query) =>
    tasksApi.getItems(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = {
        items: data,
        itemsMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);

      return nextItems;
    }),
  getItem: (context, query) =>
    tasksApi.getItem(query).then((res) => res.data.data),
  createItem: (context, query) =>
    tasksApi.createItem(query).then((res) => res.data.data),
  updateItem: (context, query) =>
    tasksApi.updateItem(query).then((res) => res.data.data),
  updateStatus: (context, query) =>
    tasksApi.updateStatus(query).then((res) => res.data.data),
  getTaskComments: (context, query) =>
    tasksApi.getTaskComments(query).then((res) => ({
      comments: res.data.data,
      meta: res.data.meta,
    })),
  addTaskComment: (context, query) =>
    tasksApi.addTaskComment(query).then((res) => res.data.data),
  getFilters: ({ commit, state }) =>
    tasksApi.getFilters().then((res) => {
      const nextFilters = res.data.data;
      const nextState = {
        ...state,
        filters: nextFilters,
      };

      commit('SET', nextState);

      return nextFilters;
    }),
  getStatuses: ({ commit, state }) =>
    tasksApi.getStatuses().then((res) => {
      const nextStatuses = res.data.data;
      const nextState = {
        ...state,
        statuses: nextStatuses,
      };

      commit('SET', nextState);

      return nextStatuses;
    }),
  getTypes: ({ commit, state }) =>
    tasksApi.getTypes().then((res) => {
      const nextTypes = res.data.data;
      const nextState = {
        ...state,
        types: nextTypes,
      };

      commit('SET', nextState);

      return nextTypes;
    }),
  getActions: ({ commit, state }, query) =>
    tasksApi.getActions(query).then((res) => {
      const nextActions = res.data.data;
      const nextState = {
        ...state,
        actions: nextActions,
      };

      commit('SET', nextState);

      return nextActions;
    }),
  reset: ({ commit }) => commit('SET', initialState.tasks),
  set: ({ commit }, tasks) => {
    commit('SET', tasks);
  },
};

const mutations = {
  SET(state, tasks) {
    /* eslint-disable no-param-reassign */
    state.items = tasks.items;
    state.itemsMeta = tasks.itemsMeta;
    state.filters = tasks.filters;
    state.statuses = tasks.statuses;
    state.types = tasks.types;
    state.actions = tasks.actions;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
