import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      sort = '',
      order = 'asc',
      search = '',
      name = '',
    } = options;

    const params = {
      page,
      sort,
      order,
      search,
      name,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/popups`,
      params,
    });
  },
  getPresentableItems() {
    return axios({
      method: 'get',
      url: `${apiUrl}/popups/presentable`,
    });
  },
  setPopupFormData({ id, ...rest }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/popups/${id}/feed_url`,
      data: {
        ...rest,
      },
    });
  },
  setDismissed({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/popups/${id}/close`,
    });
  },
};
