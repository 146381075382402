<template>
  <v-tooltip
    open-on-hover
    content-class="elevation-1"
    :color="color"
    :close-delay="delay"
    :max-width="maxWidth"
    :top="position === 'top'"
    :left="position === 'left'"
    :right="position === 'right'"
    :bottom="position === 'bottom'"
  >
    <template #activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        :size="iconSize"
        :color="iconColor"
        class="default-tooltip-icon material-icons-outlined"
        v-on="on"
      >
        info
      </v-icon>
    </template>

    <div class="default-tooltip-text">
      {{ text }}
      <div v-if="textTwo">
        <br />
        {{ textTwo }}
      </div>
      <div v-if="textThree">
        <br />
        {{ textThree }}
      </div>
      <div v-if="link" class="default-tooltip-link" @click="openTooltipLink">
        {{ $t('learn_more') }}
      </div>
      <v-icon :class="arrow" :color="color" size="48px">
        {{ arrow }}
      </v-icon>
    </div>
  </v-tooltip>
</template>

<script>
  export default {
    props: {
      position: {
        type: String,
        default: 'right',
      },
      text: {
        type: String,
        default: '',
      },
      textTwo: {
        type: String,
        default: '',
      },
      textThree: {
        type: String,
        default: '',
      },
      link: {
        type: String,
        default: '',
      },
      width: {
        // not used at the moment, all using default max-width or 240 or 200
        type: String,
        default: '240px',
      },
      delay: {
        type: Number,
        default: 200,
      },
      color: {
        type: String,
        default: '#424242',
      },
      iconSize: {
        type: String,
        default: '16px',
      },
      iconColor: {
        type: String,
        default: '#9E9E9E',
      },
    },
    data() {
      return {
        positions: {
          left: 'arrow_right',
          right: 'arrow_left',
          top: 'arrow_drop_down',
          bottom: 'arrow_drop_up',
        },
      };
    },
    computed: {
      maxWidth() {
        const { position } = this;
        return position === 'top' || position === 'bottom' ? 200 : 240;
      },
      arrow() {
        const { position, positions } = this;
        return positions[position] || '';
      },
    },
    methods: {
      openTooltipLink() {
        window.open(this.link, '_blank');
      },
    },
  };
</script>

<style lang="scss">
.default-tooltip-icon {
  margin-bottom: 2px;
}

.default-tooltip-text {
  font-size: 12px;
  line-height: 16px;
  margin: 0 -5px;

  .default-tooltip-link {
    display: inline-block;
    page-break-inside: avoid;
    pointer-events: initial;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }

  .arrow_left {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }

  .arrow_right {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
  }

  .arrow_drop_up {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .arrow_drop_down {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
