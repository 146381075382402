// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isArr } from '../../utils';
import initialState from '../initialState';

const accountingAppsApi = apiClient.merchy.accountingApps;

/**
 * @description Is valid
 * @param accountingApps
 * @returns {boolean}
 */
const isValid = (accountingApps) => {
  const { items } = accountingApps;

  return isArr(items);
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial accountingApps state');
  }

  const { items } = initialState;
  return {
    items,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  items: ({ items }) => items,
};

const actions = {
  getItems: ({ commit, state }) =>
    accountingAppsApi.getItems().then((res) => {
      const { data } = res.data;

      const xeroIndex = data.findIndex(item => item.name === 'XERO')

      data[xeroIndex].countries.findIndex(item => {
        if (item.code === "MX") {
          item.name = 'Canada'
        }
      })

      const nextState = {
        ...state,
        items: data,
      };

      return commit('SET', nextState);
    }),
  reset: ({ commit }) => commit('SET', initialState.accountingApps),
  set: ({ commit }, accountingApps) => {
    commit('SET', accountingApps);
  },
};

const mutations = {
  SET(state, accountingApps) {
    // eslint-disable-next-line no-param-reassign
    state.items = accountingApps.items;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
