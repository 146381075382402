import { isObj, isArr, isUndef } from '@/utils';
import addAlerts from '../../utils/alert';
import initialState from '../initialState';
import bugsnag from '../../plugins/bugsnag';
import store from '..';

const isDevMode = process.env.NODE_ENV === 'development';
const isTestMode = process.env.NODE_ENV === 'test';

/**
 * @description Is valid
 * @param alerts
 * @returns {boolean}
 */
const isValid = (alerts) =>
  isObj(alerts) &&
  isArr(alerts.info) &&
  isArr(alerts.success) &&
  isArr(alerts.warning) &&
  isArr(alerts.error);

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial alerts state');
  }

  const { info, success, warning, error } = initialState;
  return {
    info,
    success,
    warning,
    error,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  all: ({ info, success, warning, error }) => [
    ...info,
    ...success,
    ...warning,
    ...error,
  ],
  info: ({ info }) => info,
  success: ({ success }) => success,
  warning: ({ warning }) => warning,
  error: ({ error }) => error,
};

const actions = {
  set: ({ commit, state, dispatch }, { data, alertType = 'error' }) => {
    const incomingAlerts = addAlerts(data, alertType);
    const nextAlerts = [...state[alertType], ...incomingAlerts];

    incomingAlerts.forEach(alert => {
      if (alert.alertType === 'error') {
        dispatch('reportApiError', data);
      }
    });

    commit('SET', {
      alerts: nextAlerts,
      type: alertType,
    });
  },
  reset: ({ commit }) => commit('SET', initialState.alerts),
  dismiss: ({ commit }) => {
    commit('DISMISS');
  },
  reportApiError: ({ dispatch }, errorAlert) => {
    const { message } = errorAlert;
    let { params } = errorAlert;
    const error = `API Error: ${message}`;

    if (isUndef(params)) {
      params = {};
    }

    const data = {
      error,
      metaData: {
        params
      }
    };

    dispatch('reportError', data);
  },
  reportError: (context, payload) => {
    if (!isDevMode && !isTestMode) {
      const { error, metaData = {} } = payload;

      metaData.userRole = store?.getters['auth/role'].slug ?? 'not logged in';

      bugsnag.client.notify(new Error(error), {
        metaData
      });
    }
  }
};

const mutations = {
  SET(state, { alerts, type }) {
    // eslint-disable-next-line no-param-reassign
    state[type] = alerts;
  },
  DISMISS(state) {
    /* eslint-disable no-param-reassign */
    state.info = [];
    state.success = [];
    state.warning = [];
    state.error = [];
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
