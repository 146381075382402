import 'intersection-observer';
import Vue from 'vue';
import App from './App';
import i18n from './i18n';
import router from './router';
import store from './store';
import './core/globals';
import './core/axios';
import './core/common';
import './plugins/analytics';
import './plugins/bugsnag';
import './plugins/casl';
import './plugins/fragment';
import './plugins/meta';
import './plugins/moment';
import './plugins/recaptcha';
import './plugins/vuelidate';
import './plugins/vueNotification';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import { initTracking } from './tracking';
import { camelToKebabCase } from './utils';

// eslint-disable-next-line import/order
import { VTextField, VBtn, VAutocomplete, VSelect, VAlert } from 'vuetify/lib';

// customize config
Vue.config.productionTip = false;

const isProduction = process.env.NODE_ENV === 'production';

Vue.component('InputCustom', {
  extends: VTextField,
  props: {
    hideDetails: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
    if (!isProduction && !this.$el.getAttribute('data-cy')) {
      this.$el.setAttribute('data-cy', 'input-' + camelToKebabCase(this.$vnode.data.model.expression));
    }
  }
})

Vue.component('ButtonCustom', {
  extends: VBtn,
  props: {
    // outlined: {
    //   type: Boolean,
    //   default: true
    // },
    elevation: {
      type: [Number, String],
      default: 0
    },
    large: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    rounded: {
      type: Boolean,
      default: true
    },
    maxHeight: {
      type: Number,
      default: 40
    }
  },
  mounted() {
    if (!isProduction && !this.$el.getAttribute('data-cy')) {
      this.$el.setAttribute('data-cy', 'button-' + camelToKebabCase(this.color || 'default'));
    }
  }
})

// the only difference between AutocompleteCustomFixed and AutocompleteCustom is the attach prop
// It is for dropdown menu to be attached to the input field, also dropdown is shown always under the input field
Vue.component('AutocompleteCustomFixed', {
  extends: VAutocomplete,
  props: {
    hideDetails: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    attach: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
    if (!isProduction && !this.$el.getAttribute('data-cy')) {
      this.$el.setAttribute('data-cy', 'autocomplete-fixed-' + camelToKebabCase(this.$vnode.data.model.expression));
    }
  }
})

Vue.component('AutocompleteCustom', {
  extends: VAutocomplete,
  props: {
    hideDetails: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    attach: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    if (!isProduction && !this.$el.getAttribute('data-cy')) {
      this.$el.setAttribute('data-cy', 'autocomplete-' + camelToKebabCase(this.$vnode.data.model.expression));
    }
  }
})

Vue.component('SelectCustom', {
  extends: VSelect,
  props: {
    hideDetails: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    attach: {
      type: Boolean,
      default: true
    },
  },
  mounted() {
    if (!isProduction && !this.$el.getAttribute('data-cy')) {
      this.$el.setAttribute('data-cy', 'select-' + camelToKebabCase(this.$vnode.data.model.expression));
    }
  },
})

Vue.component('AlertCustom', {
  extends: VAlert,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    elevation: {
      type: Number,
      default: 0
    },
  }
})

// instantiate app
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

initTracking();
