export default [
  {
    path: '/threads',
    name: 'threads',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'thread',
          actions: ['view'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import(/* webpackChunkName: "threads" */ '../../views/threads/Index'),
  },
  {
    path: '/threads/archived',
    name: 'archived-threads',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'thread',
          actions: ['view-archived'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import(
        /* webpackChunkName: "archived-threads" */ '../../views/threads/Archived'
        ),
  },
  {
    path: '/threads/:id(\\d+)',
    name: 'thread-details',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'thread',
          actions: ['view'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import(
        /* webpackChunkName: "thread-details" */ '../../views/threads/Single'
        ),
  },
];
