<template>
  <div data-component="header">
    <slot />
  </div>
</template>

<script>

  export default {
    props: {
      logoPos: {
        type: String,
        default: 'left center',
      },
    },
    data() {
      return {
        logoHeight: 36,
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../../assets/styles/theme';

  [data-component="header"] {
    align-items: center;
    background-color: map-get($colors, secondary);
    display: flex;
    justify-content: space-between;
    height: 72px;
    position: relative;
    padding: 0 $spacing-xl;
    overflow: hidden;

    @media (max-width: map-get($breakpoints, md)) {
      padding: 0 $spacing-sm + 0.125rem;
    }
  }
</style>
