import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      sort = '',
      order = 'asc',
      search = '',
      name = '',
    } = options;

    const params = {
      page,
      sort,
      order,
      search,
      name,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/packages`,
      params,
    });
  },
  createItem(packageItem) {
    return axios({
      method: 'post',
      url: `${apiUrl}/packages`,
      data: {
        ...packageItem,
      },
    });
  },
  updateItem(packageItem) {
    return axios({
      method: 'put',
      url: `${apiUrl}/packages/${packageItem.id}`,
      data: {
        ...packageItem,
      },
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/packages/${id}`,
    });
  },
  getLabels({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/packages/${id}/labels`,
    });
  },
  addLabels({ id, labels, country }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/packages/${id}/labels`,
      data: {
        labels,
        country,
      },
    });
  },
  removeLabels({ id, country }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/packages/${id}/labels/${country}`,
    });
  },
  getPackageSubmissionsLimits({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/packages/${id}/limits`,
    });
  },
  addPackageSubmissionLimit({
    id,
    country,
    max_deals_per_week,
    max_deals_per_day,
  }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/packages/${id}/limits`,
      data: {
        country,
        max_deals_per_week,
        max_deals_per_day,
      },
    });
  },
  removePackageSubmissionLimit({ id, country }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/packages/${id}/limits/${country}`,
    });
  },
  getQueueTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/packages/queues`,
    });
  },
  getQueues({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/packages/${id}/queues`,
    });
  },
  addQueue({ id, country, queue }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/packages/${id}/queues`,
      data: {
        country,
        queue,
      },
    });
  },
  removeQueue({ id, country }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/packages/${id}/queues/${country}`,
    });
  },
};
