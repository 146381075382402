// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
import initialState from '../initialState';
// eslint-disable-next-line import/no-cycle
import store from '..';

const schedulesApi = apiClient.merchy.schedules;

/**
 * @description Is valid
 * @param pins
 * @returns {boolean}
 */
const isValid = (pins) => {
  const { items, itemsMeta, platforms, visibilities, pages, pageObjects } =
    pins;

  return (
    isArr(items) &&
    isObj(itemsMeta) &&
    isArr(platforms) &&
    isArr(visibilities) &&
    isArr(pages) &&
    isArr(pageObjects)
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial pins state');
  }

  const { items, itemsMeta, platforms, visibilities, pages, pageObjects } =
    initialState;
  return {
    items,
    itemsMeta,
    platforms,
    visibilities,
    pages,
    pageObjects,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  platforms: ({ platforms }) => platforms,
  visibilities: ({ visibilities }) => visibilities,
  pages: ({ pages }) => pages,
  pageObjects: ({ pageObjects }) => pageObjects,
};

/**
 * @description Handle alerts
 * @param message
 * @param alertType
 */
const handleAlerts = (message, alertType = 'success') => {
  const alert = {
    data: {
      message,
    },
  };

  store.dispatch('alerts/set', {
    data: alert,
    alertType,
  });
};

const actions = {
  getItems: ({ commit, state }, query) =>
    schedulesApi.getItems(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = {
        items: data,
        itemsMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);

      return nextItems;
    }).catch(err => err),
  getItem: (context, query) =>
    schedulesApi.getItem(query)
      .then((res) => res.data.data)
      .catch(err => err),
  createItem: (context, query) =>
    schedulesApi.createItem(query).then((res) => {
      handleAlerts('Pin created successfully.');
      return res.data.data;
    }).catch(err => err),
  updateItem: (context, query) =>
    schedulesApi.updateItem(query).then((res) => {
      handleAlerts('Pin updated successfully.');
      return res.data.data;
    }).catch(err => err),
  removeItem: (context, query) =>
    schedulesApi.removeItem(query).then((res) => {
      handleAlerts('Pin removed successfully.');
      return res.data.data;
    }).catch(err => err),
  getPlatforms: ({ commit, state }, query) =>
    schedulesApi.getPlatforms(query).then((res) => {
      const nextPlatforms = res.data.data;

      const nextState = {
        ...state,
        platforms: nextPlatforms,
      };

      commit('SET', nextState);

      return nextPlatforms;
    }).catch(err => err),
  getVisibilities: ({ commit, state }, query) =>
    schedulesApi.getVisibilities(query).then((res) => {
      const nextVisibilities = res.data.data;

      const nextState = {
        ...state,
        visibilities: nextVisibilities,
      };

      commit('SET', nextState);

      return nextVisibilities;
    }).catch(err => err),
  getPages: ({ commit, state }, query) =>
    schedulesApi.getPages(query).then((res) => {
      const nextPages = res.data.data;

      const nextState = {
        ...state,
        pages: nextPages,
      };

      commit('SET', nextState);

      return nextPages;
    }).catch(err => err),
  getPageObjects: ({ commit, state }, query) =>
    schedulesApi.getPageObjects(query).then((res) => {
      const nextPageObjects = res.data.data;

      const nextState = {
        ...state,
        pageObjects: nextPageObjects,
      };

      commit('SET', nextState);

      return nextPageObjects;
    }).catch(err => err),
  reset: ({ commit }) => commit('SET', initialState.pins),
  set: ({ commit }, pins) => {
    commit('SET', pins);
  },
};

const mutations = {
  SET(state, pins) {
    /* eslint-disable no-param-reassign */
    state.items = pins.items;
    state.itemsMeta = pins.itemsMeta;
    state.platforms = pins.platforms;
    state.visibilities = pins.visibilities;
    state.pages = pins.pages;
    state.pageObjects = pins.pageObjects;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
