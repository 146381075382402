import countriesOrder from '../config/countriesOrder';

/**
 * @description made to check axios parameters, all 'empty' values will be replaced with 'undefined', and 'undefined' is filtered by axios itself and is not sent
 * @param val
 * @returns {val | undefined}
 */
export const checkRequestParameter = (val) => {
  if (isNull(val) || isUndef(val) || val === '' || (isArr(val) && !val.length)) {
    return undefined;
  }
  return val;
};

/**
 * @description made to sort countries by custom order
 * @param arr
 * @returns {Array}
 */
export const sortCountries = (countries) => {
  return countries.sort(function(a, b) {
    return countriesOrder.indexOf(a.code) - countriesOrder.indexOf(b.code);
  });
}

/**
 * @description Is null
 * @param val
 * @returns {boolean}
 */
export const isNull = (val) => val === null;

/**
 * @description Is array
 * @param val
 * @returns {boolean}
 */
export const isArr = (val) => Array.isArray(val);

/**
 * @description Is object
 * @param val
 * @returns {boolean}
 */
export const isObj = (val) =>
  typeof val === 'object' && !isArr(val) && !isNull(val);

/**
 * @description Is number
 * @param val
 * @returns {boolean}
 */
// eslint-disable-next-line no-restricted-globals
export const isNum = (val) => typeof val === 'number' && !isNaN(val);

/**
 * @description Is function
 * @param val
 * @returns {boolean}
 */
export const isFunc = (val) => typeof val === 'function';

/**
 * @description Is string
 * @param val
 * @returns {boolean}
 */
export const isStr = (val) => typeof val === 'string';

/**
 * @description Is undefined
 * @param val
 * @returns {boolean}
 */
export const isUndef = (val) => typeof val === 'undefined';

/**
 * @description Is boolean
 * @param val
 * @returns {boolean}
 */
export const isBool = (val) => typeof val === 'boolean';

/**
 * @description To int
 * @param val
 * @returns {number}
 */
export const toInt = (val) => parseInt(val, 10);

/**
 * @description separateEvery3Digits
 * @param {number} val
 * @returns {string}
 */
export const separateEvery3Digits = (val) => Number.parseFloat(val).toLocaleString('us-US');
/**
 * @description Is non empty string
 * @param val
 * @returns {boolean|boolean}
 */
export const isNonEmptyStr = (val) => isStr(val) && val !== '';

/**
 * @description Is non empty array
 * @param val
 * @returns {boolean|boolean}
 */
export const isNonEmptyArr = (val) => isArr(val) && val.length > 0;

/**
 * @description Has property
 * @param obj
 * @param prop
 * @returns {boolean}
 */
export const hasProp = (obj, prop) =>
  Object.prototype.hasOwnProperty.call(obj, prop);

/**
 * @description Has method
 * @param obj
 * @param method
 * @returns {boolean}
 */
export const hasMethod = (obj, method) =>
  hasProp(obj, method) && isFunc(obj[method]);

/**
 * @description Get object keys
 * @param obj
 * @returns {Array}
 */
export const getKeys = (obj) => Object.keys(obj);

/**
 * @description Has key
 * @param obj
 * @param key
 * @returns {boolean}
 */
export const hasKey = (obj, key) => getKeys(obj).indexOf(key) > -1;

/**
 * @description Has keys
 * @param obj
 * @returns {boolean}
 */
export const hasKeys = (obj) => getKeys(obj).length > 0;

/**
 * @description Each key
 * @param obj
 * @param callback
 */
export const eachKey = (obj, callback) => {
  getKeys(obj).forEach((key, index) => callback(key, obj[key], index));
};

/**
 * @description Each prop
 * @param obj
 * @param callback
 */
export const eachProp = (obj, callback) => {
  eachKey(obj, (key, prop, index) => callback(prop, key, index));
};

/**
 * @description Capitalize
 * @param text
 * @returns {string}
 */
export const capitalize = (text) =>
  `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;

/**
 * @description Parse to type
 * @param val
 * @returns {*}
 */
export const parseToType = (val) => {
  if (!isStr(val)) {
    return undefined;
  }

  if (val === 'true') {
    return true;
  }

  if (val === 'false') {
    return false;
  }

  if (val === 'null') {
    return null;
  }

  if (+val + '' === val) {
    return +val;
  }

  return val;
};

/**
 * @description Extract nexted prop
 * @param obj
 * @param keysText
 * @returns {*}
 */
export const extractNestedProp = (obj, keysText) => {
  const keys = keysText.split('.');
  const keysLength = keys.length - 1;
  let keysIndex = 0;
  let isValidKey = true;
  let targetObj = { ...obj };
  let targetProp;
  let nextTarget;

  if (keys.length > 0) {
    while (isValidKey) {
      nextTarget = targetObj[keys[keysIndex]];

      if (keysIndex === keysLength) {
        targetProp =
          !isUndef(nextTarget) && !isNull(nextTarget) ? nextTarget : undefined;
        break;
      }

      if (!isObj(nextTarget)) {
        isValidKey = false;
        break;
      }

      targetObj = nextTarget;
      keysIndex += 1;
    }
  }

  return targetProp;
};

/**
 * @description Debounce
 * @returns {function}
 */
export const debounce = ({ timeout, id }) => {
  const timers = {};

  return (callback) => {
    if (timers[id]) {
      clearTimeout(timers[id]);
    }
    timers[id] = setTimeout(callback, timeout);
  };
};

/**
 * @description Sequence
 * @returns {{chain: (function(*=): chain), execute: execute}}
 */
// eslint-disable-next-line func-names
export const sequence = function () {
  const chained = [];
  let value;
  let error;

  // eslint-disable-next-line func-names
  const chain = function (func) {
    if (chained) {
      chained.push(func);
    }
    return this;
  };

  // eslint-disable-next-line func-names
  const execute = function (index = 0) {
    let callback;
    if (!chained || index >= chained.length) {
      return true;
    }

    // eslint-disable-next-line prefer-const
    callback = chained[index];
    callback({
      resolve(_value) {
        value = _value;
        // eslint-disable-next-line no-param-reassign,no-plusplus
        execute(++index);
      },
      reject(_error) {
        error = _error;
        // eslint-disable-next-line no-param-reassign,no-plusplus
        execute(++index);
      },
      response: {
        value,
        error,
      },
    });
  };

  return {
    chain,
    execute,
  };
};

/**
 * @desc Hide page scroll
 */
export const hidePageScroll = () => {
  document.documentElement.classList.add('hide-scroll');
};

/**
 * @description Show page scroll
 */
export const showPageScroll = () => {
  document.documentElement.classList.remove('hide-scroll');
};

/**
 * @description Open url in new window
 * @param urlWindow
 * @param url
 */
export const openUrlInNewWindow = (urlWindow, url) => {
  if (urlWindow) {
    /* eslint-disable-next-line no-param-reassign */
    urlWindow.opener = null;
    /* eslint-disable-next-line no-param-reassign */
    urlWindow.location = url;
  } else {
    console.log('urlWindow is null, cannot set opener or location');
  }
};

/**
 * @description Concatenates two arrays and removes duplicates by 'id'
 * accepts array1 and array2 as second and third parameters
 */
export const concatArrays = (p, ...arrs) =>
  []
    .concat(...arrs)
    .reduce(
      (a, b) => (!a.filter((c) => b[p] === c[p]).length ? [...a, b] : a),
      [],
    );

/**
 * @description Gets navigator language
 */
export const getNavigatorLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      'en';

/**
 * @description convert camelCase to kebab-case
 * @param {string} str
 * @returns {string}
 */
export const camelToKebabCase = (str) => str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();