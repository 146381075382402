<template>
  <v-app>
    <div class="d-flex justify-space-between">
      <Navigation
        v-if="isLogged"
        v-model="isNavigationAlwaysExpanded"
      />
      <v-main
        :class="contentClass"
      >
        <router-view />
        <AlertsManager />
        <IdleManager />
        <PopupsManager />
      </v-main>
    </div>
  </v-app>
</template>

<script>
  import AlertsManager from './components/AlertsManager';
  import IdleManager from './components/IdleManager';
  import PopupsManager from './components/PopupsManager';
  import Navigation from './components/Navigation';

  export default {
    data() {
      return {
        isNavigationAlwaysExpanded: false,
      }
    },
    components: {
      AlertsManager,
      IdleManager,
      PopupsManager,
      Navigation,
    },
    computed: {
      title() {
        return this.$t('app_name');
      },
      permissions() {
        return this.$store.getters['auth/permissions'];
      },
      isLogged() {
        return this.$store.getters['auth/isLogged'];
      },
      contentClass() {
        if (this.isLogged) {
          return this.isNavigationAlwaysExpanded ? 'content__shrinked' : 'content__full'
        }
        return ''
      },
      loggedUserRole() {
        return this.$store.getters['auth/role'];
      },
      isBusinessContact() {
        return (
          this.loggedUserRole.slug === 'marketing_agent' ||
          this.loggedUserRole.slug === 'business_contact' ||
          this.loggedUserRole.slug === 'business_admin'
        );
      },
    },
    created() {
      this.updateAbility();
    },
    watch: {
      isLogged(val) {
        // TODO delete this if after some time (today is june 2024)
        if (val && localStorage.getItem('isWhatsNewPopupOn') !== null) {
          // This will clear local storage for users who have already seen the popup
          localStorage.removeItem('isWhatsNewPopupOn');
        }
      }
    },
    methods: {
      updateAbility() {
        this.$ability.update(this.permissions);
      },
    },
    metaInfo() {
      const { title } = this;

      return {
        title,
        titleTemplate(viewTitle) {
          return viewTitle !== title ? `${viewTitle} | ${title}` : title;
        },
      };
    },
  };
</script>

<style lang="scss">
  .v-main {
    max-height: 100vh;
    overflow-y: scroll;
  }

  .v-main.content__full {
    max-width: calc(100vw - 53px);
  }

  .v-main.content__shrinked {
    max-width: calc(100vw - 220px);
  }
</style>
