export default [
  {
    path: '/logout',
    name: 'logout',
    meta: {
      loadState: false,
      auth: true,
      isAuthPage: true,
    },
    // route level code-splitting
    component: () =>
      import( '../../views/auth/Logout'),
  },
];
