export default [
    'DE',
    'FR',
    'UK', //
    'GB', // sometimes its GB, sometimes UK
    'PL',
    'NL',
    'AT',
    'IT',
    'MX',
    'ES',
    'BR',
    'RU'
];
