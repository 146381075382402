/* eslint-disable */

/**
 * @responsibility
 * trigger view event on elements that become visible for first time
 */

import $ from 'jquery';
import debounce from 'lodash/debounce';

const viewObserver = new IntersectionObserver((entries, obs) => {
  entries.forEach(entry => {
    if (!entry.isIntersecting) return;
    obs.unobserve(entry.target);
    entry.target.dispatchEvent(new CustomEvent('view', { bubbles: true }));
  });
});

export const observeView = (elem) => viewObserver.observe(elem);

export const unobserveView = (elem) => viewObserver.unobserve(elem);

// TODO: remove when twig is gone
// observed twig content needs to be initialised on page load & replace
export function observeTwigViews() {
  const observed = new WeakSet();

  const init = debounce(() => {
    document.querySelectorAll('[data-t-view-twig]').forEach(elem => {
      if (observed.has(elem)) return; // don't trigger view more than once on an elem
      observed.add(elem);
      observeView(elem);
    });
  }, 10);

  $(init); // init initial twig

  // init replaced twig fragments
  $(window).on('debouncedDomChanged', init);
  // cover cases where debouncedDomChanged is not triggered (e.g. loaded popover)
  $(document).on('init', '[data-handler]', init); // works for content where directives are present in fragment
}
