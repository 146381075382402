import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      sort = '',
      descending,
      business = -1,
      countries = [],
      search = '',
      status = -1,
    } = options;

    const params = {
      page,
      countries,
      order: descending ? 'desc' : 'asc',
    };

    if (search) {
      params.search = search;
    }

    if (sort) {
      params.sort = sort;
    }

    if (status > -1) {
      params.status = status;
    }

    if (business > -1) {
      params.business_id = business;
    }

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/networks`,
      params,
    });
  },
  getLinkedCountries() {
    return axios({
      method: 'get',
      url: `${apiUrl}/networks/link_countries`,
    });
  },
  getStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/networks/statuses`,
    });
  },
  createItem(query) {
    return axios({
      method: 'post',
      url: `${apiUrl}/networks`,
      data: query,
    });
  },
  updateItem(query) {
    const { network_id } = query;

    return axios({
      method: 'put',
      url: `${apiUrl}/networks/${network_id}`,
      data: query,
    });
  },
  updateItemStatus(query) {
    const { network, status, country } = query;

    return axios({
      method: 'put',
      url: `${apiUrl}/networks/${network.id}/status`,
      data: {
        status: status.id,
        country,
      },
    });
  },
};
