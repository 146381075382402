import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      countries,
      page = 1,
      perPage = maxItemsPerPage,
      order = 'asc',
      sort = '',
      search = '',
      status = -1,
      business = -1,
      contract_id = -1,
      created_at = '',
    } = options;

    const params = {
      countries,
      page,
      order,
      sort,
      search,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (status > -1) {
      params.status = status;
    }

    if (business > -1) {
      params.business_id = business;
    }

    if (contract_id > -1) {
      params.contract_id = contract_id;
    }

    if (created_at !== '') {
      params.created_at = created_at;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/payments`,
      params,
    });
  },
  updateItem({ id, discount }) {
    return axios({
      method: 'patch',
      url: `${apiUrl}/payments/${id}`,
      data: {
        discount,
      },
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/payments/${id}`,
    });
  },
  getStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/payments/statuses`,
    });
  },
};
