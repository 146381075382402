/* eslint-disable import/no-cycle */
import store from '../../store';
import accountingApps from './accountingApps';
import auth from './auth';
import siteMessages from './siteMessages';
import billing from './billing';
import businesses from './businesses';
import businessUsers from './businessUsers';
import cannedReplies from './cannedReplies';
import config from './config';
import contracts from './contracts';
import creatorProgram from './creatorProgram';
import events from './events';
import invoices from './invoices';
import labels from './labels';
import packages from './packages';
import payments from './payments';
import pepper from './pepper';
import reports from './reports';
import schedules from './schedules';
import settings from './settings';
import shops from './shops';
import sponsorships from './sponsorships';
import uris from './uris';
import users from './users';
import tasks from './tasks';
import threads from './threads';
import validation from './validation';
import popups from './popups';
import promotions from './promotions';
import networks from './networks';

const { location } = window;

/**
 * @description Api url
 * @type {string}
 */
export const apiUrl = `${location.protocol}//${import.meta.env.VUE_APP_API}/api`;

/**
 * @description Max items per page
 * @type {string}
 */
export const maxItemsPerPage = import.meta.env.VUE_APP_API_MAX_ITEMS_PER_PAGE;

/**
 * @description Get token
 * @param token
 * @returns {*}
 */
export const getToken = (token = 'access_token') =>
  store.getters['auth/token'][token];

export default {
  accountingApps,
  auth,
  siteMessages,
  billing,
  businesses,
  businessUsers,
  cannedReplies,
  config,
  contracts,
  creatorProgram,
  events,
  invoices,
  labels,
  packages,
  payments,
  pepper,
  reports,
  schedules,
  settings,
  shops,
  sponsorships,
  tasks,
  threads,
  uris,
  users,
  validation,
  popups,
  promotions,
  networks,
};
