const defaultButtons = [
  ['bold', 'italic', 'underline', 'del'],
  ['unorderedList', 'orderedList'],
  ['link', 'removeformat'],
];

export default {
  btns: defaultButtons,
  minimalLinks: true,
  defaultLinkTarget: '_blank',
};
