import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';
import { isStr, isNum, eachKey } from '../../utils';

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export default {
  getItems(options = {}) {
    const {
      countries,
      page = 1,
      per_page = maxItemsPerPage,
      sort = '',
      order = 'asc',
      search = '',
      labels = [],
      business = -1,
      teamMembers = [],
      merchant_id = -1,
      category = '',
      date_filter = '',
      status = '',
      accountTypes = [],
      priority = -1,
    } = options;

    const params = {
      page,
      sort,
      order,
      search,
      countries,
      team_members: teamMembers
    };

    if (per_page > -1) {
      params.per_page = per_page;
    }

    if (business !== -1) {
      params.business = business;
    }

    if (date_filter !== '') {
      params.date_filter = date_filter;
    }

    if (status !== '') {
      params.status = [status];
    }

    if (priority !== -1) {
      params.priority = priority;
    }

    if (merchant_id !== -1) {
      params.merchant_id = merchant_id;
    }

    if (category !== '') {
      params.category = category;
    }

    if (labels.length > 0) {
      params.labels = labels;
    }

    if (accountTypes.length > 0) {
      accountTypes.forEach((key) => {
        params[key] = true;
      });
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/threads`,
      params,
    });
  },
  getArchivedItems(options = {}) {
    const {
      countries,
      page = 1,
      per_page = maxItemsPerPage,
      sort = '',
      order = 'asc',
      search = '',
      labels = [],
      business = -1,
      teamMembers = [],
      merchant_name = '',
      category = '',
      date_filter = '',
      status = '',
      accountTypes = [],
    } = options;

    const params = {
      page,
      sort,
      order,
      search,
      countries,
      team_members: teamMembers
    };

    if (per_page > -1) {
      params.per_page = per_page;
    }

    if (business !== -1) {
      params.business = business;
    }

    if (date_filter !== '') {
      params.date_filter = date_filter;
    }

    if (status !== '') {
      params.status = [status];
    }

    if (merchant_name !== '') {
      params.merchant_name = merchant_name;
    }

    if (category !== '') {
      params.category = category;
    }

    if (labels.length > 0) {
      params.labels = labels;
    }

    if (accountTypes.length > 0) {
      accountTypes.forEach((key) => {
        params[key] = true;
      });
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/threads/archived`,
      params,
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/threads/${id}`,
    });
  },
  checkDuplicateDealURL({ country, dealURL }) {
    const fd = new FormData();
    fd.append('dealURL', dealURL);

    return axios.post(`${apiUrl}/threads/${country}/duplicate`, { dealUrl: dealURL }).then(res => res.data);
  },
  createDeal(thread) {
    const { ean, ...rest } = thread;
    const dealTypeId = 1;
    const fd = new FormData();

    eachKey(rest, (key, prop) => {
      fd.append(key, prop);
    });

    ean.forEach((code) => {
      fd.append('ean[]', code);
    });

    return axios.post(
      `${apiUrl}/threads/type/${dealTypeId}`,
      fd,
      formDataConfig,
    );
  },
  createCampaign(thread) {
    const {
      campaign_file,
      campaign_link,
      campaign_delete_attachments,
      ...rest
    } = thread;
    const campaignTypeId = 2;
    const fd = new FormData();

    eachKey(rest, (key, prop) => {
      fd.append(key, prop);
    });

    campaign_file.forEach((file) => {
      fd.append('campaign_file[]', file);
    });

    campaign_link.forEach((link) => {
      fd.append('campaign_link[]', link);
    });

    campaign_delete_attachments.forEach((id) => {
      fd.append('campaign_delete_attachments[]', id);
    });

    return axios.post(
      `${apiUrl}/threads/type/${campaignTypeId}`,
      fd,
      formDataConfig,
    );
  },
  updateItem(thread) {
    const { ean, ...rest } = thread;
    const fd = new FormData();
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    eachKey(rest, (key, prop) => {
      fd.append(key, prop);
    });

    ean.forEach((e) => {
      fd.append('ean[]', e);
    });

    return axios.post(`${apiUrl}/threads/${thread.id}`, fd, config);
  },
  updateCampaign(thread) {
    const {
      campaign_file,
      campaign_link,
      campaign_delete_attachments,
      ...rest
    } = thread;
    const fd = new FormData();

    eachKey(rest, (key, prop) => {
      fd.append(key, prop);
    });

    campaign_file.forEach((file) => {
      fd.append('campaign_file[]', file);
    });

    campaign_link.forEach((link) => {
      fd.append('campaign_link[]', link);
    });

    campaign_delete_attachments.forEach((id) => {
      fd.append('campaign_delete_attachments[]', id);
    });

    return axios.post(`${apiUrl}/threads/${thread.id}`, fd, formDataConfig);
  },
  removeItem({ id }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/threads/${id}`,
    });
  },
  getActivityLogs({ page = 1, perPage = maxItemsPerPage, id }) {
    const params = {
      page,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/threads/${id}/activities`,
      params,
    });
  },
  getStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/threads/statuses`,
    });
  },
  getPriorities() {
    return axios({
      method: 'get',
      url: `${apiUrl}/threads/priorities`,
    });
  },
  getFilterStatuses({ archived = 0 } = {}) {
    const params = {
      archived,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/threads/filter_statuses`,
      params,
    });
  },
  getSubmissionStatuses({ archived = 0 } = {}) {
    const params = {
      archived,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/threads/filter_statuses`,
      params,
    });
  },
  getCategories(country = '') {
    const params = {
      country,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/threads/categories`,
      params,
    });
  },
  getAccountTypes({ archived = 0 }) {
    const params = {
      archived,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/threads/account_types`,
      params,
    });
  },
  assignThread({ id, queue = '', user = -1 }) {
    const data = {};

    if (queue !== '') {
      data.queue = queue;
    }

    if (user !== -1) {
      data.user = user;
    }

    return axios({
      method: 'post',
      url: `${apiUrl}/threads/${id}/assign`,
      data,
    });
  },
  getCommentPermissions({ all = 0 } = {}) {
    const params = {
      all,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/threads/comments/permissions`,
      params,
    });
  },
  getComments({ id, filter }) {
    const params = {
      filter,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/threads/${id}/comments`,
      params,
    });
  },
  commentThread({ id, comment }) {
    const fd = new FormData();

    fd.append('comment', comment.comment);

    if (isStr(comment.permissions)) {
      fd.append('permissions', comment.permissions);
    }

    if (isNum(comment.predefined_comment_id)) {
      fd.append('predefined_comment_id', comment.predefined_comment_id);
    }

    comment.attachments.forEach((attachment) => {
      fd.append('attachments[]', attachment);
    });

    return axios.post(`${apiUrl}/threads/${id}/comments`, fd, formDataConfig);
  },
  denyThread({ id, comment }) {
    const fd = new FormData();

    fd.append('comment', comment.comment);

    if (isStr(comment.permissions)) {
      fd.append('permissions', comment.permissions);
    }

    if (isNum(comment.predefined_comment_id)) {
      fd.append('predefined_comment_id', comment.predefined_comment_id);
    }

    comment.attachments?.forEach((attachment) => {
      fd.append('attachments[]', attachment);
    });

    return axios.post(`${apiUrl}/threads/${id}/deny`, fd, formDataConfig);
  },
  archiveThread({ id, comment }) {
    const fd = new FormData();

    fd.append('comment', comment.comment);

    if (isStr(comment.permissions)) {
      fd.append('permissions', comment.permissions);
    }

    if (isNum(comment.predefined_comment_id)) {
      fd.append('predefined_comment_id', comment.predefined_comment_id);
    }

    comment.attachments.forEach((attachment) => {
      fd.append('attachments[]', attachment);
    });

    return axios.post(`${apiUrl}/threads/${id}/archive`, fd, formDataConfig);
  },
  reopenThread({ id, comment }) {
    const fd = new FormData();

    fd.append('comment', comment.comment);

    if (isStr(comment.permissions)) {
      fd.append('permissions', comment.permissions);
    }

    if (isNum(comment.predefined_comment_id)) {
      fd.append('predefined_comment_id', comment.predefined_comment_id);
    }

    comment.attachments.forEach((attachment) => {
      fd.append('attachments[]', attachment);
    });

    return axios.post(`${apiUrl}/threads/${id}/reopen`, fd, formDataConfig);
  },
  acceptThread({ id, comment }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/threads/${id}/accept`,
      data: {
        ...comment,
      },
    });
  },
  publishThread({ id }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/threads/${id}/publish`,
    });
  },
  publishThreadWithUrl({ id, published_url }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/threads/${id}/url`,
      data: {
        published_url,
      },
    });
  },
  getLabels({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/threads/${id}/labels`,
    });
  },
  updateLabels({ id, labels }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/threads/${id}/labels`,
      data: {
        labels,
      },
    });
  },
  getAllExternalMerchants(options = {}) {
    const { page = 1, per_page = -1, search = '' } = options;

    const params = {};

    if (page > 1) {
      params.page = page;
    }

    if (per_page > -1) {
      params.per_page = per_page;
    }

    if (search !== '') {
      params.search = search;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/threads/external_merchants`,
      params,
    });
  },
  searchExternalMerchants({ id, search }) {
    const params = {
      search,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/threads/${id}/external_merchants/search`,
      params,
    });
  },
  getExternalMerchants({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/threads/${id}/external_merchants`,
    });
  },
  addExternalMerchant({ id, merchant }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/threads/${id}/external_merchants`,
      data: {
        ...merchant,
      },
    });
  },
  getExternalMerchantDeals({ id, page = 1, perPage = 5 }) {
    const params = {
      page,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/threads/${id}/external_merchant_deals`,
      params,
    });
  },
  getPriceComparison({ target_country }) {
    const params = {
      target_country,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/price_comparison/${target_country}/link`,
      params,
    });
  },
  getDuplicates({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/threads/duplicates/${id}`,
    });
  },
  getQueues() {
    return axios({
      method: 'get',
      url: `${apiUrl}/threads/queues`,
    });
  },
  getDiscountTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/threads/discount_types`,
    });
  },
  getCommentFilters() {
    return axios({
      method: 'get',
      url: `${apiUrl}/threads/comment_filters`,
    });
  },
  getThreadCommentTemplates(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/comments/templates`,
      params
    });
  },
};
