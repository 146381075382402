<template>
  <div data-component-group="panels" data-component="table-title">
    <h1>{{ primaryText }}</h1>

    <template v-if="hasSecondaryText">
      <span class="separator">
        {{ ' / ' }}
      </span>

      <h2>{{ secondaryText }}</h2>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      primaryText: {
        type: String,
        default: '',
      },
      secondaryText: {
        type: String,
        default: '',
      },
    },
    computed: {
      hasSecondaryText() {
        return this.secondaryText !== '';
      },
    },
  };
</script>

<style lang="scss" scoped>
@import '../../assets/styles/theme';

[data-component-group='panels'][data-component='table-title'] {
  display: inline-block;

  h1 {
    display: inline-block;

    @media (max-width: map-get($breakpoints, md)) {
      padding: 0 0 10px 0;
    }
  }

  .separator {
    font-size: $text-size-xl + 0.25rem;
    margin: 0 4px;
    color: map-deep-get($colors, grey, lighten-1);
  }

  h2 {
    display: inline-block;
    font-size: $text-size-xl + 0.125rem;
    font-weight: 400;
  }
}
</style>
