<template>
  <div data-component-group="labels" data-component="color">
    <v-icon :class="iconClass"> fiber_manual_record </v-icon>

    <span v-if="hasLabel" :class="'color-label ' + `${color}--text`">
      {{ label }}
    </span>
  </div>
</template>

<script>
  export default {
    props: {
      color: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        default: '',
      },
    },
    computed: {
      iconClass() {
        return `color-circle color-${this.color}`;
      },
      hasLabel() {
        return this.label !== '';
      },
    },
  };
</script>

<style lang="scss" scoped>
@import '../../assets/styles/theme';

[data-component-group='labels'][data-component='color'] {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .color-label {
    color: map-deep-get($colors, shades, 'black');
  }
}
</style>
