import download from 'downloadjs';
// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
// eslint-disable-next-line import/no-cycle
import router from '../../router';
import { isObj, isArr, sortCountries } from '@/utils';
import initialState from '../initialState';
// eslint-disable-next-line import/no-cycle
import store from '..';

const businessUsersApi = apiClient.merchy.businessUsers;

/**
 * @description Is valid
 * @param businessUsers
 * @returns {boolean}
 */
const isValid = (businessUsers) => {
  const { items, itemsMeta, limits, submissionLimits, settings, statuses, enabledPromoCountries } =
    businessUsers;

  return (
    isObj(businessUsers) &&
    isArr(items) &&
    isObj(itemsMeta) &&
    isArr(limits) &&
    isArr(enabledPromoCountries) &&
    isObj(submissionLimits) &&
    isObj(settings) &&
    isArr(statuses)
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial businessUsers state');
  }

  const { items, itemsMeta, limits, submissionLimits, settings, statuses, enabledPromoCountries } = initialState;
  return {
    items,
    itemsMeta,
    limits,
    enabledPromoCountries,
    submissionLimits,
    settings,
    statuses,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
  limits: ({ limits }) => limits,
  enabledPromoCountries: ({ enabledPromoCountries }) => enabledPromoCountries,
  submissionLimits: ({ submissionLimits }) => submissionLimits,
  settings: ({ settings }) => settings,
  statuses: ({ statuses }) => statuses,
  banners: ({ banners }) => banners,
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') =>
  store.dispatch('alerts/set', {
    data,
    alertType,
  });

const actions = {
  getMulticountryMerchants: (query) => (
    businessUsersApi.getMulticountryMerchants(query).then((res) => {
      return res.data.data;
    })
  ),
  getItems: ({ commit, state }, query) =>
    businessUsersApi.getItems(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = data.map((item) => ({
        ...item,
        name: `${item.firstname} ${item.lastname}`,
      }));
      const nextItemsState = {
        items: nextItems,
        itemsMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextItemsState,
      };

      commit('SET', nextState);

      return nextItemsState;
    }),
  getLimits: ({ commit, state }) =>
    businessUsersApi.getLimits().then((res) => {
      const nextState = {
        ...state,
        limits: res.data.data,
      };

      return commit('SET', nextState);
    }),
  getEnabledCountriesForPromotion: ({ commit, state }) =>
    businessUsersApi.getEnabledCountriesForPromotion().then((res) => {
      const countries = sortCountries(res.data.data)
      const nextState = {
          ...state,
          enabledPromoCountries: countries,
      };

      commit('SET', nextState);
      return countries;
    }),
  getSubmissionLimits: ({ commit, state }, query) =>
    businessUsersApi.getSubmissionLimits(query).then((res) => {
      const nextState = {
        ...state,
        submissionLimits: res.data.data,
      };

      return commit('SET', nextState);
    }),
  getFile: (context, query = {}) =>
    businessUsersApi.getFile(query).then((res) => {
      const content = res.headers['content-type'];

      return download(res.data, query.type || 'File', content);
    }),
  getSettings: ({ commit, state }, query) =>
    businessUsersApi.getSettings(query).then((res) => {
      const nextSettings = res.data.data;
      const nextState = {
        ...state,
        settings: nextSettings,
      };

      commit('SET', nextState);

      return nextSettings;
    }),
    // todo: check if we need business_id in request, if not delete it
  setSettings: (context, query) =>
    businessUsersApi.setSettings(query).then((res) => {
      const { business_id, setting } = query;

      handleAlerts(
        {
          data: {
            message: `${router.app.$t(
              'changes_to_user',
            )} ${setting} ${router.app.$t('have_been_saved')}`,
          },
        },
        'success',
      );

      return res.data.data;
    }),
  getStatuses: ({ commit, state }) =>
    businessUsersApi.getStatuses().then((res) => {
      const nextStatuses = res.data.data;
      const nextState = {
        ...state,
        statuses: nextStatuses,
      };

      commit('SET', nextState);

      return nextStatuses;
    }),
  updateSettings: ({ commit, state }, query) =>
    businessUsersApi.updateSettings(query).then((res) => {
      const nextSettings = res.data.data;
      const nextState = {
        ...state,
        settings: nextSettings,
      };

      commit('SET', nextState);

      return nextSettings;
    }),
  switchBusiness: ({ commit }, business_id) => {
    return businessUsersApi.changeLastBusinessId(business_id).then((res) => {
      const data = res.data.data;
      return store.dispatch('auth/logoutAction', commit).then(() => {
        return store.dispatch('auth/executeLogin', data)
        // TO DO We have to fix the refresh flow on /threads
        .then(() => {window.location.pathname === '/threads' ? router.go() : router.replace({ name: 'index' })})
      });
    });
  },
  inviteBusinessUser: ({ commit }, query) => {
    return businessUsersApi.inviteBusinessUser(query.token).then((res) => {
      const data = res.data.data || false;
      if (query.isLogged) {
        return store.dispatch('auth/logoutAction', commit).then((res)=>{
          if (data.length !== 0) {
            return store.dispatch('auth/executeLogin', data).then((res) => {
              router.replace({ name: 'index' });
              return res;
            });
          }
          router.replace({ name: 'index' });
          return res;
        });
      }
      router.replace({ name: 'index' });
      return res;
    });
  },
  inviteUser: (context, query) =>
    businessUsersApi.inviteUser(query).then((res) => {
      handleAlerts(
        {
          data: {
            message: router.app.$t('invitation_sent_successfully'),
          },
        },
        'success',
      );

      return res;
    }),
  reset: ({ commit }) => commit('SET', initialState.businessUsers),
  set: ({ commit }, businessUsers) => {
    commit('SET', businessUsers);
  },
};

const mutations = {
  SET(state, businessUsers) {
    /* eslint-disable no-param-reassign */
    state.items = businessUsers.items;
    state.itemsMeta = businessUsers.itemsMeta;
    state.limits = businessUsers.limits;
    state.submissionLimits = businessUsers.submissionLimits;
    state.settings = businessUsers.settings;
    state.statuses = businessUsers.statuses;
    state.banners = businessUsers.banners;
    state.enabledPromoCountries = businessUsers.enabledPromoCountries;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
