export default [
  {
    path: '/sponsorships',
    name: 'logo-sponsorships',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'sponsorship',
          actions: ['list-all'],
        },
      ],
    },
    // route level code-splitting
    component: () => import('../../views/sponsorships/Index'),
    children: [
      {
        path: 'new',
        name: 'logo-sponsorship-new',
        meta: {
          loadState: true,
          auth: true,
          isAuthPage: false,
          permissions: [
            {
              subject: 'sponsorship',
              actions: ['store'],
            },
          ],
        },
        // route level code-splitting
        component: () => import('../../components/dialogs/sponsorships/AddNew'),
      },
      {
        path: ':id(\\d+)',
        name: 'logo-sponsorship-details',
        meta: {
          loadState: true,
          auth: true,
          isAuthPage: false,
          permissions: [
            {
              subject: 'sponsorship',
              actions: ['view'],
            },
          ],
        },
        // route level code-splitting
        component: () => import('../../components/dialogs/sponsorships/Edit'),
      },
    ]
  },
];
