import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      sort = '',
      order = 'asc',
      search = '',
      dates = '',
      countries = [],
      status = '',
    } = options;

    const params = {
      page,
      sort,
      order,
      search,
      countries
    };

    if (dates !== '') {
      params.dates = dates;
    }

    if (status !== '') {
      params.status = status;
    }

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/banners`,
      params,
    });
  },
  createItem(banner) {
    return axios({
      method: 'post',
      url: `${apiUrl}/banners`,
      data: {
        ...banner,
      },
    });
  },
  updateItem(banner) {
    return axios({
      method: 'put',
      url: `${apiUrl}/banners/${banner.id}`,
      data: {
        ...banner,
      },
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/banners/${id}`,
    });
  },
  getStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/banners/statuses`,
    });
  },
  getPresentableItems() {
    return axios({
      method: 'get',
      url: `${apiUrl}/banners/presentable`,
    });
  },
  setDismissed({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/banners/${id}/close`,
    });
  },
  trackRedirect({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/banners/${id}/visit`,
    });
  },
};
