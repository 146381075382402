<template>
  <div v-if="hasPopups" data-component="popups-manager">
    <FeedModal :feed="visiblePopup" :is-on="isFeedModalOn" />
  </div>
</template>

<script>
  import FeedModal from './dialogs/popups/Feed';
  import testClassMixin from '../mixins/testClass';
  import { isObj, isNum } from '../utils';

  export default {
    components: {
      FeedModal,
    },
    mixins: [testClassMixin],
    data() {
      return {
        isFeedModalOn: false,
      };
    },
    computed: {
      isLogged() {
        return this.$store.getters['auth/isLogged'];
      },
      popups() {
        return this.$store.getters['popups/presentableItems'];
      },
      canSeePopups() {
        const { isLogged, $ability } = this;

        return $ability.can('view-presentable', 'popup') && isLogged;
      },
      hasPopups() {
        return this.popups.length > 0;
      },
      visiblePopup() {
        return this.$store.getters['popups/visiblePopup'];
      },
      isFeedPopup() {
        return this.visiblePopup.type.id === 1;
      },
    },
    watch: {
      canSeePopups: {
        handler() {
          if (this.canSeePopups) {
            this.getPopups();
          }
        },
        immediate: true,
      },
      visiblePopup: {
        handler(nextPopup) {
          if (isObj(nextPopup) && isNum(nextPopup.id)) {
            this.turnOnPopup(nextPopup);
          } else {
            this.hidePopups();
          }
        },
        immediate: true,
      },
    },
    methods: {
      turnOnPopup() {
        if (this.isFeedPopup) {
          this.isFeedModalOn = true;
        }
      },
      hidePopups() {
        this.isFeedModalOn = false;
      },
      getPopups() {
        return this.$store.dispatch('popups/getPresentableItems');
      },
    },
  };
</script>

<style lang="scss" scoped>
[data-component='popups-manager'] {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10000;
}
</style>
