<!-- eslint-disable-next-line vue/valid-template-root -->
<template />

<script>
  const { history, location } = window;

  export default {
    props: {
      path: {
        type: String,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
      active: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        initialUrl: location.pathname,
      };
    },
    computed: {
      targetUrl() {
        return `${location.origin}${this.path}`;
      },
    },
    created() {
      if (this.active) {
        this.onToggle(this.active);
      }
    },
    watch: {
      active(nextVal) {
        this.onToggle(nextVal);
      },
    },
    methods: {
      onToggle(active) {
        const url = active ? this.targetUrl : this.initialUrl;
        this.updateLastHistoryState(url);
      },
      updateLastHistoryState(url) {
        history.replaceState(history.state, this.title, url);
      },
    },
  };
</script>
