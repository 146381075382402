// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const labelsApi = apiClient.merchy.labels;

/**
 * @description Is valid
 * @param labels
 * @returns {boolean}
 */
const isValid = (labels) => {
  const { activeItem, items, itemsMeta } = labels;

  return (
    isObj(labels) &&
    isObj(activeItem) &&
    isArr(items) &&
    isObj(itemsMeta)
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial labels state');
  }

  const { activeItem, items, itemsMeta } =
    initialState;
  return {
    activeItem,
    items,
    itemsMeta,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  activeItem: ({ activeItem }) => activeItem,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
};

/**
 * @description Handle alerts
 * @param message
 * @param alertType
 */
const handleAlerts = (message, alertType = 'success') => {
  const alert = {
    data: {
      message,
    },
  };

  return store.dispatch('alerts/set', {
    data: alert,
    alertType,
  });
};

const actions = {
  create: (context, label) =>
    labelsApi
      .createItem(label)
      .then(() => handleAlerts('Label created successfully.')),
  update: ({ commit, state }, comment) =>
    labelsApi.updateItem(comment).then((res) => {
      const { activeItem } = state;
      const nextLabel = res.data.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextLabel,
        },
      };

      commit('SET', nextState);
      return handleAlerts('Label updated successfully.');
    }),
  remove: (context, label) =>
    labelsApi
      .removeItem(label)
      .then(() => handleAlerts('Label removed successfully.')),
  getItem: ({ commit, state }, query) =>
    labelsApi.getItem(query).then((res) => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...res.data.data,
        },
      };

      return commit('SET', nextState);
    }),
  getItems: ({ commit, state }, query) => {
    const labelsQuery = {
      ...query,
      // is_system_label: 0,
    };

    return labelsApi.getItems(labelsQuery).then((res) => {
      const { data, meta } = res.data;
      const nextItems = {
        items: data,
        itemsMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  setActiveItem: ({ commit, state }, nextActiveItem) => {
    const nextState = {
      ...state,
      activeItem: {
        ...state.activeItem,
        ...nextActiveItem,
      },
    };

    return commit('SET', nextState);
  },
  reset: ({ commit }) => commit('SET', initialState.labels),
  set: ({ commit }, labels) => {
    commit('SET', labels);
  },
};

const mutations = {
  SET(state, labels) {
    /* eslint-disable no-param-reassign */
    state.activeItem = labels.activeItem;
    state.items = labels.items;
    state.itemsMeta = labels.itemsMeta;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
