export default [
  {
    path: '/scheduled-threads',
    name: 'scheduled-threads',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'schedule-thread',
          actions: ['list-all'],
        },
      ],
    },
    component: () => import('../../views/scheduledThreads/Index'),
  },
  {
    path: '/scheduled-threads/today',
    name: 'scheduled-threads-today',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'schedule-thread',
          actions: ['list-all'],
        },
      ],
    },
    component: () => import('../../views/scheduledThreads/Index'),
  },
];
