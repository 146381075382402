import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      sort = '',
      order = 'asc',
      search = '',
      countries = [],
      is_system_label = -1,
    } = options;

    const params = {
      page,
      sort,
      order,
      search,
      countries,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (is_system_label > -1) {
      params.is_system_label = is_system_label;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/labels`,
      params,
    });
  },
  createItem(label) {
    return axios({
      method: 'post',
      url: `${apiUrl}/labels`,
      data: {
        ...label,
      },
    });
  },
  updateItem(label) {
    return axios({
      method: 'put',
      url: `${apiUrl}/labels/${label.id}`,
      data: {
        ...label,
      },
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/labels/${id}`,
    });
  },
  removeItem({ id }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/labels/${id}`,
    });
  },
};
