// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isArr } from '../../utils';
import initialState from '../initialState';

const schedulesApi = apiClient.merchy.schedules;

/**
 * @description Is valid
 * @param schedules
 * @returns {boolean}
 */
const isValid = (schedules) => {
  const { threads, threadsMeta } = schedules;

  return isArr(threads) && isArr(threadsMeta);
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial schedules state');
  }

  const { threads, threadsMeta } = initialState;
  return {
    threads,
    threadsMeta,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  threads: ({ threads }) => threads,
  threadsMeta: ({ threadsMeta }) => threadsMeta,
};

const actions = {
  getThreads: ({ commit, state }, query) =>
    schedulesApi.getThreads(query).then((res) => {
      const { data, meta } = res.data;
      const nextThreadsState = {
        threads: data,
        threadsMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextThreadsState,
      };

      commit('SET', nextState);

      return nextThreadsState;
    }),
  reset: ({ commit }) => commit('SET', initialState.schedules),
  set: ({ commit }, schedules) => {
    commit('SET', schedules);
  },
};

const mutations = {
  SET(state, schedules) {
    /* eslint-disable no-param-reassign */
    state.threads = schedules.threads;
    state.threadsMeta = schedules.threadsMeta;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
