<template>
  <div data-component="copy-to-clipboard">
    <v-btn
      :text="flat"
      :depressed="depressed"
      :ripple="ripple"
      :class="getCopyToClipboardClass()"
      @click="copyToClipboard"
    >
      {{ copyLinkLabel }}
      <v-icon>link</v-icon>
    </v-btn>
  </div>
</template>

<script>
  import copy from 'copy-to-clipboard';

  export default {
    props: {
      text: {
        type: String,
        required: true,
      },
      delay: {
        type: Number,
        default: 3000,
      },
      ripple: {
        type: Boolean,
        default: false,
      },
      depressed: {
        type: Boolean,
        default: true,
      },
      flat: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        copyLinkLabel: this.$t('copy_link'),
        isHighlighted: false,
      };
    },
    methods: {
      highlightCopyToClipboard() {
        this.highlight();

        setTimeout(() => {
          this.normalize();
        }, this.delay);
      },
      highlight() {
        this.copyLinkLabel = this.$t('link_was_copied');
        this.isHighlighted = true;
      },
      normalize() {
        this.copyLinkLabel = this.$t('copy_link');
        this.isHighlighted = false;
      },
      copyToClipboard() {
        if (!this.isHighlighted) {
          copy(this.text);
          this.highlightCopyToClipboard();
        }
      },
      getCopyToClipboardClass() {
        return {
          highlighted: this.isHighlighted,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
@import '../assets/styles/theme';

[data-component='copy-to-clipboard'] {
  .v-btn {
    color: map-deep-get($colors, grey, base);
    margin-left: 4px;

    &:before {
      background-color: transparent;
    }

    &:not(.v-btn--icon) .v-icon:not(.icon-toggle) {
      margin: 0 0 0 4px;
    }

    &.highlighted {
      color: map-get($colors, primary);
      transition: 0.15s ease-in-out;

      .v-icon {
        color: map-get($colors, primary) !important;
        transition: 0.15s ease-in-out;
      }
    }
  }
}
</style>
