import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiUrl } from './index';

export default {
  validate({ type, value }) {
    const params = {
      type,
      value,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/validate`,
      params,
    });
  },
};
