import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr, isStr, isBool } from '../../utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const threadsApi = apiClient.merchy.threads;

/**
 * @description Is valid
 * @param threads
 * @returns {boolean}
 */
const isValid = (threads) => {
  const {
    activeItem,
    items,
    itemsMeta,
    archived,
    statuses,
    filterStatuses,
    submissionStatuses,
    categories,
    accountTypes,
    commentPermissions,
    priceComparison,
    queues,
    discountTypes,
    isDirtyDeal,
    isDirtyCampaign,
    priorities,
  } = threads;

  const isValidActiveItem =
    isObj(activeItem) &&
    isStr(activeItem.pendingComment) &&
    isArr(activeItem.labels) &&
    isArr(activeItem.merchants) &&
    isArr(activeItem.comments) &&
    isArr(activeItem.duplicates) &&
    isObj(activeItem.log) &&
    isArr(activeItem.log.items) &&
    isObj(activeItem.log.meta) &&
    isBool(activeItem.hasUpdatedStatus);

  return (
    isObj(threads) &&
    isArr(items) &&
    isObj(itemsMeta) &&
    isObj(archived) &&
    isArr(archived.items) &&
    isObj(archived.meta) &&
    isArr(statuses) &&
    isArr(filterStatuses) &&
    isArr(submissionStatuses) &&
    isArr(categories) &&
    isArr(accountTypes) &&
    isArr(commentPermissions) &&
    isArr(priceComparison) &&
    isArr(queues) &&
    isArr(discountTypes) &&
    isArr(priorities) &&
    isBool(isDirtyDeal) &&
    isBool(isDirtyCampaign) &&
    isValidActiveItem
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial threads state');
  }

  const {
    activeItem,
    items,
    itemsMeta,
    archived,
    statuses,
    filterStatuses,
    submissionStatuses,
    categories,
    accountTypes,
    commentPermissions,
    priceComparison,
    queues,
    discountTypes,
    isDirtyDeal,
    isDirtyCampaign,
    priorities,
  } = initialState;

  return {
    activeItem,
    items,
    itemsMeta,
    archived,
    statuses,
    filterStatuses,
    submissionStatuses,
    categories,
    accountTypes,
    commentPermissions,
    priceComparison,
    queues,
    discountTypes,
    isDirtyDeal,
    isDirtyCampaign,
    priorities
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  activeItem: ({ activeItem }) => activeItem,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
  archived: ({ archived }) => archived,
  statuses: ({ statuses }) => statuses,
  filterStatuses: ({ filterStatuses }) => filterStatuses,
  submissionStatuses: ({ submissionStatuses }) => submissionStatuses,
  categories: ({ categories }) => categories,
  accountTypes: ({ accountTypes }) => accountTypes,
  commentPermissions: ({ commentPermissions }) => commentPermissions,
  priceComparison: ({ priceComparison }) => priceComparison,
  queues: ({ queues }) => queues,
  discountTypes: ({ discountTypes }) => discountTypes,
  // TODO delete isDirtyDeal and isDirtyCampaign
  // those two are to prevent you from closing the popup and losing all filled data
  isDirtyDeal: ({ isDirtyDeal }) => isDirtyDeal,
  isDirtyCampaign: ({ isDirtyCampaign }) => isDirtyCampaign,
  priorities: ({ priorities }) => priorities,
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') =>
  store.dispatch('alerts/set', {
    data,
    alertType,
  });

const actions = {
  createDeal: ({ state, commit }, query) =>
    threadsApi.createDeal(query).then((res) => {
      const { activeItem } = state;
      const nextItem = res.data.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextItem,
        },
      };

      commit('SET', nextState);

      handleAlerts(
        {
          data: {
            message: 'Deal created successfully.',
          },
        },
        'success',
      );

      return nextItem;
    }).catch(err => err),
  createCampaign: ({ state, commit }, query) =>
    threadsApi.createCampaign(query).then((res) => {
      const { activeItem } = state;
      const nextItem = res.data.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextItem,
        },
      };

      commit('SET', nextState);

      handleAlerts(
        {
          data: {
            message: 'Campaign created successfully.',
          },
        },
        'success',
      );

      return nextItem;
    }).catch(err => err),
  checkDuplicateDealURL: (_, query) => threadsApi.checkDuplicateDealURL(query),
  updateDeal: ({ commit, state }, query) => (
    threadsApi.updateItem(query).then((res) => {
      const { activeItem } = state;
      const nextItem = res.data.data;
      const nextActiveItem = {
        ...activeItem,
        ...nextItem,
      };

      const nextState = {
        ...state,
        activeItem: nextActiveItem,
      };

      commit('SET', nextState);

      handleAlerts(
        {
          data: {
            message: 'Deal updated successfully.',
          },
        },
        'success',
      );

      return nextActiveItem;
    }).catch(err => err)
  ),
  updateCampaign: ({ commit, state }, query) =>
    threadsApi.updateCampaign(query).then((res) => {
      const { activeItem } = state;
      const nextItem = res.data.data;
      const nextActiveItem = {
        ...activeItem,
        ...nextItem,
      };

      const nextState = {
        ...state,
        activeItem: nextActiveItem,
      };

      commit('SET', nextState);

      handleAlerts(
        {
          data: {
            message: 'Campaign updated successfully.',
          },
        },
        'success',
      );

      return nextItem;
    }).catch(err => err),
  getItems: ({ commit, state }, query) =>
    threadsApi.getItems(query).then((res) => {
      const { data, meta } = res.data;

      const nextState = {
        ...state,
        items: data,
        itemsMeta: meta,
      };

      commit('SET', nextState);
      return { data, meta };
    }).catch(err => err),
  getArchivedItems: ({ commit, state }, query) =>
    threadsApi.getArchivedItems(query).then((res) => {
      const { data, meta } = res.data;

      const nextArchived = {
        data,
        meta,
      };

      const nextState = {
        ...state,
        archived: nextArchived,
      };

      commit('SET', nextState);
      return nextArchived;
    }).catch(err => err),
  getItem: (context, query) =>
    threadsApi.getItem(query).then((res) => res.data.data).catch(err => err),
  getStatuses: ({ commit, state }) =>
    threadsApi.getStatuses().then((res) => {
      const nextStatuses = _.sortBy(res.data.data, (status) =>
        status.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        statuses: nextStatuses,
      };

      return commit('SET', nextState);
    }).catch(err => err),
  getPriorities: ({ commit, state }) =>
    threadsApi.getPriorities().then((res) => {
      const nextState = {
        ...state,
        priorities: res.data.data,
      };

      return commit('SET', nextState);
    }).catch(err => err),
  getFilterStatuses: ({ commit, state }, query) =>
    threadsApi.getFilterStatuses(query).then((res) => {
      const nextFilterStatuses = _.sortBy(res.data.data, (status) =>
        status.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        filterStatuses: nextFilterStatuses,
      };

      commit('SET', nextState);

      return nextFilterStatuses;
    }).catch(err => err),
  getSubmissionStatuses: ({ commit, state }, query) =>
    threadsApi.getSubmissionStatuses(query).then((res) => {
      const nextStatuses = _.sortBy(res.data.data, (status) =>
        status.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        submissionStatuses: nextStatuses,
      };

      return commit('SET', nextState);
    }).catch(err => err),
  setActiveItem: ({ commit, state }, activeItem) => {
    const nextActiveItem = {
      ...state.activeItem,
      ...activeItem,
      comments: state.activeItem.comments, // save comments
    };

    const nextState = {
      ...state,
      activeItem: nextActiveItem,
    };

    commit('SET', nextState);

    return nextActiveItem;
  },
  getActivityLogs: ({ commit, state }, query) =>
    threadsApi.getActivityLogs(query).then((res) => {
      const { activeItem } = state;
      const { data, meta } = res.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          log: {
            items: data,
            meta,
          },
        },
      };

      return commit('SET', nextState);
    }).catch(err => err),
  getCategories: ({ commit, state }, query) =>
    threadsApi.getCategories(query).then((res) => {
      const nextCategories = _.sortBy(res.data.data, (status) =>
        status.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        categories: nextCategories,
      };

      return commit('SET', nextState);
    }).catch(err => err),
  getAccountTypes: ({ commit, state }, query) =>
    threadsApi.getAccountTypes(query).then((res) => {
      const nextAccountTypes = _.sortBy(res.data.data, (status) =>
        status.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        accountTypes: nextAccountTypes,
      };

      commit('SET', nextState);

      return nextAccountTypes;
    }).catch(err => err),
  getUserThreads: (context, query) =>
    threadsApi.getUserThreads(query).then((res) => {
      const { data, meta } = res.data;

      return { data, meta };
    }).catch(err => err),
  assignThread: (context, query) =>
    threadsApi.assignThread(query).then((res) => {
      handleAlerts(
        {
          data: {
            message: 'Deal assigned successfully.',
          },
        },
        'success',
      );

      return res.data.data;
    }).catch(err => err),
  denyThread: (_, query) =>
    threadsApi.denyThread(query).then((res) => {
      handleAlerts(
        {
          data: {
            message: 'Deal denied successfully.',
          },
        },
        'success',
      );

      store.dispatch('threads/setActiveItem', res.data.data);

      return res.data.data;
    }).catch(err => err),
  archiveThread: ({ state, commit }, query) =>
    threadsApi.archiveThread(query).then((res) => {
      const nextComment = res.data.data;

      handleAlerts(
        {
          data: {
            message: 'Deal archived successfully.',
          },
        },
        'success',
      );

      const nextActiveItem = {
        ...state.activeItem,
        comments: [nextComment, ...state.activeItem.comments],
      };

      const nextState = {
        ...state,
        activeItem: nextActiveItem,
      };

      commit('SET', nextState);

      return nextComment;
    }).catch(err => err),
  reopenThread: (_, query) =>
    threadsApi.reopenThread(query).then((res) => {
      handleAlerts(
        {
          data: {
            message: 'Deal reopened successfully.',
          },
        },
        'success',
      );

      store.dispatch('threads/setActiveItem', res.data.data);

      return res.data.data;
    }).catch(err => err),
  publishThread: (context, query) =>
    threadsApi.publishThread(query).then((res) => res.data.data).catch(err => err),
  publishThreadWithUrl: (context, query) =>
    threadsApi.publishThreadWithUrl(query).then((res) => {
      handleAlerts(
        {
          data: {
            message: 'Deal Thread URL has been set.',
          },
        },
        'success',
      );

      store.dispatch('threads/setActiveItem', res.data.data);

      return res.data.data;
    }).catch(err => err),
  acceptThread: (context, query) =>
    threadsApi.acceptThread(query).then((res) => {
      handleAlerts(
        {
          data: {
            message: 'Deal accepted successfully.',
          },
        },
        'success',
      );

      store.dispatch('threads/setActiveItem', res.data.data);

      return res.data.data;
    }).catch(err => err),
  getComments: ({ commit, state }, query) =>
    threadsApi.getComments(query).then((res) => {
      const nextComments = res.data.data;
      const nextState = {
        ...state,
        activeItem: {
          ...state.activeItem,
          comments: nextComments,
        },
      };

      commit('SET', nextState);

      return nextComments;
    }).catch(err => err),
  getCommentPermissions: ({ commit, state }, query) =>
    threadsApi.getCommentPermissions(query).then((res) => {
      const nextCommentPermissions = _.sortBy(res.data.data, (status) =>
        status.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        commentPermissions: nextCommentPermissions,
      };

      commit('SET', nextState);

      return nextCommentPermissions;
    }).catch(err => err),
  commentThread: ({ state, commit }, query) =>
    threadsApi.commentThread(query).then((res) => {
      const nextComment = { ...res.data.data }

      handleAlerts(
        {
          data: {
            message: 'Your comment has been posted.',
          },
        },
        'success',
      );

      const activeItem = {...state.activeItem};

      const nextActiveItem = {
        ...activeItem,
        comments: [nextComment, ...activeItem.comments],
      };

      const nextState = {
        ...state,
        activeItem: nextActiveItem,
      };

      commit('SET', nextState);

      return nextComment;
    }).catch(err => err),
  getLabels: ({ commit, state }, query) =>
    threadsApi.getLabels(query).then((res) => {
      const { activeItem } = state;
      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          labels: res.data.data,
        },
      };

      return commit('SET', nextState);
    }).catch(err => err),
  updateLabels: ({ commit, state }, query) =>
    threadsApi.updateLabels(query).then((res) => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          labels: res.data.data,
        },
      };

      handleAlerts(
        {
          data: {
            message: 'Labels updated successfully.',
          },
        },
        'success',
      );

      return commit('SET', nextState);
    }).catch(err => err),
  getAllExternalMerchants: (context, query) =>
    threadsApi.getAllExternalMerchants(query).then((res) => res.data.data).catch(err => err),
  searchExternalMerchants: (context, query) =>
    threadsApi.searchExternalMerchants(query).catch(err => err),
  getExternalMerchants: ({ commit, state }, query) =>
    threadsApi.getExternalMerchants(query).then((res) => {
      const { activeItem } = state;

      // TODO: Revise this once the backend returns correct data

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          merchants: [res.data.data],
        },
      };

      return commit('SET', nextState);
    }).catch(err => err),
  addExternalMerchant: ({ commit, state }, query) =>
    threadsApi.addExternalMerchant(query).then((res) => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          merchant: res.data.data,
        },
      };

      handleAlerts(
        {
          data: {
            message: 'External merchant updated successfully.',
          },
        },
        'success',
      );

      return commit('SET', nextState);
    }).catch(err => err),
  getExternalMerchantDeals: (context, query) =>
    threadsApi.getExternalMerchantDeals(query).then((res) => res.data.data).catch(err => err),
  getPriceComparison: ({ commit, state }, query) =>
    threadsApi.getPriceComparison(query).then((res) => {
      const nextState = {
        ...state,
        priceComparison: res.data.data,
      };

      return commit('SET', nextState);
    }).catch(err => err),
  getDuplicates: ({ commit, state }, query) =>
    threadsApi.getDuplicates(query).then((res) => {
      const { activeItem } = state;
      const nextDuplicates = res.data.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          duplicates: nextDuplicates,
        },
      };

      commit('SET', nextState);

      return nextDuplicates;
    }).catch(err => err),
  getQueues: ({ commit, state }, query) =>
    threadsApi.getQueues(query).then((res) => {
      const nextState = {
        ...state,
        queues: res.data.data,
      };

      return commit('SET', nextState);
    }).catch(err => err),
  getDiscountTypes: ({ commit, state }, query) =>
    threadsApi.getDiscountTypes(query).then((res) => {
      const nextState = {
        ...state,
        discountTypes: res.data.data,
      };

      return commit('SET', nextState);
    }).catch(err => err),
  getCommentFilters: () => threadsApi.getCommentFilters().catch(err => err),
  setDirtyDeal: ({ commit, state }, isDirtyDeal) => {
    const nextState = {
      ...state,
      isDirtyDeal,
    };

    commit('SET', nextState);
  },
  setDirtyCampaign: ({ commit, state }, isDirtyCampaign) => {
    const nextState = {
      ...state,
      isDirtyCampaign,
    };

    commit('SET', nextState);
  },
  getThreadCommentTemplates: (context, query) =>
    threadsApi.getThreadCommentTemplates(query).then((res) => res.data.data).catch(err => err),
  reset: ({ commit }) => commit('SET', initialState.threads),
  set: ({ commit }, threads) => {
    commit('SET', threads);
  },
};

const mutations = {
  SET(state, threads) {
    /* eslint-disable no-param-reassign */
    state.activeItem = threads.activeItem;
    state.items = threads.items;
    state.itemsMeta = threads.itemsMeta;
    state.statuses = threads.statuses;
    state.filterStatuses = threads.filterStatuses;
    state.submissionStatuses = threads.submissionStatuses;
    state.archived = threads.archived;
    state.categories = threads.categories;
    state.accountTypes = threads.accountTypes;
    state.commentPermissions = threads.commentPermissions;
    state.priceComparison = threads.priceComparison;
    state.queues = threads.queues;
    state.discountTypes = threads.discountTypes;
    state.isDirtyDeal = threads.isDirtyDeal;
    state.isDirtyCampaign = threads.isDirtyCampaign;
    state.priorities = threads.priorities;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
