// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import VApp from 'vuetify/lib/components/VApp/VApp';
import VGrid from 'vuetify/lib/components/VGrid';
import '../assets/styles/main.scss';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  components: {
    VApp,
    VGrid,
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.orange.darken2,
        secondary: colors.grey.darken3,
        accent: colors.orange.darken2,
        error: colors.red.darken1,
        warning: colors.yellow.darken2,
        info: colors.blue.darken1,
        highlight: colors.blue.darken1,
        success: colors.green.darken1,
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 420,
    },
    scrollbarWidth: 10,
  },
});
