export default [
  {
    path: '/business-users',
    name: 'business-users',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'user',
          actions: ['view-nav-link-business'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/businessUsers/Index'
      ),
  },
  {
    path: '/invitation/join/:token',
    name: 'invite-business-user',
    meta: {
      loadState: true,
      auth: false,
      isAuthPage: true,
    },
    // route level code-splitting
    component: () =>
      import( '../../views/businessUsers/Invite'),
  },
];
