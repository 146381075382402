<template>
  <Tooltip
    :text="activeTooltip.text"
    :link="activeTooltip.link"
    :position="activeTooltip.position"
    :width="activeTooltip.width"
    :delay="activeTooltip.delay"
    :icon-size="activeTooltip.iconSize"
    :icon-color="activeTooltip.iconColor"
  />
</template>

<script>
  import Tooltip from './Tooltip';

  export default {
    components: {
      Tooltip,
    },
    props: {
      item: {
        type: String,
        default: '',
      },
      defaultDelay: {
        type: Number,
        default: 1000,
      },
    },
    data() {
      return {
        tooltips: {
          ThreadsTableStartDate: {
            text: this.$t('threads_table_start_date_tooltip'),
            iconSize: '13px',
          },
          ThreadsTableStatus: {
            text: this.$t('threads_table_status_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          ThreadsTablesSubmitted: {
            text: this.$t('threads_tables_submit_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          ArchivedThreadsTableStartDate: {
            text: this.$t('archived_threads_table_start_date_tooltip'),
            iconSize: '13px',
          },
          ArchivedThreadsTableStatus: {
            text: this.$t('archived_threads_table_status_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          promotionsTableMerchant: {
            text: this.$t('promotions_table_merchant_tooltip'),
            iconSize: '13px',
          },
          promotionsTableAds: {
            text: this.$t('promotions_table_ads_tooltip'),
            iconSize: '13px',
          },
          promotionsTableStart: {
            text: this.$t('promotions_table_start_tooltip'),
            iconSize: '13px',
          },
          promotionsTableEnd: {
            text: this.$t('promotions_table_end_tooltip'),
            iconSize: '13px',
          },
          promotionsTableImpressions: {
            text: this.$t('the_value_describes_the_number'),
            link: import.meta.env.VUE_APP_HELP_IMPRESSIONS_URL,
            delay: this.defaultDelay,
            position: 'left',
            iconSize: '13px',
          },
          adsTableStatus: {
            text: this.$t('ads_table_status_tooltip'),
            iconSize: '13px',
          },
          adsTableProgress: {
            text: this.$t('ads_table_progress_tooltip'),
            iconSize: '13px',
          },
          adsTableImpressions: {
            text: this.$t('the_value_describes_the_number'),
            link: import.meta.env.VUE_APP_HELP_IMPRESSIONS_URL,
            delay: this.defaultDelay,
            position: 'left',
            iconSize: '13px',
          },
          CPCReportsTableDailyClickouts: {
            text: this.$t('cpc_reports_table_daily_clickouts_tooltip'),
            link: import.meta.env.VUE_APP_HELP_REPORTS_URL,
            delay: this.defaultDelay,
            position: 'left',
            iconSize: '13px',
          },
          CPCReportsTableDailyCommission: {
            text: this.$t('cpc_reports_table_daily_commission_tooltip'),
            link: import.meta.env.VUE_APP_HELP_REPORTS_URL,
            delay: this.defaultDelay,
            position: 'left',
            iconSize: '13px',
          },
          CPCReportsAdvancedTableExternalLink: {
            text: this.$t('cpc_reports_advanced_table_external_link_tooltip'),
            iconSize: '13px',
          },
          CPCReportsAdvancedTableSourceUrl: {
            text: this.$t('cpc_reports_advanced_table_source_url_tooltip'),
            iconSize: '13px',
          },
          CPCReportsAdvancedTableRate: {
            text: this.$t('cpc_reports_advanced_table_rate_tooltip'),
            iconSize: '13px',
          },
          CPCReportsAdvancedTableUniqueClickouts: {
            text: this.$t('cpc_reports_advanced_table_unique_clickouts_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          CPCReportsAdvancedTableCommission: {
            text: this.$t('cpc_reports_advanced_table_commission_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          CPMReportsTableAd: {
            text: this.$t('cpm_reports_table_ad_tooltip'),
            iconSize: '13px',
          },
          CPMReportsTableImpressions: {
            text: this.$t('cpm_reports_table_impressions_tooltip'),
            link: import.meta.env.VUE_APP_HELP_REPORTS_URL,
            delay: this.defaultDelay,
            position: 'left',
            iconSize: '13px',
          },
          CPMReportsTableTotalClickouts: {
            text: this.$t('cpm_reports_table_total_clickouts_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          CPMReportsTableOrganicClickouts: {
            text: this.$t('cpm_reports_table_organic_clickouts_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          CPMReportsTablePaidClickouts: {
            text: this.$t('cpm_reports_table_paid_clickouts_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          CPMReportsTableClickoutsBoost: {
            text: this.$t('cpm_reports_table_clickouts_boost_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          CPMReportsTableTotalDealVisits: {
            text: this.$t('cpm_reports_table_total_deal_visits_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          CPMReportsTableOrganicDealVisits: {
            text: this.$t('cpm_reports_table_organic_deal_visits_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          CPMReportsTablePaidDealVisits: {
            text: this.$t('cpm_reports_table_paid_deal_visits_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          CPMReportsTableDealVisitsBoost: {
            text: this.$t('cpm_reports_table_deal_visits_boost_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          CPMReportsTableCommission: {
            text: this.$t('cpm_reports_table_commission_tooltip'),
            link: import.meta.env.VUE_APP_HELP_REPORTS_URL,
            position: 'left',
            iconSize: '13px',
          },
          CPMReportsTableCTR: {
            text: this.$t('cpm_reports_table_ctr_tooltip'),
            position: 'left',
            iconSize: '13px',
          },
          BillingTablesContract: {
            text: this.$t('billing_tables_contract_tooltip'),
            link: import.meta.env.VUE_APP_HELP_BILLING_URL,
            delay: this.defaultDelay,
            iconSize: '13px',
          },
          BusinessMembersTablesStatus: {
            text: this.$t('business_members_tables_status_tooltip'),
            link: import.meta.env.VUE_APP_HELP_ADDING_MEMBER_URL,
            delay: this.defaultDelay,
            position: 'left',
            iconSize: '13px',
          },
          BusinessMembersTablesRole: {
            text: this.$t('business_members_tables_role_tooltip'),
            link: import.meta.env.VUE_APP_HELP_ADDING_MEMBER_URL,
            delay: this.defaultDelay,
            position: 'left',
            iconSize: '13px',
          },
        },
      };
    },
    computed: {
      activeTooltip() {
        return this.tooltips[this.item];
      },
    },
    methods: {},
  };
</script>

<style lang="scss" scoped></style>
