<template>
  <div :class="['tag-custom', `tag-custom__${type}`]">
    {{ label }}
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: [String, Number],
        required: true,
      },
      type: {
        type: String,
        default: '',
      }
    },
  };
</script>

<style lang="scss" scoped>
@import '../../assets/styles/theme';

.tag-custom {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.25;
  min-width: 27px;
  padding: 6px 8px;
  margin: 0 4px 4px 0;
  border-radius: 20px;
  color: map-get($colors, text);
  background-color: map-deep-get($colors, grey, lighten-4);

  &__error {
    color: map-get($colors, error);
    background-color: map-deep-get($colors, red, lighten-4);
  }

  &__success {
    color: map-get($colors, error);
    background-color: map-deep-get($colors, red, lighten-4);
  }
}
</style>
