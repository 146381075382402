import Vue from 'vue';
import bugsnag from '@bugsnag/js';
import bugsnagVue from '@bugsnag/plugin-vue';

const isDevEnv = process.env.NODE_ENV === 'development'; // normal development
const isTestEnv = process.env.NODE_ENV === 'test'; // unit testing

const bs = {
  client: null,
};

// execute only in beta/production env
if (!isDevEnv && !isTestEnv) {
  const appVersion = import.meta.env.VUE_APP_VERSION;
  const bugsnagApiKey = import.meta.env.VUE_APP_BUGSNAG_API_KEY;
  const bugsnagReportStage = import.meta.env.VUE_APP_BUGSNAG_REPORT_STAGE;
  const releaseStage = bugsnagReportStage !== undefined
      ? bugsnagReportStage
      : process.env.NODE_ENV;
  const userRole = JSON.parse(localStorage.getItem('pepper_business'))?.auth.role.slug ?? 'not logged in';
  const user = {
    role: userRole,
  }

  bs.client = bugsnag({
    appVersion,
    apiKey: bugsnagApiKey,
    releaseStage,
    user
  });

  bs.client.use(bugsnagVue, Vue);
}

export default bs;
