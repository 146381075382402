import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      countries,
      page = 1,
      perPage = maxItemsPerPage,
      sort = '',
      order = 'asc',
      search = '',
      language = '',
    } = options;

    const params = {
      page,
      countries,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (language !== '') {
      params.lang = language;
    }

    if (sort !== '') {
      params.sort = sort;
      params.order = order;
    }

    if (search !== '') {
      params.search = search;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/comments`,
      params,
    });
  },
  createItem(comment) {
    return axios({
      method: 'post',
      url: `${apiUrl}/comments`,
      data: {
        ...comment,
      },
    });
  },
  updateItem(comment) {
    return axios({
      method: 'put',
      url: `${apiUrl}/comments/${comment.id}`,
      data: {
        ...comment,
      },
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/comments/${id}`,
    });
  },
  removeItem({ id }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/comments/${id}`,
    });
  },
};
