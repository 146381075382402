// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
// eslint-disable-next-line import/no-cycle
import router from '../../router';
import { isObj, isArr } from '@/utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const promotionsApi = apiClient.merchy.promotions;

/**
 * @description Is valid
 * @param promotions
 * @returns {boolean}
 */
const isValid = (promotions) => {
  const {
    activeItem,
    items,
    itemsMeta,
    activeLineItem,
    advertisers,
    advertisersMeta,
    statuses,
    orderStatuses,
    lineStatuses,
    types,
    costTypes,
    placements,
    geo,
    devices,
    targetGroups,
    targetUsers,
    freqTimeUnits,
    threads,
    forecast,
    businessMerchants,
    accountTypes,
    contractNames,
    orderTypes
  } = promotions;

  return (
    isObj(promotions) &&
    isObj(activeItem) &&
    isArr(items) &&
    isObj(itemsMeta) &&
    isObj(activeLineItem) &&
    isArr(advertisers) &&
    isObj(advertisersMeta) &&
    isArr(statuses) &&
    isArr(orderStatuses) &&
    isArr(lineStatuses) &&
    isArr(types) &&
    isArr(costTypes) &&
    isArr(placements) &&
    isArr(geo) &&
    isArr(devices) &&
    isArr(targetGroups) &&
    isArr(targetUsers) &&
    isArr(freqTimeUnits) &&
    isArr(threads) &&
    isArr(businessMerchants) &&
    isArr(forecast) &&
    isArr(accountTypes) &&
    isArr(contractNames) &&
    isArr(orderTypes)
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial google-manager state');
  }

  const {
    activeItem,
    items,
    itemsMeta,
    activeLineItem,
    advertisers,
    advertisersMeta,
    statuses,
    orderStatuses,
    lineStatuses,
    types,
    costTypes,
    placements,
    geo,
    devices,
    targetGroups,
    targetUsers,
    freqTimeUnits,
    threads,
    forecast,
    businessMerchants,
    accountTypes,
    contractNames,
    orderTypes
  } = initialState;

  return {
    activeItem,
    items,
    itemsMeta,
    activeLineItem,
    advertisers,
    advertisersMeta,
    statuses,
    orderStatuses,
    lineStatuses,
    types,
    costTypes,
    placements,
    geo,
    devices,
    targetGroups,
    targetUsers,
    freqTimeUnits,
    threads,
    businessMerchants,
    forecast,
    accountTypes,
    contractNames,
    orderTypes
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  activeItem: ({ activeItem }) => activeItem,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
  activeLineItem: ({ activeLineItem }) => activeLineItem,
  advertisers: ({ advertisers }) => advertisers,
  advertisersMeta: ({ advertisersMeta }) => advertisersMeta,
  statuses: ({ statuses }) => statuses,
  types: ({ types }) => types,
  orderStatuses: ({ orderStatuses }) => orderStatuses,
  lineStatuses: ({ lineStatuses }) => lineStatuses,
  costTypes: ({ costTypes }) => costTypes,
  placements: ({ placements }) => placements,
  geo: ({ geo }) => geo,
  devices: ({ devices }) => devices,
  targetGroups: ({ targetGroups }) => targetGroups,
  targetUsers: ({ targetUsers }) => targetUsers,
  freqTimeUnits: ({ freqTimeUnits }) => freqTimeUnits,
  threads: ({ threads }) => threads,
  businessMerchants: ({ businessMerchants }) => businessMerchants,
  forecast: ({ forecast }) => forecast,
  accountTypes: ({ accountTypes }) => accountTypes,
  contractNames: ({ contractNames }) => contractNames,
  orderTypes: ({ orderTypes }) => orderTypes,
};

/**
 * @description Handle alerts
 * @param message
 * @param alertType
 */
const handleAlerts = (message, alertType = 'success') => {
  const alert = {
    data: {
      message,
    },
  };

  return store.dispatch('alerts/set', {
    data: alert,
    alertType,
  });
};

const actions = {
  createOrder: (context, order) =>
    promotionsApi
      .createOrder(order)
      .then((res) => {
        if (!order.brief) {
          handleAlerts(
            `${router.app.$t('order')} ${router.app.$t(
              'created_successfully',
            )}.`,
          );
        }

        return {
          success: true,
          data: res,
        };
      })
      .catch((err) => ({
        success: false,
        error: err,
      })),
  updateOrder: ({ commit, state }, order) =>
    promotionsApi
      .updateOrder(order)
      .then((res) => {
        const { activeItem } = state;
        const nextOrder = res.data.data;

        const nextState = {
          ...state,
          activeItem: {
            ...activeItem,
            ...nextOrder,
          },
        };

        commit('SET', nextState);

        if (!order.brief) {
          const item = order.isBC
            ? router.app.$t('promotion')
            : router.app.$t('order');
          handleAlerts(`${item} ${router.app.$t('updated_successfully')}.`);
        }

        return {
          success: true,
          data: res,
        };
      })
      .catch((err) => ({
        success: false,
        error: err,
      })),
  createLineItem: (context, lineItem) =>
    promotionsApi
      .createLineItem(lineItem)
      .then((res) => {
        if (!lineItem.brief) {
          const item = lineItem.isBC
            ? router.app.$t('ad')
            : router.app.$t('line_item');
          handleAlerts(`${item} ${router.app.$t('created_successfully')}.`);
        }

        return {
          success: true,
          data: res,
        };
      })
      .catch((err) => ({
        error: err,
      })),
  updateLineItem: ({ commit, state }, lineItem) =>
    promotionsApi
      .updateLineItem(lineItem)
      .then((res) => {
        const { data } = res.data;
        const nextItems = {
          activeLineItem: data,
        };

        const nextState = {
          ...state,
          ...nextItems,
        };

        commit('SET', nextState);

        if (!lineItem.brief) {
          const item = lineItem.isBC
            ? router.app.$t('ad')
            : router.app.$t('line_item');
          handleAlerts(`${item} ${router.app.$t('updated_successfully')}.`);
        }

        return nextItems;
      })
      .catch((err) => ({
        success: false,
        error: err,
      })),
  updateStatus: ({ commit, state }, order) =>
    promotionsApi.updateStatus(order).then((res) => {
      const { activeItem } = state;
      const nextStatus = res.data.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextStatus,
        },
      };

      commit('SET', nextState);
      const item = order.isBC
        ? router.app.$t('promotion')
        : router.app.$t('order');
      return handleAlerts(`${item} ${router.app.$t('updated_successfully')}.`);
    }),
  updateLineStatus: (context, lineItem) =>
    promotionsApi.updateLineStatus(lineItem).then(() => {
      const item = lineItem.isBC
        ? router.app.$t('ad')
        : router.app.$t('line_item');
      return handleAlerts(`${item} ${router.app.$t('updated_successfully')}.`);
    }),
  /* eslint-disable arrow-body-style */
  getOrders: ({ commit, state }, query) => {
    return promotionsApi.getOrders(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = {
        items: data,
        itemsMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getOrder: ({ commit, state }, query) => {
    return promotionsApi.getOrder(query).then((res) => {
      const { data } = res.data;

      const nextState = {
        ...state,
        activeItem: data,
      };

      commit('SET', nextState);

      return data;
    });
  },
  // eslint-disable-next-line no-unused-vars
  searchOrders: ({ commit, state }, query) => {
    return promotionsApi.getOrders(query).then((res) => res.data.data);
  },
  getLineItemById: ({ commit, state }, query) => {
    return promotionsApi.getLineItemById(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        activeLineItem: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getAccountTypes: ({ commit, state }, query) => {
    return promotionsApi.getAccountTypes(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        accountTypes: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getContractNames: ({ commit, state }, query) => {
    return promotionsApi.getContractNames(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        contractNames: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  clearContractNames: ({ commit, state }) => {
      const nextItems = {
        contractNames: [],
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
  },
  getOrderTypes: (query) => {
    return promotionsApi.getOrderTypes(query).then(res => res);
  },
  getOrderTypesForFilter: ({ commit, state }, query) => {
    return promotionsApi.getOrderTypesForFilter(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        orderTypes: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getStatuses: ({ commit, state }, query) => {
    return promotionsApi.getStatuses(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        statuses: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getOrderStatuses: ({ commit, state }, query) => {
    return promotionsApi.getOrderStatuses(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        orderStatuses: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getLineStatuses: ({ commit, state }, query) => {
    return promotionsApi.getLineStatuses(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        lineStatuses: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getTypes: ({ commit, state }, query) => {
    return promotionsApi.getTypes(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        types: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getCostTypes: ({ commit, state }, query) => {
    return promotionsApi.getCostTypes(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        costTypes: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getPlacementsTypes: () => {
    return promotionsApi.getPlacementsTypes().then((res) => {
      return res.data.data;
    });
  },
  getPlacements: ({ commit, state }, query) => {
    return promotionsApi.getPlacements(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        placements: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getGeo: ({ commit, state }, query) => {
    return promotionsApi.getGeo(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        geo: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getDevices: ({ commit, state }, query) => {
    return promotionsApi.getDevices(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        devices: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getTargetGroups: ({ commit, state }, query) => {
    return promotionsApi.getTargetGroups(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        targetGroups: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getTargetUsers: ({ commit, state }) => {
    return promotionsApi.getTargetUsers().then((res) => {
      const { data } = res.data;
      const nextItems = {
        targetUsers: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getFreqTimeUnits: ({ commit, state }) => {
    return promotionsApi.getFreqTimeUnits().then((res) => {
      const { data } = res.data;
      const nextItems = {
        freqTimeUnits: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getAdvertisers: ({ commit, state }, query) => {
    return promotionsApi.getAdvertisers(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = {
        advertisers: data,
        advertisersMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  setActiveOrder: ({ commit, state }, nextActiveItem) => {
    const nextState = {
      ...state,
      activeItem: {
        ...state.activeItem,
        ...nextActiveItem,
      },
    };

    return commit('SET', nextState);
  },
  getThreads: ({ commit, state }, query) => {
    return promotionsApi.getThreads(query).then((res) => {
      const { data } = res.data;

      const nextItems = query.loadMore
        ? {
          threads: [...state.threads, ...data],
        }
        : {
          threads: data,
        };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getBusinessMerchants: ({ commit, state }, query) => {
    return promotionsApi.getBusinessMerchants(query).then((res) => {
      const nextItems = {
        businessMerchants: res.data.data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  addBusinessMerchants: ({ commit, state }, query) => {
    return promotionsApi.addBusinessMerchants(query).then((res) => {
      const nextItems = {
        businessMerchants: res.data.data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  getForecast: ({ commit, state }, query) => {
    return promotionsApi.getForecast(query).then((res) => {
      const { data } = res.data;
      const nextItems = {
        forecast: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);
      return nextItems;
    });
  },
  reset: ({ commit }) => commit('SET', initialState.promotions),
  set: ({ commit }, promotions) => {
    commit('SET', promotions);
  },
};

const mutations = {
  SET(state, promotions) {
    /* eslint-disable no-param-reassign */
    state.items = promotions.items;
    state.itemsMeta = promotions.itemsMeta;
    state.activeItem = promotions.activeItem;
    state.activeLineItem = promotions.activeLineItem;
    state.advertisers = promotions.advertisers;
    state.advertisersMeta = promotions.advertisersMeta;
    state.statuses = promotions.statuses;
    state.orderStatuses = promotions.orderStatuses;
    state.lineStatuses = promotions.lineStatuses;
    state.types = promotions.types;
    state.costTypes = promotions.costTypes;
    state.placements = promotions.placements;
    state.geo = promotions.geo;
    state.devices = promotions.devices;
    state.targetGroups = promotions.targetGroups;
    state.targetUsers = promotions.targetUsers;
    state.freqTimeUnits = promotions.freqTimeUnits;
    state.threads = promotions.threads;
    state.businessMerchants = promotions.businessMerchants;
    state.forecast = promotions.forecast;
    state.accountTypes = promotions.accountTypes;
    state.contractNames = promotions.contractNames;
    state.orderTypes = promotions.orderTypes;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
