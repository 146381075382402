export default [
  {
    path: '/users',
    name: 'users',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'user',
          actions: ['view-nav-link', 'view'],
        },
      ],
    },
    // route level code-splitting
    component: () => import( '../../views/users/Index.vue'),
  },
  {
    path: '/users/pending',
    name: 'users-pending',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'user',
          actions: ['view-nav-link', 'view'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import(/* webpackChunkName: "users" */ '../../views/users/Pending'),
  },
  {
    path: '/users/:id(\\d+)',
    name: 'user-details',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'user',
          actions: ['view'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import( '../../views/users/Single'),
  },
  {
    path: '/users/:id(\\d+)/settings',
    name: 'user-settings',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'user',
          actions: ['see-profile-settings'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/users/Settings'
      ),
  },
  {
    path: '/users/:id(\\d+)/invite',
    name: 'invite-users',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'user',
          actions: ['invite'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import( '../../views/users/Invite'),
  },
];
