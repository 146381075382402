<template>
  <div
    data-component-group="layout"
    data-component="logged-frame"
  >
    <layout-logged-header :title="title + totalCountText">
      <slot name="header-actions" />
    </layout-logged-header>

    <section v-if="hasBefore">
      <slot name="before" />
    </section>

    <v-layout :class="getLayoutClass()">
      <div class="logged-frame__content">
        <slot name="before-content" class="flex wide"/>

        <aside v-if="hasAside">
          <slot name="aside" />
        </aside>

        <section class="main-content">
          <slot name="content" />
        </section>
      </div>
    </v-layout>
  </div>
</template>

<script>
  import { showPageScroll } from '../../utils';
  import { isNum } from '../../utils';

  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      totalCount: {
        type: Number,
        default: null
      },
      hasBefore: {
        type: Boolean,
        default: false,
      },
      hasAside: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      showPageScroll();
    },
    computed: {
      totalCountText() {
        return isNum(this.totalCount) ? ` (${this.totalCount})` : ''
      }
    },
    methods: {
      getLayoutClass() {
        return {
          normal: true,
          'view-wrapper': true,
          'has-aside': this.hasAside,
        };
      },
    },
  };
</script>

<style lang="scss">
  @import '../../assets/styles/theme';

  [data-component-group="layout"][data-component="logged-frame"] {
    .view-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0 $spacing-xl;

      @media (max-width: map-get($breakpoints, md)) {
        padding: 0 $spacing-sm + 0.125rem;
      }

    &:not(.has-aside) {
      .logged-frame__content {
        .main-content {
          width: 100%;
        }
      }
    }

    .logged-frame__content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: $spacing-xl 0;

      @media (max-width: map-get($breakpoints, md)) {
        display: block;
      }

      aside {
        width: 387px;
        padding: 0 $spacing-xl 0 0;

        @media (max-width: map-get($breakpoints, md)) {
          width: 100%;
          padding: 0 0 $spacing-md 0;
        }
      }

      .main-content {
        width: calc(100% - 280px);

          @media (max-width: map-get($breakpoints, md)) {
            width: 100%;
          }
        }
      }

      &.has-aside {
        .logged-frame__content {
          display: flex;
          width: 100%;
          padding: $spacing-xl 0;

          @media (max-width: map-get($breakpoints, md)) {
            display: block;
          }

          aside {
            width: 387px;
            padding: 0 32px 0 0;

            @media (max-width: map-get($breakpoints, md)) {
              width: 100%;
              padding: 0 0 $spacing-md 0;
            }
          }

          .main-content {
            width: calc(100% - 387px);

            @media (max-width: map-get($breakpoints, md)) {
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>
