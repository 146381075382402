export default [
  {
    path: '/reports/monthly', // rename to /reports/billing?
    name: 'billing-reports',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'billing-reports',
          actions: ['view'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import('../../views/reports/Monthly'),
  },
  {
    path: '/reports/daily',
    name: 'daily-reports-cpc',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'cpc-reports',
          actions: ['view'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import('../../views/reports/Daily'),
  },
  {
    path: '/reports/daily/advanced',
    name: 'advanced-daily-reports-cpc',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'cpc-reports-advanced',
          actions: ['view'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import('../../views/reports/DailyAdvanced'),
  },
  {
    path: '/reports/daily-cpm',
    name: 'daily-reports-cpm',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'cpm-reports',
          actions: ['view'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import('../../views/reports/DailyCPM'),
  },

  {
    path: '/reports',
    name: 'reports',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'performance-reports',
          actions: ['list-all'],
        },
      ],
    },
    // route level code-splitting
    component: () => import('../../views/reports/Index'),
  },
  {
    path: '/reports/:id(\\d+)',
    name: 'reports-details',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'performance-reports',
          actions: ['list-all'],
        },
      ],
    },
    // route level code-splitting
    component: () => import('../../views/reports/Single'),
  },
];
