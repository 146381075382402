<template>
  <div data-component="text-editor" :style="`min-height: ${height}px`">
    <textarea />
  </div>
</template>

<script type="text/javascript">
  import 'trumbowyg';
  import 'trumbowyg/dist/ui/trumbowyg.css';
  import svgIcons from 'trumbowyg/dist/ui/icons.svg';
  import { isStr, isNull } from '../utils';

  const events = [
    'focus',
    'blur',
    'change',
    'resize',
    'paste',
    'openfullscreen',
    'closefullscreen',
    'close',
  ];

  export default {
    props: {
      value: {
        default: null,
        required: true,
        validator(value) {
          return isNull(value) || isStr(value);
        },
      },
      // http://alex-d.github.io/Trumbowyg/documentation.html#basic-options
      config: {
        type: Object,
        default: () => ({}),
      },
      height: {
        type: Number,
        default: 300,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        el: null,
        svgIcons,
      };
    },
    watch: {
      value(newValue) {
        if (this.el) {
          // prevent multiple input events
          if (newValue === this.el.trumbowyg('html')) {
            return;
          }
          // set new value
          this.el.trumbowyg('html', newValue);
        }
      },
      disabled(nextValue) {
        this.toggleDisabled(nextValue);
      },
    },
    mounted() {
      if (this.el) {
        return;
      }
      this.el = jQuery(this.$el);

      // set placeholder
      this.el.attr(
        'placeholder',
        isStr(this.config.placeholder) ? this.config.placeholder : '',
      );

      // init editor with config
      this.el.trumbowyg({
        ...this.config,
        btns: [
          ['strong', 'em', 'underline'],
          ['formatting'],
          ['unorderedList', 'orderedList'],
          ['link'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['removeformat'],
          ['undo', 'redo'],
        ],
        svgPath: this.svgIcons,
      });

      // set initial value
      this.el.trumbowyg('html', this.value);

      // set initial disabled state
      this.toggleDisabled(this.disabled);

      // watch for further changes
      this.el.on('tbwchange', this.onChange);
      this.el.on('tbwpaste', this.onChange);

      // register events
      this.registerEvents();
    },
    beforeDestroy() {
      // free up memory
      if (this.el) {
        this.el.trumbowyg('destroy');
        this.el = null;
      }
    },
    methods: {
      onChange(event) {
        this.$emit('input', event.target.innerHTML);
      },
      registerEvents() {
        events.forEach((name) => {
          this.el.on(`tbw${name}`, (...args) => {
            this.$emit(`tbw-${name}`, ...args);
          });
        });
      },
      toggleDisabled(isDisabled) {
        if (isDisabled) {
          this.el.trumbowyg('disable');
        } else {
          this.el.trumbowyg('enable');
        }
      },
    },
  };
</script>

<style lang="scss">
@import '../assets/styles/theme';

[data-component='text-editor'] {
  background-color: transparent;
  font-size: $text-size-md;
  height: auto !important;
}

.trumbowyg-box {
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  min-height: auto;
  overflow: hidden;
}

.trumbowyg-modal {
  font-family: $font-family;
}

.trumbowyg-editor {
  padding: 8px;
}

.trumbowyg-button-pane button {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  margin-right: 4px;

  &:hover {
    border-color: #BFBFBF;
  }
}

.trumbowyg-button-pane .trumbowyg-button-group::after {
  display: none;
  width: 0;
}

.trumbowyg-button-pane {
  background-color: transparent;
  border-bottom: none;
  margin: 8px 3px 0;
  z-index: 7;
}

.trumbowyg-button-pane:after {
  display: none;
}

.trumbowyg-button-pane:before {
  display: none;
}
</style>
