/* eslint-disable */

/**
 * @responsibility
 * send track events to june
 */
import { createActionName } from './payload';

import store from '../store';
import { Analytics } from '@june-so/analytics-node';

const analyticsToken = import.meta.env.VUE_APP_JUNE_TRACKING_TOKEN;
const analytics = analyticsToken !== undefined ? new Analytics(analyticsToken) : undefined;

// @TODO Test the actions below, compare them against the working testing events - https://gitlab.pepper.com/mvp-computers/pepperbusinessview/-/merge_requests/716/diffs?commit_id=0165ae5b026e67a2962c5d46b383e2a939cdbb07 (at a first look only the createJuneTrackData method doesn't emit a proper event, as the groupId should be provided into the context object, outside properties, check the commit provided. ?? Not sure, might be new version of the plugin or so, to be checked ??)
// @TODO Call person/company creation/updating only on user/business creation, not on every event. And fire the events themselves only on the certain required action (not defined from Product yet)

const createJuneGroupData = () => {
  if (!store.state?.auth?.user) {
    return {};
  }

  const isBusinessRole = JSON.parse(store.state.auth.user.role?.is_business_role);

  let groupData = {
      userId: String(store.state.auth.user.id),
  };

  if (isBusinessRole) {
      groupData = {
          ...groupData,
          groupId: String(store.state.auth.user.business.id),
          traits: {
            name: store.state.auth.user.business.name,
            country: store.state.auth.user.business.country.code,
            // Add anything else about the company here
          },
      }
  }

  return groupData;
};

const createJuneIdentityData = () => {
  if (!store.state?.auth?.user) {
      return {};
  }

  const isBusinessRole = JSON.parse(store.state.auth.user.role?.is_business_role);

  const identityData = {
      userId: String(store.state.auth.user.id),
      traits: {
        email: store.state.auth.user.email,
        firstName: store.state.auth.user.firstname,
        lastName: store.state.auth.user.lastname,
      }
  }

  if (isBusinessRole) {
      identityData.groupId = store.state.auth.user.business_id;
  }

  return identityData;
};

function createJuneTrackData(trackAction) {
  if (!store.state?.auth?.user) {
    return {};
  }

  let context = trackAction.target.name;

  return {
    userId: String(store.state.auth.user.id),
    event: trackAction.target.data.event,
    properties: {
      context,
      // context: camelToSnakeCase(context),
      unit_action: createActionName(trackAction),
      url: location.href,
    }
  };
}

export function trackJune(trackAction) {
  if (!!analytics && import.meta.env.VUE_APP_IS_PROD === 'true') {
    analytics.track(createJuneTrackData(trackAction));
  }

  return true;
}

if (!!analytics && import.meta.env.VUE_APP_IS_PROD === 'true') {
  analytics.identify(createJuneIdentityData());
  analytics.group(createJuneGroupData());
}
