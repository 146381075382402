import AlertBase from '../components/alerts/AlertBase'
import Vue from 'vue';
import VBtn from 'vuetify/es5/components/VBtn';
import VOverflowBtn from 'vuetify/es5/components/VOverflowBtn';
import VIcon from 'vuetify/es5/components/VIcon';
import VTextField from 'vuetify/es5/components/VTextField';
import VSelect from 'vuetify/es5/components/VSelect';
import VAutocomplete from 'vuetify/es5/components/VAutocomplete';
import VTextarea from 'vuetify/es5/components/VTextarea';
import Can from '../components/Can';
import AuthHeader from '../components/layout/header/Auth';
import LoggedHeader from '../components/layout/header/Logged';
import LoggedFrame from '../components/layout/LoggedFrame';
import AuthFrame from '../components/layout/AuthFrame';
import SingleFrame from '../components/layout/SingleFrame';
import SectionHeader from '../components/layout/SectionHeader';
import Dialog from '../components/dialogs/Dialog';
import ConfirmDialog from '../components/dialogs/ConfirmDialog';
import AddDialog from '../components/dialogs/AddDialog';
import EditDialog from '../components/dialogs/EditDialog';
import Dropdown from '../components/dropdowns/Dropdown';
import ColorDropdown from '../components/dropdowns/Color';
import ColorLabel from '../components/labels/Color';
import TextEditor from '../components/TextEditor';
import EnhancedEditor from '../components/EnhancedEditor';
import Table from '../components/tables/Table';
import LangInput from '../components/lang/LangInput';
import DatePicker from '../components/DatePicker';
import FileUpload from '../components/FileUpload';
import Tag from '../components/panels/Tag';
import TableTitle from '../components/panels/TableTitle';
import PlatformSelect from '../components/PlatformSelect';

Vue.component('VBtn', VBtn);
Vue.component('VOverflowBtn', VOverflowBtn);
Vue.component('VIcon', VIcon);
Vue.component('VTextField', VTextField);
Vue.component('VSelect', VSelect);
Vue.component('VAutocomplete', VAutocomplete);
Vue.component('VTextarea', VTextarea);
Vue.component('Can', Can);
Vue.component('LayoutAuthHeader', AuthHeader);
Vue.component('LayoutLoggedHeader', LoggedHeader);
Vue.component('LayoutSectionHeader', SectionHeader);
Vue.component('LayoutLoggedFrame', LoggedFrame);
Vue.component('LayoutAuthFrame', AuthFrame);
Vue.component('LayoutSingleFrame', SingleFrame);
Vue.component('BaseDialog', Dialog);
Vue.component('BaseEditDialog', EditDialog);
Vue.component('BaseAddDialog', AddDialog);
Vue.component('BaseTextEditor', TextEditor);
Vue.component('BaseEnhancedEditor', EnhancedEditor);
Vue.component('BaseTable', Table);
Vue.component('BaseDropdown', Dropdown);
Vue.component('DialogConfirm', ConfirmDialog);
Vue.component('DropdownColor', ColorDropdown);
Vue.component('LabelColor', ColorLabel);
Vue.component('LangInput', LangInput);
Vue.component('BaseDatePicker', DatePicker);
Vue.component('BaseFileUpload', FileUpload);
Vue.component('PanelTag', Tag);
Vue.component('PanelTableTitle', TableTitle);
Vue.component('AlertBase', AlertBase);
Vue.component('PlatformSelect', PlatformSelect);
