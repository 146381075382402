// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
// eslint-disable-next-line import/no-cycle
import initialState from '../initialState';
// eslint-disable-next-line import/no-cycle
import store from '..';
// eslint-disable-next-line import/no-cycle
import router from '../../router';

const networksApi = apiClient.merchy.networks;

/**
 * @description Is valid
 * @param networks
 * @returns {boolean}
 */
const isValid = (networks) => {
  const { items, itemsMeta, statuses, linkedCountries } = networks;

  return (
    isObj(networks) &&
    isArr(items) &&
    isObj(itemsMeta) &&
    isArr(statuses) &&
    isArr(linkedCountries)
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error(router.app.$t('invalid_initial_networks_state'));
  }

  const { items, itemsMeta, statuses, linkedCountries } = initialState;

  return {
    items,
    itemsMeta,
    statuses,
    linkedCountries,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
  statuses: ({ statuses }) => statuses,
  linkedCountries: ({ linkedCountries }) => linkedCountries,
};

/**
 * @description Handle alerts
 * @param message
 * @param alertType
 */
const handleAlerts = (message, alertType = 'success') => {
  const alert = {
    data: {
      message,
    },
  };

  return store.dispatch('alerts/set', {
    data: alert,
    alertType,
  });
};

const actions = {
  getItems: ({ commit, state }, query) =>
    networksApi.getItems(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = {
        items: data,
        itemsMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);

      return nextItems;
    }),
  getLinkedCountries: ({ commit, state }) =>
    networksApi.getLinkedCountries().then((res) => {
      const nextState = {
        ...state,
        linkedCountries: res.data.data,
      };

      commit('SET', nextState);

      return res;
    }),
  searchItems: (context, query) =>
    networksApi.getItems(query).then((res) => res),
  createItem: (context, query) =>
    networksApi.createItem(query).then((res) => {
      handleAlerts(router.app.$t('network_created_successfully'));
      return res;
    }),
  updateItem: (context, query) =>
    networksApi.updateItem(query).then((res) => {
      handleAlerts(router.app.$t('network_updated_successfully'));
      return res;
    }),
  getStatuses: ({ commit, state }) =>
    networksApi.getStatuses().then((res) => {
      const nextState = {
        ...state,
        statuses: res.data.data,
      };

      return commit('SET', nextState);
    }),
  updateItemStatus: (context, query) =>
    networksApi.updateItemStatus(query).then((res) => {
      if (res.data) {
        handleAlerts(router.app.$t('network_status_updated_successfully'));
      }

      return res;
    }),
  reset: ({ commit }) => commit('SET', initialState.networks),
  set: ({ commit }, networks) => {
    commit('SET', networks);
  },
};

const mutations = {
  SET(state, networks) {
    /* eslint-disable no-param-reassign */
    state.items = networks.items;
    state.itemsMeta = networks.itemsMeta;
    state.statuses = networks.statuses;
    state.linkedCountries = networks.linkedCountries;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
