export default [
  {
    path: '/businesses',
    name: 'businesses',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'business',
          actions: ['view-nav-link', 'list-all'],
        },
      ],
    },
    component: () =>
      import('../../views/businesses/Index'),
  },
  {
    path: '/businesses/:id(\\d+)',
    name: 'business-details',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'business',
          actions: ['view'],
        },
      ],
    },
    component: () =>
      import('../../views/businesses/Single'),
  },
];
