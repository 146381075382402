export default [
  {
    path: '/feeds/google-shopping',
    name: 'feed-details',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'user',
          actions: ['submit-shopping-feed'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/feeds/GoogleShopping'
      ),
  },
];
