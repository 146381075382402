const meta = {
  loadState: true,
  auth: true,
  isAuthPage: false,
  permissions: [
    {
      subject: 'event.assets',
      actions: ['list-all'],
    }
  ],
}

export default [
  {
    path: '/events',
    name: 'events',
    component: () => import('../../views/Proxy.vue'),
    meta,
    children: [
      {
        path: 'promotions',
        name: 'event-promotions',
        meta,
        component: () => import('../../views/events/Promotions.vue'),
      },
      {
        path: 'promotion',
        name: 'event-promotion',
        meta,
        component: () => import('../../views/events/Promotions.vue'),
        children: [
          {
            path: 'new',
            name: 'event-promotion-new',
            meta,
            component: () => import('../../components/dialogs/events/PromotionFirstStep.vue'),
          },
          {
            path: 'new-theming',
            name: 'event-promotion-new-theming',
            meta,
            component: () => import('../../components/dialogs/events/PromotionTheming.vue'),
          },
          {
            path: 'new-banner',
            name: 'event-promotion-new-banner',
            meta,
            component: () => import('../../components/dialogs/events/PromotionBanner.vue'),
          },
          {
            path: 'theming/:id(\\d+)',
            name: 'event-promotion-details-theming',
            meta,
            component: () => import('../../components/dialogs/events/PromotionTheming.vue'),
          },
          {
            path: 'banner/:id(\\d+)',
            name: 'event-promotion-details-banner',
            meta,
            component: () => import('../../components/dialogs/events/PromotionBanner.vue'),
          },
        ]
      },
      {
        path: 'assets',
        name: 'event-assets',
        meta,
        component: () => import('../../views/events/Assets.vue'),
        children: [
          {
            path: 'new',
            name: 'event-assets-new',
            meta,
            component: () => import('../../components/dialogs/events/Asset.vue'),
          },
          {
            path: ':id(\\d+)',
            name: 'event-assets-details',
            meta,
            component: () => import('../../components/dialogs/events/Asset.vue'),
          },
        ]
      },
    ]
  },
];
