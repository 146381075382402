<template>
  <div data-component="dropdown">
    <v-menu
      v-model="isOn"
      bottom
      offset-y
      :max-height="maxHeight"
      transition="slide-y-transition"
      content-class="dropdown-menu-content"
      close-on-click
      :close-on-content-click="false"
    >
      <template #activator="{ on }">
        <v-btn
          class="trigger toggle-menu"
          :color="triggerColor"
          :class="getTriggerClass()"
          v-on="on"
          @click="toggleMenu()"
        >
          {{ title }}
          <v-icon class="icon-toggle"> keyboard_arrow_down </v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item[itemKey]"
          :value="item"
          :class="getItemClass(item)"
        >
          <v-checkbox
            v-model="item.isSelected"
            :label="item[itemValue]"
            :readonly="shouldDisable(item)"
            @change="onChangeItem(item)"
          />
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      activeItems: {
        type: Array,
        default() {
          return [];
        },
      },
      itemKey: {
        type: String,
        default: 'id',
      },
      itemValue: {
        type: String,
        default: 'label',
      },
      maxHeight: {
        type: Number,
        default: 360,
      },
      minSelected: {
        type: Number,
        default: -1,
      },
      primaryColor: {
        type: String,
        default: 'primary',
      },
      secondaryColor: {
        type: String,
        default: 'secondary',
      },
      onChangeItem: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        isOn: false,
      };
    },
    computed: {
      selectedItems() {
        return this.items.filter(({ isSelected }) => isSelected);
      },
      activeItemsKeys() {
        const { activeItems, itemKey } = this;
        return activeItems.map((item) => item[itemKey]);
      },
      triggerColor() {
        const { primaryColor, secondaryColor, isOn } = this;
        return isOn ? primaryColor : secondaryColor;
      },
    },
    methods: {
      toggleMenu() {
        this.isOn = !this.isOn;
      },
      getTriggerClass() {
        return {
          active: this.isOn,
        };
      },
      getItemClass(item) {
        return {
          'dropdown-item': true,
          active: this.activeItemsKeys.indexOf(item[this.itemKey]) > -1,
        };
      },
      shouldDisable(item) {
        const { minSelected, selectedItems } = this;

        const isInvalidMinSelected =
          item.isSelected &&
          minSelected !== -1 &&
          selectedItems.length === minSelected;

        return item.isRequired || isInvalidMinSelected;
      },
    },
  };
</script>

<style lang="scss">
@import '../../assets/styles/theme';

.dropdown-menu-content {
  .v-messages {
    display: none;
  }

  .dropdown-item {
    &.active {
      label {
        color: map-get($colors, primary);
      }
    }
  }
}

[data-component='dropdown'] {
  .trigger.toggle-menu {
    margin: 0;

    &:before {
      background-color: map-deep-get($colors, grey, darken-3);
    }

    .icon-toggle {
      color: map-deep-get($colors, shades, primary);
    }

    &:not(.active) {
      .icon-toggle {
        &:last-child {
          transform: rotate(0deg);
          transition: transform 0.15s ease-in-out;
        }
      }
    }

    &.active {
      color: map-deep-get($colors, shades, 'white');
      background-color: map-get($colors, primary);

      .icon-toggle {
        color: map-deep-get($colors, shades, 'white');

        &:last-child {
          transform: rotate(180deg);
          transition: transform 0.15s ease-in-out;
        }
      }
    }
  }
}
</style>
