<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0939 17.1517H18.3138C19.6243 17.1517 20.6845 16.0842 20.6845 14.7737V4.37804C20.6845 3.06018 19.6243 2 18.3138 2H5.37079C4.06029 2 3.00011 3.06754 3.00011 4.37804V14.7663C3.00011 16.0842 4.06029 17.1444 5.37079 17.1444H7.4617V22.0919L13.0939 17.1517Z" :style="`fill: url('#${randomId}')`"/>
        <path d="M10.9884 7.72778H6.86552V11.8507H10.9884V7.72778Z" fill="white"/>
        <path d="M16.8193 7.72778H12.6964V11.8507H16.8193V7.72778Z" fill="white"/>
        <defs>
              <linearGradient :id="randomId" x1="11.8429" y1="2.00033" x2="11.8429" y2="20.2491" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FCB52E"/>
                <stop offset="1" stop-color="#FF7300"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
// random ids are fixing the issue of the same id for multiple components.
// the issue is that when the first image with the same id is hidden, the
// gradient is disappearing for all the other images with the same id.
export default {
  data() {
    return {
      randomId: `gradient1-${Math.random()}`,
    };
  },
};
</script>
