<template>
  <div data-component-group="header-layout" data-component="logged">
    <Header>
      <div style="display: flex; align-items: center">
        <h1>
          {{ title }}
        </h1>
      </div>

      <div class="header-layout__actions">
        <slot />
      </div>
    </Header>
  </div>
</template>

<script>
  import Header from './Header';

  export default {
    components: {
      Header,
    },
    props: {
      title: {
        type: String,
        default: '',
      },
    },
  };
</script>

<style lang="scss">
@import '../../../assets/styles/theme';

[data-component-group='header-layout'][data-component='logged'] {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  h1 {
    font-size: 24px;
  }
}

.header-layout__actions {
  display: flex;
  gap: 16px;
}
</style>
