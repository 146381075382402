import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const urisApi = apiClient.merchy.uris;

/**
 * @description Is valid
 * @param uris
 * @returns {boolean}
 */
const isValid = (uris) => {
  const { activeItem, items, itemsMeta } = uris;

  const isValidActiveItem =
    isObj(activeItem) &&
    isObj(activeItem.log) &&
    isArr(activeItem.log.items) &&
    isObj(activeItem.log.meta);

  return isObj(uris) && isArr(items) && isObj(itemsMeta) && isValidActiveItem;
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial uris state');
  }

  const { activeItem, items, itemsMeta } = initialState;
  return {
    activeItem,
    items,
    itemsMeta,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  activeItem: ({ activeItem }) => activeItem,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') =>
  store.dispatch('alerts/set', {
    data,
    alertType,
  });

const actions = {
  create: (context, shop) =>
    urisApi.createItem(shop).then((res) => {
      const nextUri = res.data.data;
      const { uri } = nextUri;

      return handleAlerts(
        {
          data: {
            message: `URI "${uri}" created successfully.`,
          },
        },
        'success',
      );
    }),
  update: ({ commit, state }, uri) =>
    urisApi.updateItem(uri).then((res) => {
      const { activeItem } = state;
      const nextUri = res.data.data;
      const { uri } = nextUri;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextUri,
        },
      };

      commit('SET', nextState);

      return handleAlerts(
        {
          data: {
            message: `URI "${uri}" has been updated successfully.`,
          },
        },
        'success',
      );
    }),
  getItem: ({ commit, state }, query) =>
    urisApi.getItem(query).then((res) => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...res.data.data,
        },
      };

      return commit('SET', nextState);
    }),
  getItems: ({ commit, state }, query) =>
    urisApi.getItems(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = data.map((item) => ({
        ...item,
        keywords: item.keywords.join(', '),
      }));

      const nextState = {
        ...state,
        items: nextItems,
        itemsMeta: meta,
      };

      return commit('SET', nextState);
    }),
  getStatuses: ({ commit, state }) =>
    urisApi.getStatuses().then((res) => {
      const nextStatuses = _.sortBy(res.data.data, (status) =>
        status.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        statuses: nextStatuses,
      };

      return commit('SET', nextState);
    }),
  setActiveItem: ({ commit, state }, uri) => {
    const nextState = {
      ...state,
      activeItem: {
        ...state.activeItem,
        ...uri,
      },
    };

    return commit('SET', nextState);
  },
  updateDiscount: (context, query) => urisApi.updateDiscount(query),
  getActivityLogs: ({ commit, state }, query) =>
    urisApi.getActivityLogs(query).then((res) => {
      const { activeItem } = state;
      const { data, meta } = res.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          log: {
            items: data,
            meta,
          },
        },
      };

      return commit('SET', nextState);
    }),
  reset: ({ commit }) => commit('SET', initialState.uris),
  set: ({ commit }, uris) => {
    commit('SET', uris);
  },
};

const mutations = {
  SET(state, uris) {
    /* eslint-disable no-param-reassign */
    state.activeItem = uris.activeItem;
    state.items = uris.items;
    state.itemsMeta = uris.itemsMeta;
    state.statuses = uris.statuses;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
