<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.1249 9.3045C20.7392 8.20672 20.1458 7.28696 19.4634 6.54522C18.0689 5.06173 16.4074 4.29032 15.9327 4.08263C15.8734 4.05296 15.8437 3.96395 15.8734 3.90461C16.4668 3.01452 16.9415 2.65849 17.2679 2.42113C17.8612 2.00575 16.9712 1.82773 15.9921 2.21344C14.7756 2.68816 13.9152 3.31122 13.7372 3.45957C13.7075 3.48924 13.6778 3.48924 13.6481 3.48924C13.2921 3.4299 11.2152 3.10353 7.92189 4.05296C4.71756 5.00239 2.78903 6.69357 2.07696 7.67267C1.86927 7.96936 2.10663 8.35507 2.49234 8.20672C3.05606 7.99903 4.21318 6.93092 7.47685 6.33753C9.34604 6.01116 10.7108 6.27819 11.1856 6.39687C11.2746 6.42654 11.3042 6.51555 11.2449 6.57489C10.8295 7.13861 9.19769 9.57153 7.56586 13.5176C7.53619 13.6363 6.52742 16.2175 6.26039 17.078C5.60766 19.1548 5.48898 20.1933 5.48898 20.668C5.48898 21.3504 5.667 21.7064 6.43841 21.6471C7.20982 21.5878 12.9954 21.5284 17.0305 18.8285C19.9678 16.8406 21.1843 14.6154 21.4513 12.5385C21.481 12.3605 21.5403 11.6484 21.4513 10.9067C21.4216 10.2243 21.1843 9.42318 21.1249 9.3045ZM14.9833 17.2263C12.3724 19.2142 10.6218 19.3032 10.0581 19.3922C9.49439 19.4812 9.37571 19.3032 9.43505 18.9472C9.49439 18.6505 9.88009 16.6329 10.8592 13.933C10.9185 13.7253 11.0075 13.5473 11.0669 13.3396C13.1141 7.88035 14.0042 7.22762 14.0042 7.22762C16.9712 8.38474 17.8612 10.8177 17.4755 13.1022C17.1788 14.7044 16.2591 16.2472 14.9833 17.2263Z" fill="#91C03D"/>
    <path d="M21.4955 11.5683C21.491 11.3598 21.4788 11.1357 21.4513 10.9067C21.4216 10.2243 21.1843 9.42318 21.1249 9.3045C20.7392 8.20672 20.1458 7.28696 19.4634 6.54522C18.0689 5.06173 16.4074 4.29032 15.9327 4.08263C15.8734 4.05296 15.8437 3.96395 15.8734 3.90461C16.4668 3.01452 16.9415 2.65849 17.2678 2.42113C17.8612 2.00575 16.9712 1.82773 15.9921 2.21344C14.7756 2.68816 13.9152 3.31122 13.7372 3.45957C13.7075 3.48924 13.6778 3.48924 13.6481 3.48924C13.2921 3.4299 11.2152 3.10353 7.92189 4.05296C4.71756 5.00239 2.78903 6.69357 2.07696 7.67267C1.86927 7.96936 2.10663 8.35507 2.49234 8.20672C3.05606 7.99903 4.21318 6.93092 7.47685 6.33753C9.34604 6.01116 10.7108 6.27819 11.1856 6.39687C11.2746 6.42654 11.3042 6.51555 11.2449 6.57489C10.8295 7.13861 9.19769 9.57153 7.56586 13.5176C7.56022 13.5402 7.51868 13.653 7.45388 13.8255C8.35101 13.9038 9.27216 13.947 10.214 13.947C10.4307 13.947 10.6438 13.9428 10.8571 13.9388C10.8578 13.9368 10.8585 13.9349 10.8592 13.933C10.9185 13.7253 11.0075 13.5473 11.0669 13.3396C13.1141 7.88035 14.0042 7.22762 14.0042 7.22762C16.9633 8.38165 17.8559 10.8046 17.478 13.0839C19.0008 12.6891 20.3477 12.1732 21.4955 11.5683Z" :style="`fill: url('#${randomId}')`"/>
    <defs>
      <linearGradient :id="randomId" x1="13.317" y1="31.2625" x2="11.2562" y2="2.91059" gradientUnits="userSpaceOnUse">
        <stop offset="0.0013" stop-color="white"/>
        <stop offset="0.9997" stop-color="white" stop-opacity="0"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
// random ids are fixing the issue of the same id for multiple components.
// the issue is that when the first image with the same id is hidden, the
// gradient is disappearing for all the other images with the same id.
export default {
  data() {
    return {
      randomId: `gradient-${Math.random()}`,
    };
  },
};
</script>
