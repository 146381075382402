export default [
  {
    path: '/verify-link/:key',
    name: 'verify-link',
    meta: {
      loadState: true,
      auth: false,
      isAuthPage: false,
    },
    component: () =>
      import(
         '../../views/verifyLink/Index'
      ),
  },
];
