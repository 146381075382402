export default [
  {
    path: '/network-review',
    name: 'network-review',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'network',
          actions: ['list-all'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/networkReview/Index'
      ),
  },
];
