/* eslint-disable */

/**
 * @responsibility
 * handle track contexts
 */
import jquery_1 from 'jquery';
import { isCamelCase } from '../utils/strings';
import { getPortalSource } from '../utils/vue';

const isProduction = process.env.NODE_ENV === 'production';
/**
 * Returns track context for given elem.
 */
export function getTrackContext(elem) {
  return {
    name: elem.dataset.t,
    // html rendered through twig provides context data via data-t-d; TODO: adjust when twig is gone
    data: contextData.get(elem) || (elem.dataset.tD ? JSON.parse(elem.dataset.tD) : null),
  };
}
/**
 * Marks given elem as track context by assigning track name & optional data. Also updates existing track context.
 */
export function setTrackContext(elem, name, data) {
  validateContextName(name);
  // We could store the context name together with the context data in a WeakMap, but data attributes are needed for
  // twig rendering. Additionally, data attributes simplify the visual highlighting in our tracking bookmarklet.
  elem.dataset.t = name;
  if (data) {
    validateContextData(data);
    contextData.set(elem, data);
  }
  else if (contextData.has(elem)) {
    contextData.delete(elem); // handle update case
  }
}
/**
 * Removes track context from given elem.
 */
export function removeTrackContext(elem) {
  delete elem.dataset.t;
  contextData.delete(elem);
}
/**
 * Returns true iff given elem has track context.
 */
export function hasTrackContext(elem) {
  let _a;
  // @ts-ignore
  return !!((_a = elem === null || elem === void 0 ? void 0 : elem.dataset) === null || _a === void 0 ? void 0 : _a.t); // name is mandatory, so we use it as context marker
}
export const findTrackContextElem = function (elem) { return elem.closest('[data-t]'); };
/**
 * Returns array of all track contexts in path of given elem (see getContextElemPath).
 */
export const getTrackContextPath = function (elem) {
  return getContextElemPath(elem).map((parentElem) => getTrackContext(parentElem));
};
/**
 * Returns array of all track context elements in path of given elem, sorted by outermost parent first.
 * Popover / portal content will have its trigger/portal-source element added as parent context.
 */
export function getContextElemPath(elem) {
  let _a;
  const path = [];
  while (elem) {
    if (hasTrackContext(elem)) {
      path.unshift(elem);
    }
    // add portal source context to elems that are rendered through a vue portal
    const portalSource = (0, getPortalSource)(elem, (vm) => !('track-pass-by' in vm.$attrs));
    if (portalSource) {
      // continue with context of portal source; use parentElement as portalSource.$el is html comment
      path.unshift.apply(path, getContextElemPath(portalSource.$el.parentElement));
      break;
    }
    // add popover trigger context to elems that are rendered inside legacy popover;
    // TODO: remove when twig is gone
    if (elem.classList.contains('.js-layer')) {
      // continue with context of legacy popover trigger
      const popoverTrigger = (_a = (0, jquery_1.default)(elem).data('layer')) === null || _a === void 0 ? void 0 : _a.$trigger[0];
      path.unshift.apply(path, getContextElemPath(popoverTrigger));
      break;
    }
    elem = elem.parentElement;
  }
  return path;
}
export function validateContext(context) {
  validateContextName(context.name);
  if ('data' in context) {
    validateContextData(context.data);
  }
}
export function validateContextName(name) {
  if (!name)
    throw Error('Missing track context name');
  // avoid validation overhead in production
  if (!isProduction && !(0, isCamelCase)(name)) {
    throw Error('Invalid track context name: '.concat(name, ', please use camel case'));
  }
}
export function validateContextData(data) {
  if (data === null)
    return;
  if (typeof data !== 'object')
    throw Error('Track data must be object, but got: '.concat(data));
  // avoid validation overhead in production
  if (!isProduction) {
    const keys = Object.keys(data);
    if (!keys.length)
      throw Error('Empty track data, please pass null instead');
    const invalidKeys = keys.filter((key) => !key || !(0, isCamelCase)(key));
    if (invalidKeys.length)
      throw Error('Invalid track data keys: '.concat(invalidKeys.join(','), ', please use camel case'));
    const invalidValues = Object.values(data).filter((value) => typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'boolean' && typeof value !== 'undefined');
    if (invalidValues.length)
      throw Error('Invalid track data values: '.concat(invalidValues.join(','), ', please use type string or number'));
  }
}
// we don't want to en/decode JSON in data attributes (performance)
var contextData = new WeakMap();
