<template>
  <div data-component-group="lang" data-component="input">
    <base-dropdown
      :items="languages"
      :active-items="activeLanguages"
      :title="languageTitle"
      item-key="code"
      item-value="name"
      secondary-color="default"
      :on-change-item="onChangeLanguage"
    />

    <v-textarea
      v-if="!isEditor"
      :value="value"
      label="Enter translation..."
      :error-messages="errorMessages"
      @input="onChangeValue"
      @blur="onBlur"
    />
    <base-enhanced-editor
      v-else
      :value="value"
      :config="editorConfig"
      :disabled="isDisabled"
      :error-messages="errorMessages"
      @input="onChangeValue"
      @blur="onBlur"
    />
  </div>
</template>

<script>
  import { isObj, isStr } from '../../utils';
  import defaultEditorConfig from '../../config/defaultEditorConfig';

  export default {
    props: {
      translations: {
        type: Array,
        required: true,
      },
      activeTranslations: {
        type: Array,
        required: true,
      },
      isEditor: {
        type: Boolean,
        default: false,
      },
      errorMessages: {
        type: Array,
        default() {
          return [];
        },
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      onBlur: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        languages: [],
        selectedLanguage: {},
        editorConfig: {
          ...defaultEditorConfig,
          placeholder: 'Type comment here...',
        },
        hasLanguages: false,
        isSynced: false,
      };
    },
    computed: {
      allLanguages() {
        return this.$store.getters['config/languages'];
      },
      activeLanguages() {
        const activeTranslationsLanguages = this.activeTranslations.map(
          ({ language }) => language,
        );
        return this.languages.filter(
          ({ code }) => activeTranslationsLanguages.indexOf(code) > -1,
        );
      },
      languageTitle() {
        const { selectedLanguage } = this;
        return isObj(selectedLanguage) && isStr(selectedLanguage.name)
          ? selectedLanguage.name
          : 'Language';
      },
      value: {
        get() {
          const language = this.languages.find((language) => language.isSelected);
          return isObj(language) ? language.value : '';
        },
        set(nextValue) {
          this.languages.map((language) => ({
            ...language,
            value: language.isSelected ? nextValue : language.value || '',
          }));
        },
      },
    },
    watch: {
      translations(nextTranslations) {
        this.languages = this.syncValues(this.languages, nextTranslations);
      },
      allLanguages: {
        handler(nextVal) {
          if (this.languages.length !== nextVal.length) {
            const nextLanguages = this.sortLanguages(nextVal);
            this.languages = this.syncValues(nextLanguages, this.translations);
            const [firstLanguage] = nextLanguages;
            this.selectedLanguage = firstLanguage;
            const languages = this.formatLanguages(this.languages);

            this.$emit('ready', languages);
          }
        },
        immediate: true,
      },
    },
    methods: {
      syncValues(languages, translations) {
        const hasSelectedLanguage = isStr(this.selectedLanguage.code);

        return languages.map((language, index) => {
          const translation = translations.find(
            (translation) => translation.code === language.code,
          );

          return {
            ...language,
            isSelected: hasSelectedLanguage ? language.isSelected : index === 0,
            value: isObj(translation) ? translation.value : '',
          };
        });
      },
      onChangeLanguage(selectedLanguage) {
        this.selectedLanguage = selectedLanguage;
        this.languages = this.languages.map((language) => ({
          ...language,
          isSelected: language.code === selectedLanguage.code,
        }));
      },
      onChangeValue(nextValue) {
        this.languages = this.languages.map((language) => ({
          ...language,
          value: language.isSelected ? nextValue : language.value,
        }));

        const languages = this.formatLanguages(this.languages);
        this.$emit('input', languages);
      },
      formatLanguages(languages) {
        return languages.map(({ code, name, value }) => ({ code, name, value }));
      },
      sortLanguages(languages) {
        return languages.sort((a, b) => {
          if (a.code === 'en') {
            return -1;
          }

          if (b.code === 'en') {
            return 1;
          }

          return a.code > b.code;
        });
      },
    },
  };
</script>

<style lang="scss">
@import '../../assets/styles/theme';

[data-component-group='lang'][data-component='input'] {
  [data-component='dropdown'] {
    text-align: right;
    margin: 0 0 10px 0;

    .trigger.toggle-menu {
      margin-right: 0;
      box-shadow: none;
    }
  }

  textarea {
    height: 200px;
    max-height: 320px;
    border-radius: $border-radius;
  }
}
</style>
