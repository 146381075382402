<template>
  <div data-component="table">
    <div class="component-header">
      <div class="table-title">
        <slot name="title" />
      </div>

      <div class="component-actions">
        <slot name="actions" />
      </div>
    </div>

    <v-card elevation="0">
      <v-data-table
        class="elevation-0 table"
        :headers="selectedColumns"
        :items="items"
        :header-props="headerProps"
        :server-items-length="hasDefaultFooter ? -1 : pagination.totalItems"
        :hide-default-footer="!items.length || !hasDefaultFooter || hasNoFooter"
        :sort-by="hasDefaultFooter ? '' : pagination.sortBy"
        :sort-desc="hasDefaultFooter ? false : pagination.descending"
        :loading="isLoading"
        :must-sort="true"
        loader-height="2"
        :loading-text="loadingLabel"
        @update:options="onUpdateOptions"
      >
        <template
          v-for="h in customHeaders"
          #[`header.${h.value}`]="{ header }"
        >
          {{ header.text }}
          <TooltipsManager v-if="h.tooltip" :key="h.value" :item="h.tooltip" />
        </template>

        <template slot="item" slot-scope="props">
          <tr>
            <td
              v-for="column in selectedColumns"
              :key="getColumnValue(column.value)"
              @click="onSelectItem(props.item)"
            >
              <slot
                v-if="hasCustomItemsTemplate"
                :cell="{
                  item: props.item,
                  column,
                }"
                name="item-cell"
              />

              <template v-else>
                <div v-if="column.value !== 'actions'">
                  {{ displayColumnValue(props.item, column.value) }}
                </div>

                <div
                  v-else
                  @click="
                    (event) => {
                      event.stopPropagation();
                      onAction(props.item, column.action);
                    }"
                >
                  <v-icon>
                    {{ column.icon }}
                  </v-icon>
                </div>
              </template>
            </td>
          </tr>

          <slot name="after-item" :row="props"></slot>
        </template>

        <template slot="no-results">
          <!-- TODO no-results slot is a fallback for search prop in v-data-table, we dont use it so we might dont need this -->
          <NoData
            :title="noResultsLabel"
          />
        </template>

        <template slot="no-data">
          <slot name="no-data">
            <NoData
              :title="noDataTitle"
              :subtitle="noDataSubtitle"
            >
              <slot name="no-data-action"></slot>
            </NoData>
          </slot>
        </template>
      </v-data-table>
    </v-card>

    <div v-if="!hasNoFooter && !hasDefaultFooter && items.length" class="component-footer">
      <div class="pager">
        {{
          $t('table_pager', {
            firstIndex: firstItemIndex,
            lastIndex: lastItemIndex,
            total: pagination.totalItems,
          })
        }}
      </div>

      <v-pagination
        v-if="pagination.totalItems > pagination.rowsPerPage"
        v-model="pagination.page"
        :length="pages"
        circle
        :total-visible="totalVisible"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
  import { extractNestedProp } from '@/utils';
  import TooltipsManager from '../tooltips/TooltipsManager';
  import NoData from './NoData';

  export default {
    components: {
      TooltipsManager,
      NoData
    },
    props: {
      columns: {
        type: Array,
        required: true,
      },
      items: {
        type: Array,
        required: true,
      },
      pages: {
        type: Number,
        default: 0,
      },
      pagination: {
        type: Object,
        required: true,
      },
      hasDefaultFooter: {
        type: Boolean,
        default: false,
      },
      hasNoFooter: {
        type: Boolean,
        default: false,
      },
      noResultsLabel: {
        type: String,
        default() {
          return this.$t('no_data_found');
        },
      },
      noDataTitle: {
        type: String,
        default() {
          return this.$t('no_data_available');
        },
      },
      noDataSubtitle: {
        type: String,
        default: '',
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      loadingLabel: {
        type: String,
        default() {
          return this.$t('loading_items');
        },
      },
      hasCustomItemsTemplate: {
        type: Boolean,
        default: false,
      },
      customHeaders: {
        type: Array,
        default() {
          return [];
        },
      },
      onSelectItem: {
        type: Function,
        default: () => {},
      },
      onSort: {
        type: Function,
        default: null,
      },
      // TODO: Trigger this callback only when changing sorting!!!
      onUpdatePagination: {
        type: Function,
        default: () => {},
      },
      onChangePage: {
        type: Function,
        default: () => {},
      },
      onAction: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        allRowsChecked: false,
        totalVisible: 10,
        rowsPerPage: [15, 30, 50, 100],
        headerProps: {
          'sort-icon': 'arrow_drop_down',
        },
      };
    },
    computed: {
      selectedColumns() {
        return this.columns.filter((column) => column.isSelected);
      },
      firstItemIndex() {
        const { page, rowsPerPage } = this.pagination;
        return page * rowsPerPage - (rowsPerPage - 1);
      },
      lastItemIndex() {
        const { page, rowsPerPage, totalItems } = this.pagination;
        const index = page * rowsPerPage;
        return index < totalItems ? index : totalItems;
      },
    },
    methods: {
      onUpdateOptions(options) {
        const obj = this.columns.find(item => item.value === options.sortBy[0]);
        let sort = '';
        let desc = options.sortDesc[0] === undefined ? false : options.sortDesc[0];

        if (obj?.apiSort) {
          sort = obj.apiSort;
        } else {
          sort = options.sortBy[0];
        }

        const nextOptions = {
          page: options.page,
          sortBy: sort,
          descending: desc,
        };

        this.onUpdatePagination(nextOptions);
      },
      checkAllRows() {
        const { allRowsChecked, onCheckAllRows } = this;

        this.allRowsChecked = !allRowsChecked;
        onCheckAllRows(this.allRowsChecked);
      },
      getColumnValue(value) {
        return value.indexOf('.') === -1 ? value : value.split('.').join('-');
      },
      displayColumnValue(item, columnValue) {
        return extractNestedProp(item, columnValue);
      },
    },
  };
</script>

<style lang="scss">
@import '../../assets/styles/theme';

[data-component='table'] {
  border-radius: $border-radius;
  padding: 0 0 48px 0;

  .v-card {
    overflow: hidden;
    box-shadow: none;
    position: static;
  }

  .table,
  .v-table.v-datatable {
    border-radius: $border-radius !important;
  }

  table thead tr {
    height: 46px;
  }

  table tbody tr {
    vertical-align: top;
    background-color: map-deep-get($colors, shades, 'white');
  }

  .v-data-table > .v-data-table__wrapper table thead tr th {
    padding: 0 8px;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr {
    > td {
      padding: 16px 8px;
    }

    &:first-child:hover td:first-child {
      border-top-left-radius: 0;
    }

    &:first-child:hover td:last-child {
      border-top-right-radius: 0;
    }

    &:last-child:hover td:first-child {
      border-bottom-left-radius: 0;
    }

    &:last-child:hover td:last-child {
      border-bottom-right-radius: 0;
    }
  }

  table tbody tr {
    cursor: pointer;

    &:hover {
      background-color: map-deep-get($colors, grey, lighten-3);
    }
  }

  > .component-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 16px 0;

    @media (max-width: map-get($breakpoints, md)) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .table-title {
      h1 {
        display: inline-block;

        @media (max-width: map-get($breakpoints, md)) {
          padding: 0 0 10px 0;
        }
      }

      .heading-separator {
        font-size: $text-size-xl + 0.25rem;
        margin: 0 4px;
        color: map-deep-get($colors, grey, lighten-1);
      }

      h2 {
        display: inline-block;
        font-size: $text-size-xl + 0.375rem;
        font-weight: 400;
      }
    }

    .component-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.reversed {
        flex-direction: row-reverse;
      }

      [data-component='dropdown'] {
        .trigger.toggle-menu {
          box-shadow: none;

          @media (max-width: map-get($breakpoints, md)) {
            margin-left: 0;
          }

          .v-icon {
            &.icon-edit {
              margin: 0 8px 0 0;
            }
          }
        }
      }

      .trigger {
        border-radius: $border-radius;
        margin-top: 0;
        margin-left: 8px;
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .v-data-table__empty-wrapper {
    vertical-align: middle;

    &:hover {
      background-color: #fff;
    }

    > td {
      height: auto !important;
    }
  }

  .v-data-table__wrapper {
    overflow: auto;
  }

  .v-data-table__progress {
    .v-progress-linear {
      position: relative;
      left: -16px;
      width: calc(100% + 24px);
    }
  }

  .v-datatable thead th.column.sortable {
    padding-bottom: 7px;

    .v-icon {
      position: relative;
      top: 5px;
      float: none;
      display: inline-block;
      font-size: $text-size-xl !important;
    }
  }

  > .component-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: map-deep-get($colors, shades, 'white');
    border-top: 1px solid map-deep-get($colors, grey, lighten-2);
    overflow-x: auto;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    @media (max-width: map-get($breakpoints, sm)) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .pager {
      width: 200px;
      padding: 10px $spacing-sm;
      font-size: $text-size-sm;
      color: map-deep-get($colors, grey, darken-2);

      .counter {
        color: map-deep-get($colors, shades, 'black');
      }
    }

    .v-pagination {
      //width: calc(100% - 200px);
      justify-content: flex-end;

      button {
        box-shadow: none;
      }

      @media (max-width: map-get($breakpoints, sm)) {
        width: 100%;
        justify-content: flex-start;
      }
    }
  }

  [data-component='status'] {
    .trigger.view-url {
      margin-left: -8px !important;
    }
  }
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom-width: 2px;
  color: map-get($colors, text-muted) !important;

  i {
    color: map-get($colors, text-muted) !important;
  }
}
</style>
