<template>
  <v-alert
    :value="true"
    :type="type"
    rounded
    transition="scale-transition"
    :dismissible="isDismissible"
    outlined
    @input="onDismiss"
    class="alert-base"
  >
    <template slot="prepend">
      <v-icon class="material-icons-outlined alert-base__icon">
        {{ icon }}
      </v-icon>
    </template>

    <div class="alert-base__content">
      <div class="alert-base__title">
        <slot name="title"/>
      </div>

      <div class="alert-base__text">
        <slot name="text"/>
      </div>

      <div class="alert-base__actions">
        <slot name="action"/>
      </div>
    </div>

    <template v-if="isDismissible" v-slot:close="{ toggle }">
      <v-icon @click="toggle()">close</v-icon>
    </template>
  </v-alert>
</template>

<script>
// TODO we have two similar components: AlertBase and Banner

export default {
  props: {
    type: {
      type: String,
      default: 'info',
    },
    isDismissible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      switch (this.type) {
        case 'success':
          return 'check_circle'
        case 'warning':
          return 'warning'
        case 'error':
          return 'error'
        case 'info':
          return 'info'
        default:
          return 'info'
      }
    }
  },
  methods: {
    onDismiss() {
      this.$emit('dismiss');
    },
  },
};
</script>

<style lang="scss">
  @import '../../assets/styles/theme';

  .alert-base {
    &__icon {
      position: relative;
      color: inherit !important;
      margin-right: 16px;

      &.v-icon:after {
        opacity: 0.2;
      }
    }
  }

  .v-application .alert-base.rounded {
    border-radius: $border-radius !important;
  }
</style>
