// eslint-disable-next-line import/no-cycle
import store from '../../store';

export default [
  {
    path: '/password/reset',
    name: 'forgot-pass',
    meta: {
      loadState: true,
      auth: false,
      isAuthPage: true,
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/auth/ForgotPass'
      ),
  },
  {
    path: '/password/reset/:token/:email',
    name: 'reset-pass',
    meta: {
      loadState: true,
      auth: false,
      isAuthPage: true,
      isTokenProtected: true,
    },
    // route level code-splitting
    component: () =>
      import( '../../views/auth/ResetPass'),
    beforeEnter: (to, from, next) => {
      const { token, email } = to.params;

      return store
        .dispatch('auth/isValidPassResetToken', { token, email })
        .then((res) => {
          if (!res) {
            return next('/login');
          }

          return next();
        });
    },
  },
  {
    path: '/password/request/:token',
    name: 'set-pass',
    meta: {
      loadState: true,
      auth: false,
      isAuthPage: true,
      isTokenProtected: true,
    },
    // route level code-splitting
    component: () =>
      import( '../../views/auth/SetPass'),
    beforeEnter: (to, from, next) => {
      const { token } = to.params;

      return store
        .dispatch('auth/isValidPassSetToken', { token })
        .then((res) => {
          if (!res) {
            return next('/login');
          }

          return next();
        });
    },
  },
];
