import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      sort = '',
      order = 'asc',
      search = '',
      business = -1,
      managed_by = -1,
      conflicts = -1,
      reviewItems = [],
      countries = [],
      linkedCountries = [],
    } = options;

    const params = {
      page,
      sort,
      order,
      search,
      countries,
      linked_countries: linkedCountries,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (business > -1) {
      params.business = business;
    }

    if (managed_by > -1) {
      params.managed_by = managed_by;
    }

    // TODO for refactor: double check if conflicts is used and if not remove it
    if (conflicts > -1) {
      params.conflicts = conflicts;
    }

    if (reviewItems.length > 0) {
      reviewItems.forEach((key) => {
        params[key] = 1;
      });
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/billing`,
      params,
    });
  },
  createItem(comment) {
    return axios({
      method: 'post',
      url: `${apiUrl}/billing`,
      data: {
        ...comment,
      },
    });
  },
  updateItem(item) {
    return axios({
      method: 'put',
      url: `${apiUrl}/billing/${item.id}`,
      data: {
        ...item,
      },
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/billing/${id}`,
    });
  },
  removeItem({ id }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/billing/${id}`,
    });
  },
  getActivityLogs({ page = 1, perPage = maxItemsPerPage, id }) {
    const params = {
      page,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/billing/${id}/activities`,
      params,
    });
  },
  getInvoiceEmails({ page = 1, perPage = -1, id }) {
    const params = {
      page,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/billing/${id}/emails`,
      params,
    });
  },
  updateInvoiceEmails({ id, invoice_emails }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/billing/${id}/emails`,
      data: {
        invoice_emails,
      }
    });
  },
  updateOwners({ id, user }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/billing/${id}/owners`,
      data: {
        user,
      },
    });
  },
  uploadCSV({ csv }) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const fd = new FormData();

    fd.append('csv', csv.file);
    return axios.post(`${apiUrl}/billing/upload`, fd, config);
  },
  vatCheck({ country, id }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/vat/${country}/${id}`,
    });
  },
};
