<template>
  <div v-show="contextElem" id="trackLayer" :style="layerStyle" :class="{ alignLeft }">
    <template v-if="info">
      <section v-for="event of info.events" :key="`${event.name}-${event.service}`" class="space--b-2">
        <h4>
          <span class="text--b">{{ event.service }} event</span>
          <template v-if="event.schema !== null"> ({{ event.schema ? 'has schema' : 'no schema' }})</template>
        </h4>
        <ul class="space--l-2">
          <li v-for="(value, key) of event.query" :key="key">
            <span class="text--b">{{ key }}</span>: {{ value }}
          </li>
        </ul>
      </section>
      <section v-if="!info.events.length">
        <h1 class="text--b">context: {{ info.context.path }}</h1>
        <ul class="space--l-2">
          <li v-for="(value, key) of info.context.data" :key="key">
            <span class="text--b">{{ key }}</span>: {{ value }}
          </li>
        </ul>
      </section>
    </template>
    <template v-else>waiting for event…</template>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { findTrackContextElem, getTrackContextPath } from './context';
  import { getTrackAction, getTrackTargetAttributeName, TRACK_EVENTS } from './target';
  import { track } from './broker';
  import { assignContextData, createActionName, stringifyContextPath } from './payload';
  import { createOcularQuery } from './ocular';
  import whitelistedOcularActionNames from '../utils/events.json';

  const TrackInfoLayer = {
    components: {},

    data() {
      return { contextElem: null, info: null, left: 0, top: 0 };
    },
    computed: {
      layerStyle() {
        return {
          top: `${this.top + document.documentElement.scrollTop}px`,
          left: `${this.left}px`,
        };
      },
      alignLeft() {
        return this.left > document.documentElement.offsetWidth / 2;
      },
    },
    methods: {
      hide() {
        if (this.contextElem) {
          this.contextElem.classList.remove('active');
          this.contextElem = null;
        }
        this.info = null;
      },
      async show(contextElem) {
        this.hide();

        contextElem.classList.add('active');

        // native title tooltips can be in way of layer
        contextElem.removeAttribute('title');

        this.contextElem = contextElem;

        const info = await this.getInfo(contextElem);

        // discard when show has been called with different context element while waiting for info
        if (contextElem !== this.contextElem) return;

        this.info = info;
      },
      mouseover(event) {
        // disable when not hovering valid dom element
        if (!(event.target instanceof Element)) return this.hide();

        const contextElem = findTrackContextElem(event.target);

        // disable when not hovering track target
        if (!contextElem) return this.hide();

        // do nothing when still hovering same track target
        if (contextElem === this.contextElem) return;

        this.show(contextElem);
      },
      updatePos({ clientX: left, clientY: top }) {
        this.left = left;
        this.top = top;
      },
      async createEventInfo(eventType, target) {
        const action = await track({ ...getTrackAction(eventType, target), simulate: true });

        return action
          ? action.services.map(service => {
            const name = createActionName(action);
            // const isOcular = service === TrackService.Ocular;
            return {
              service,
              name,
              query: createOcularQuery(action),
              schema: whitelistedOcularActionNames.includes(name),
            };
          })
          : [];
      },
      async getInfo(target) {
        const events = TRACK_EVENTS.filter(
          eventType => target.hasAttribute(getTrackTargetAttributeName(eventType)),
        ).map(eventType => this.createEventInfo(eventType, target));

        const contextPath = getTrackContextPath(target);
        const data = {};
        contextPath.forEach(context => assignContextData(data, context));

        return {
          events: (await Promise.all(events)).flat(),
          context: {
            path: stringifyContextPath(contextPath),
            data,
          },
        };
      },
    },
    mounted() {
      document.addEventListener('mouseover', this.mouseover, true);
      document.addEventListener('mousemove', this.updatePos);
    },
  };

  export const init = () => {
    // little obstacle for curious non-staff people trying to call __pepperTrack()
    // if (!document.documentElement.classList.contains('trackBookmarklet')) return;

    // directly mount to body
    const infoLayer = new (Vue.extend(TrackInfoLayer))();
    document.body.appendChild(infoLayer.$mount().$el);
  };

  export default TrackInfoLayer;
</script>

<style lang="scss">
.trackBookmarklet {
  [data-t]:not(:disabled) {
    --border-color: 75, 0, 255;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: inset 0 0 0 1px #{'rgb(var(--border-color))'}, 0 0 3px 0 #{'rgb(var(--border-color))'};

    &.active {
      box-shadow: inset 0 0 0 10000px #{'rgba(var(--border-color), 0.37)'}, 0 0 5px -1px #{'rgb(var(--border-color))'};
    }
  }

  [data-t-click],
  [data-t-change],
  [data-t-focus],
  [data-t-blur],
  [data-t-submit],
  [data-t-view] {
    &:not(:disabled) {
      --border-color: 255, 0, 0;
    }
  }

  #trackLayer {
    position: absolute;
    z-index: 99999;
    width: fit-content;
    max-width: 46%;
    margin-left: 20px;
    padding: 5px;
    white-space: pre-wrap;
    background: white;
    box-shadow: 0 0 4px 0 #000;
    pointer-events: none;
    font-size: 10px;
    font-family: monospace;

    &.alignLeft {
      margin-left: -20px;
      transform: translateX(-100%);
    }
  }
}
</style>
