<template>
  <div data-component="add-dialog">
    <base-dialog
      :content-class="dialogClass"
      :max-width="width"
      :is-fullscreen="isFullscreen"
      :is-on="isOn"
      :on-close="onClose"
      :is-close-on-outside-click="isCloseOnOutsideClick"
    >
      <template slot="title">
        <div class="item-title">
          <v-icon v-if="hasIcon">
            {{ icon }}
          </v-icon>

          <h1>{{ title }}</h1>
        </div>

        <v-btn
          v-if="closeIcon"
          class="elevation-0 trigger close-dialog"
          @click="onClose"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>

      <div slot="content" class="slot-wrap container content grid-list-lg">
        <slot name="content" />
      </div>
    </base-dialog>
  </div>
</template>

<script>
  import { isStr } from '../../utils';

  export default {
    props: {
      icon: {
        type: String,
        default: 'add',
      },
      contentClass: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default() {
          return this.$t('edit_item');
        },
      },
      width: {
        type: Number,
        default: 640,
      },
      isOn: {
        type: Boolean,
        default: false,
      },
      isFullscreen: {
        type: Boolean,
        default: false,
      },
      isCloseOnOutsideClick: {
        type: Boolean,
        default: false
      },
      closeIcon: {
        type: Boolean,
        default: true,
      },
      onClose: {
        type: Function,
        default: () => {},
      },
    },
    computed: {
      hasIcon() {
        const { icon } = this;
        return isStr(icon) && icon !== '';
      },
      dialogClass() {
        return `dialog add-item-dialog ${this.contentClass}`;
      },
    },
  };
</script>

<style lang="scss">
@import '../../assets/styles/theme';

.dialog.add-item-dialog > .modal__backdrop > .modal__content {
  width: 640px;
  max-width: 100%;
  margin: 0 auto;
  background: map-deep-get($colors, grey, lighten-5);

  > .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: 0 auto;
    color: map-deep-get($colors, shades, 'white');

    .item-title {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .v-icon {
        font-size: $text-size-xl + 0.5rem;
        color: map-deep-get($colors, shades, 'white');
      }

      h1 {
        font-weight: 500;
        margin-left: $spacing-md;
      }
    }

    .trigger.close-dialog {
      width: 60px;
      min-width: auto;
      height: 60px;
      border-radius: 50%;
      padding: 0;
      margin: 0 $spacing-xs 0 0;
      background-color: transparent;

      .v-icon {
        margin: 0;
        font-size: $text-size-xl + 0.5rem;
        color: map-deep-get($colors, shades, 'white');
      }

      &:before {
        background-color: transparent;
      }
    }
  }

  > .modal__body {
    .slot-wrap {
      padding: initial;
    }
  }
}
</style>
