<template>
  <v-dialog
    v-model="isOpen"
    :persistent="isPersistent"
    scrollable
    max-width="540px"
    @click:outside="onCancel"
  >
    <v-card>
      <TermsTemplate />
      <v-divider />
      <v-card-actions>
        <v-btn class="terms-button" color="white" text @click="onConfirm">
          {{ buttonLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import TermsTemplate from '../../../views/terms/Index';

  export default {
    components: {
      TermsTemplate,
    },
    props: {
      isOn: {
        type: Boolean,
        required: true,
      },
      isPersistent: {
        type: Boolean,
        default: false,
      },
      onConfirm: {
        type: Function,
        default: () => {},
      },
      onCancel: {
        type: Function,
        default: () => {},
      },
      buttonLabel: {
        type: String,
        default() {
          return `${this.$t('accept')} ${this.$t('terms_and_conditions')}`;
        },
      },
    },
    computed: {
      isOpen: {
        get() {
          return this.isOn;
        },
        set() {
          return this.onCancel();
        },
      },
    },
  };
</script>

<style lang="scss">
.terms-button {
  margin: 15px auto;
  width: 370px;
  color: white;
  background-color: #fb8c00;
}
</style>
