import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiUrl } from './index';

export default {
  // Assets START
  getAsset(id) {
    return axios({
      method: 'get',
      url: `${apiUrl}/events/assets/${id}`,
    });
  },
  getAssets(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/events/assets`,
      params,
    });
  },
  createAsset(data) {
    return axios({
      method: 'post',
      url: `${apiUrl}/events/assets`,
      data: data.formData,
    });
  },
  updateAsset(data) {
    return axios({
      method: 'post',
      url: `${apiUrl}/events/assets/${data.id}`,
      data: data.formData,
    });
  },

  getAssetsColors(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/events/assets/colors`,
      params,
    });
  },
  getAssetsImageTypes(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/events/assets/image_types`,
      params,
    });
  },
  // Assets END

  // Promotions START
  getPromotion(id) {
    return axios({
      method: 'get',
      url: `${apiUrl}/events/promotions/${id}`,
    });
  },
  getPromotions(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/events/promotions`,
      params,
    });
  },
  createPromotion(data) {
    return axios({
      method: 'post',
      url: `${apiUrl}/events/promotions`,
      data,
    });
  },
  updatePromotion(data) {
    return axios({
      method: 'put',
      url: `${apiUrl}/events/promotions/${data.id}`,
      data,
    });
  },

  getPromotionsPlacements(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/events/promotions/placements`,
      params,
    });
  },
  getPromotionsTypes(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/events/promotions/types`,
      params,
    });
  },
  getPromotionsStatuses(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/events/promotions/statuses`,
      params,
    });
  },
  // Promotions END
};
