// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';

const validationApi = apiClient.merchy.validation;

const actions = {
  validate: (context, query) =>
    validationApi.validate(query).then((res) => res.data.data.valid),
};

export default () => ({
  namespaced: true,
  actions,
});
