// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '@/utils';
import initialState from '../initialState';
// eslint-disable-next-line import/no-cycle
import store from '..';

const sponsorshipsApi = apiClient.merchy.sponsorships;

/**
 * @description Is valid
 * @param sponsorships
 * @returns {boolean}
 */
const isValid = (sponsorships) => {
  const { items, itemsMeta, statuses } = sponsorships;

  return (
    isArr(items) &&
    isArr(statuses) &&
    isObj(itemsMeta)
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial sponsorships state');
  }

  const { items, itemsMeta, statuses } = initialState;

  return {
    items,
    itemsMeta,
    statuses
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  statuses: ({ statuses }) => statuses,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
};

/**
 * @description Handle alerts
 * @param message
 * @param alertType
 */
const handleAlerts = (message, alertType = 'success') => {
  const alert = {
    data: {
      message,
    },
  };

  store.dispatch('alerts/set', {
    data: alert,
    alertType,
  });
};

const actions = {
  getStatuses({ commit, state }) {
    sponsorshipsApi.getStatuses().then((res) => {
      const { data } = res.data;
      const nextItems = {
        statuses: data,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);

      return { data };
    }).catch(e => Promise.reject(e))
  },
  getUsedCategories(_, query) {
    return sponsorshipsApi.getUsedCategories(query).then((res) => {
      return res.data.data;
    }).catch(e => Promise.reject(e))
  },
  getLogoSponsorships: ({ commit, state }, query) =>
    sponsorshipsApi.getLogoSponsorships(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = {
        items: data,
        itemsMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);

      return { data, meta };
    }).catch(e => Promise.reject(e)),
  getLogoSponsorship: (context, query) =>
    sponsorshipsApi.getLogoSponsorship(query).then((res) => res.data.data).catch(e => Promise.reject(e)),
  createSponsorship: (context, query) =>
    sponsorshipsApi.createSponsorship(query).then((res) => {
      handleAlerts('Logo Sponsorship created successfully.');
      return res.data.data;
    }).catch(e => Promise.reject(e)),
  editSponsorship: (context, query) =>
    sponsorshipsApi.editSponsorship(query).then((res) => {
      handleAlerts('Logo Sponsorship updated successfully.');
      return res.data.data;
    }).catch(e => Promise.reject(e)),
  reset: ({ commit }) => commit('SET', initialState.sponsorships),
};

const mutations = {
  SET(state, data) {
    /* eslint-disable no-param-reassign */
    state.statuses = data.statuses;
    state.items = data.items;
    state.itemsMeta = data.itemsMeta;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
