import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      sort = '',
      order = 'asc',
      search = '',
      dates = '',
      countries = [],
      status = -1,
      labels = [],
    } = options;

    const params = {
      countries,
      page,
      sort,
      order,
      search,
      dates,
      labels
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (status > -1) {
      params.status = status;
    }

    if (dates !== '') {
      params.dates = dates;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/businesses`,
      params,
    });
  },
  createItem(comment) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses`,
      data: {
        ...comment,
      },
    });
  },
  updateItem(item) {
    return axios({
      method: 'put',
      url: `${apiUrl}/businesses/${item.id}`,
      data: {
        ...item,
      },
    });
  },
  removeItem({ id }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}`,
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}`,
    });
  },
  getActivityLogs({ page = 1, perPage = maxItemsPerPage, id }) {
    const params = {
      page,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/activities`,
      params,
    });
  },
  getStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/statuses`,
    });
  },
  getSettings({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/settings`,
    });
  },
  getBillingSettings() {
    return axios({
      method: 'get',
      url: `${apiUrl}/business_users/billing_settings`,
    });
  },
  getBillingCountriesByStatus() {
    return axios({
      method: 'get',
      url: `${apiUrl}/business_users/billing_countries_by_status`
    });
  },
  updateSettings({ id, settings }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/settings`,
      data: {
        ...settings,
      },
    });
  },
  getBillingAccounts({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/account`,
    });
  },
  addBillingAccount({ id, billing_account }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/account`,
      data: {
        billing_account,
      },
    });
  },
  removeBillingAccount({ id, billing_account }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/account/${billing_account}`,
    });
  },
  getLabels({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/labels`,
    });
  },
  addLabels({ id, country_labels, labels }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/labels`,
      data: {
        country_labels,
        labels,
      },
    });
  },
  removeLabels({ id, country }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/labels/${country}`,
    });
  },
  getCommentCountries({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/comments`,
    });
  },
  addCommentCountry({ id, country_enable_comments }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/comments`,
      data: {
        country_enable_comments,
      },
    });
  },
  removeCommentCountry({ id, country }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/comments/${country}`,
    });
  },
  getCampaigns({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/campaigns`,
    });
  },
  addCampaign({ id, country_enable_campaigns }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/campaigns`,
      data: {
        country_enable_campaigns,
      },
    });
  },
  removeCampaign({ id, country }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/campaigns/${country}`,
    });
  },
  getThreadSubmissions({ id, thread_type, only_allowed_countries }) {
    const params = {
      thread_type,
      only_allowed_countries
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/thread/submission`,
      params
    });
  },
  addThreadSubmission({ id, country }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/thread/submission`,
      data: {
        country,
      },
    });
  },
  removeThreadSubmission({ id, country }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/thread/submission/${country}`,
    });
  },
  getEnabledPromotions({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/promotion/submission`,
    });
  },
  addEnabledPromotion({ id, country }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/promotion/submission`,
      data: {
        country,
      },
    });
  },
  removeEnabledPromotion({ id, country }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/promotion/submission/${country}`,
    });
  },
  getThreadSubmissionsLimits({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/limits`,
    });
  },
  addThreadSubmissionLimit({
    id,
    country_limits,
    max_deals_per_week,
    max_deals_per_day,
  }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/limits`,
      data: {
        country_limits,
        max_deals_per_week,
        max_deals_per_day,
      },
    });
  },
  removeThreadSubmissionLimit({ id, country }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/limits/${country}`,
    });
  },
  getNotifications({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/notifications`,
    });
  },
  addNotification({ id, country_notify, notify_when }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/notifications`,
      data: {
        country_notify,
        notify_when,
      },
    });
  },
  removeNotification({ id, notification_id }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/notifications/${notification_id}`,
    });
  },
  getKeyAccounts({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/key/account`,
    });
  },
  addKeyAccount({ id, key_country }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/key/account`,
      data: {
        key_country,
      },
    });
  },
  removeKeyAccount({ id, country }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/key/account/${country}`,
    });
  },
  getAccountManagers({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/managers`,
    });
  },
  addAccountManager({ id, manager, manager_country, assign }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/managers`,
      data: {
        manager,
        manager_country,
        assign,
      },
    });
  },
  removeAccountManager({ id, manager, country }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/managers/${manager}/${country}`,
    });
  },
  getBusinessAdvertisers({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/company`,
    });
  },
  addBusinessAdvertiser({ id, advertiser }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/company`,
      data: {
        advertiser_id: advertiser.id,
        name: advertiser.name,
      },
    });
  },
  removeBusinessAdvertiser({ id, advertiser }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/company/${advertiser.id}`,
    });
  },
  getBillingAccountAdvertisers({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/company/billing`,
    });
  },
  addBillingAccountAdvertiser({ id, country, advertiser, account }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/company/billing`,
      data: {
        country,
        advertiser_id: advertiser.id,
        billing_account_id: account.id,
      },
    });
  },
  removeBillingAccountAdvertiser({ id, account }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/company/billing/${account.id}`,
    });
  },
  getBusinessMerchants({ id, country }) {
    const params = {
        country
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/merchants`,
      params
    });
  },
  addBusinessMerchant({ id, merchant, country }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/merchants`,
      data: {
        country,
        merchant_id: merchant.id,
        merchant_name: merchant.name,
      },
    });
  },
  removeBusinessMerchant({ id, merchant_id }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/merchants/${merchant_id}`,
    });
  },
  getBusinessPlacements({ business_id, advertiser_id, country, start_date, end_date}) {
    const params = {
      start_date,
      end_date
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${business_id}/get_cpm_prices/${country}/${advertiser_id}`,
      params
    });
  },
  getBusinessCpmPrices({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/cpm_prices`,
    });
  },
  getBusinessFreeImpressions({ businessId }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${businessId}/cpm_impressions`,
    });
  },
  addBusinessCpmPrice({ id, country, advertiser, price }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/cpm_prices`,
      data: {
        advertiser_id: advertiser.id,
        country,
        // placement: placement.id,
        price,
      },
    });
  },
  removeBusinessCpmPrice({ id, price }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/cpm_prices/${price.id}`,
    });
  },
  addBusinessFreeImpressions({ businessId, country, impressions, end_date }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${businessId}/cpm_impressions`,
      data: {
        country,
        impressions,
        end_date,
      },
    });
  },
  removeBusinessFreeImpressions({ businessId, record }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${businessId}/cpm_impressions/${record.id}`,
    });
  },
  getNotificationTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/notification_types`,
    });
  },
  getNotes({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/notes`,
    });
  },
  addNote({ id, country, note }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/businesses/${id}/notes`,
      data: {
        country,
        note,
      },
    });
  },
  updateNote({ id, note_id, country, note }) {
    return axios({
      method: 'put',
      url: `${apiUrl}/businesses/${id}/notes/${note_id}`,
      data: {
        country,
        note,
      },
    });
  },
  removeNote({ id, note_id }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/businesses/${id}/notes/${note_id}`,
    });
  },
  getBusinessThreads(options = {}) {
    const {
      id,
      country,
      page = 1,
      perPage = maxItemsPerPage,
      sort = '',
      order = 'asc',
      search,
    } = options;
    const params = {
      page,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (sort !== '') {
      params.sort = sort;
      params.order = order;
    }

    if (search !== '') {
      params.search = search;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/businesses/${id}/threads/${country}`,
      params,
    });
  },
};
