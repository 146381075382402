export default [
  {
    path: '/promotions/deals/:id(\\d+)',
    name: 'promotable-deal-details',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'ad-manager.order',
          actions: ['view-business-contact'],
        },
      ],
    },
    // route level code-splitting
    component: () => import('../../views/promotions/PromotionsDeals'),
  },
  {
    path: '/promotable-deals',
    name: 'promotable-deals',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'ad-manager.order',
          actions: ['view-business-contact'],
        },
      ],
    },
    // route level code-splitting
    component: () => import('../../views/promotions/PromotionsDeals'),
  },
  {
    path: '/promotions/:id(\\d+)',
    name: 'promotion-details',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'ad-manager.order',
          actions: ['view-nav-link'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import('../../views/promotions/Index.vue'),
  },
  {
    path: '/promotions-archived',
    name: 'promotions-archived',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'ad-manager.order',
          actions: ['view-nav-link'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import('../../views/promotions/PromotionsArchived'),
  },
  {
    path: '/promotions',
    name: 'promotions',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'ad-manager.order',
          actions: ['view-nav-link'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import('../../views/promotions/Index.vue'),
  },
];
