import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiUrl } from './index';

export default {
  getLanguages() {
    return axios({
      method: 'get',
      url: `${apiUrl}/configs/languages`,
    });
  },
  getTimezones() {
    return axios({
      method: 'get',
      url: `${apiUrl}/configs/timezones`,
    });
  },
  getCountries({ type = -1, is_eu_member = -1 } = {}) {
    const params = {};

    if (type > -1) {
      params.type = type;
    }

    if (is_eu_member > -1) {
      params.is_eu_member = is_eu_member;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/configs/countries`,
      params,
    });
  },
  getColors() {
    return axios({
      method: 'get',
      url: `${apiUrl}/configs/colors`,
    });
  },
};
