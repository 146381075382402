<template>
  <div data-component="alerts-manager">
    <notifications
      animation-type="css"
      class="alert-card"
      position="bottom left"
      :ignore-duplicates="true"
      :reverse="true"
      :speed=1
    >
      <template
        slot="body"
        slot-scope="props"
      >
        <div
          class="alert-card__wrap"
          :class='"alert-card__" + props.item.type'
        >
          <v-icon color="white">warning</v-icon>

          <div>
            {{ props.item.text }}
          </div>

          <v-btn
            text
            depressed
            fab
            icon
            small
            @click="$notify.close(props.item.id)"
          >
            <v-icon color="white">
              cancel
            </v-icon>
          </v-btn>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
  import { isNonEmptyArr } from '../utils';

  export default {
    data() {
      return {
        dismissTimeout: import.meta.env.VUE_APP_ALERT_DURATION,
      };
    },
    computed: {
      allAlerts: {
        get() {
          return this.$store.getters['alerts/all'];
        },
        set(alert) {
          return this.$store.dispatch('alerts/set', alert);
        }
      },
    },
    watch: {
      allAlerts(nextVal) {
        if (!isNonEmptyArr(nextVal)) {
          return;
        }

        this.$notify({
          clean: true
        });

        setTimeout(() => {
          const alert = nextVal[nextVal.length - 1];

          const alertId = Date.now();

          const alertData = {
            id: alertId,
            duration: this.dismissTimeout,
            text: alert.message,
            type: alert.alertType,
          };

          this.showAlert(alertData);

          this.clearAlerts();
        }, 200);
      },
    },
    methods: {
      showAlert(alertData) {
        this.$notify(alertData);
      },
      clearAlerts() {
        return this.$store.dispatch('alerts/dismiss');
      },
    }
  };
</script>

<style lang="scss" scoped>
[data-component='alerts-manager'] {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10000;

    .v-alert {
      border-radius: 0;
    }
  }

  .alert-card {
    color: #fff;
    font-size: 16px;
    width: 100% !important;
    bottom: 0 !important;
    max-height: 100%;

    &__error {
      background-color: #e53935;
    }

    &__warning {
      background-color: #fbc02d;
    }

    &__success {
      background-color: #43A047;
    }

    &__wrap {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 8px 16px;

      >.v-icon {
        margin: 0 16px 0 0;
      }

      >.v-btn {
        margin: 0 0 0 auto;
      }
    }
  }
</style>
