import Vue from 'vue';
import Vuex from 'vuex';
import initialState from './initialState';
import persistentStore from './persistentStore';
/* eslint-disable import/no-cycle */
import accountingApps from './modules/accountingApps';
import alerts from './modules/alerts';
import popups from './modules/popups';
import auth from './modules/auth';
import siteMessages from './modules/siteMessages';
import billing from './modules/billing';
import businesses from './modules/businesses';
import businessUsers from './modules/businessUsers';
import cannedReplies from './modules/cannedReplies';
import config from './modules/config';
import contracts from './modules/contracts';
import creatorProgram from './modules/creatorProgram';
import events from './modules/events';
import invoices from './modules/invoices';
import labels from './modules/labels';
import locale from './modules/locale';
import packages from './modules/packages';
import payments from './modules/payments';
import pepper from './modules/pepper';
import pins from './modules/pins';
import reports from './modules/reports';
import schedules from './modules/schedules';
import settings from './modules/settings';
import shops from './modules/shops';
import tasks from './modules/tasks';
import bumps from './modules/bumps';
import threads from './modules/threads';
import uris from './modules/uris';
import users from './modules/users';
import validation from './modules/validation';
import promotions from './modules/promotions';
import sponsorships from './modules/sponsorships';
import networks from './modules/networks';

Vue.use(Vuex);

const options = {
  plugins: [persistentStore.plugin],
  modules: {
    accountingApps: accountingApps(initialState.accountingApps),
    alerts: alerts(initialState.alerts),
    popups: popups(initialState.popups),
    auth: auth(initialState.auth),
    siteMessages: siteMessages(initialState.siteMessages),
    billing: billing(initialState.billing),
    businesses: businesses(initialState.businesses),
    businessUsers: businessUsers(initialState.businessUsers),
    cannedReplies: cannedReplies(initialState.cannedReplies),
    config: config(initialState.config),
    contracts: contracts(initialState.contracts),
    creatorProgram: creatorProgram(initialState.creatorProgram),
    events: events(initialState.events),
    invoices: invoices(initialState.invoices),
    labels: labels(initialState.labels),
    locale: locale(initialState.locale),
    packages: packages(initialState.packages),
    payments: payments(initialState.payments),
    pepper: pepper(initialState.pepper),
    pins: pins(initialState.pins),
    reports: reports(initialState.reports),
    schedules: schedules(initialState.schedules),
    settings: settings(initialState.settings),
    shops: shops(initialState.shops),
    bumps: bumps(initialState.bumps),
    tasks: tasks(initialState.tasks),
    threads: threads(initialState.threads),
    uris: uris(initialState.uris),
    users: users(initialState.users),
    validation: validation(),
    promotions: promotions(initialState.promotions),
    sponsorships: sponsorships(initialState.sponsorships),
    networks: networks(initialState.networks),
  },
};

/**
 * @description Reset stores
 * @param store
 */
export const resetStores = (store) => {
  store.dispatch('accountingApps/reset');
  store.dispatch('alerts/reset');
  store.dispatch('popups/reset');
  store.dispatch('auth/reset');
  store.dispatch('siteMessages/reset');
  store.dispatch('billing/reset');
  store.dispatch('businesses/reset');
  store.dispatch('businessUsers/reset');
  store.dispatch('cannedReplies/reset');
  store.dispatch('config/reset');
  store.dispatch('contracts/reset');
  store.dispatch('creatorProgram/reset');
  store.dispatch('events/reset');
  store.dispatch('invoices/reset');
  store.dispatch('labels/reset');
  store.dispatch('packages/reset');
  store.dispatch('payments/reset');
  store.dispatch('pepper/reset');
  store.dispatch('pins/reset');
  store.dispatch('reports/reset');
  store.dispatch('schedules/reset');
  store.dispatch('settings/reset');
  store.dispatch('shops/reset');
  store.dispatch('bumps/reset');
  store.dispatch('tasks/reset');
  store.dispatch('threads/reset');
  store.dispatch('uris/reset');
  store.dispatch('users/reset');
  store.dispatch('promotions/reset');
  store.dispatch('sponsorships/reset');
  store.dispatch('networks/reset');
};

const store = new Vuex.Store(options)
export default store
export const useStore = () => store

