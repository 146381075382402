import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiUrl } from './index';

export default {
  getItems() {
    return axios({
      method: 'get',
      url: `${apiUrl}/accounting_apps`,
    });
  },
};
