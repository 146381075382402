import { isStr, isObj } from '../../utils';
import initialState from '../initialState';

const isValid = (locale) => isObj(locale) && isStr(locale.language);

const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial locale state');
  }

  return {
    language: initialState.language,
  };
};

export const getters = {
  language: ({ language }) => language,
};

const actions = {
  setLanguage: ({ commit }, language) => {
    commit('SET', language);
  },
  reset: ({ commit }) => commit('SET', initialState.locale),
};

const mutations = {
  SET(state, language) {
    /* eslint-disable no-param-reassign */
    state.language = language;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
