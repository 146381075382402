export default {
  computed: {
    isProdEnv() {
      return process.env.NODE_ENV === 'production';
    },
  },
  methods: {
    generateTestClass(val, prefix = 'e2e') {
      return !this.isProdEnv ? `${prefix}-${val}` : false;
    },
  },
};
