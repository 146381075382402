import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiUrl, maxItemsPerPage } from './index';
import { isNull, isNum, isStr } from '../../utils';

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export default {
  getItems(options = {}) {
    const {
      countries,
      page = 1,
      perPage = maxItemsPerPage,
      sort = '',
      order = 'asc',
      status = -1,
      type = -1,
      assignee_id = -1,
      reporter_id = -1,
    } = options;

    const params = {
      countries,
      page,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (sort !== '') {
      params.sort = sort;
      params.order = order;
    }

    if (status > -1) {
      params.status = status;
    }

    if (type > -1) {
      params.type = type;
    }

    if (isNull(assignee_id)) {
      params.assignee_id = 'null';
    } else if (assignee_id > -1) {
      params.assignee_id = assignee_id;
    }

    if (reporter_id > -1) {
      params.reporter_id = reporter_id;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/tasks`,
      params,
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/tasks/${id}`,
    });
  },
  createItem(options = {}) {
    return axios({
      method: 'post',
      url: `${apiUrl}/tasks`,
      data: options,
    });
  },
  updateItem(options = {}) {
    const { id, ...restOptions } = options;
    const data = restOptions;

    return axios({
      method: 'put',
      url: `${apiUrl}/tasks/${id}`,
      data,
    });
  },
  updateStatus(options = {}) {
    const { id, status, comment, permissions, predefined_comment_id } = options;
    const data = {
      status,
    };

    if (comment !== '') {
      data.comment = comment;
      data.permissions = permissions;
      data.predefined_comment_id = predefined_comment_id;
    }

    return axios({
      method: 'patch',
      url: `${apiUrl}/tasks/${id}/status`,
      data,
    });
  },
  getFilters() {
    return axios({
      method: 'get',
      url: `${apiUrl}/tasks/filters`,
    });
  },
  getStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/tasks/statuses`,
    });
  },
  getTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/tasks/types`,
    });
  },
  getActions(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      sort = '',
      order = '',
      search = '',
    } = options;

    const params = {
      page,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (sort !== '') {
      params.sort = sort;
      params.order = order;
    }

    if (search !== '') {
      params.search = search;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/tasks/actions`,
    });
  },
  getTaskComments(options = {}) {
    const { id, page = 1, per_page = maxItemsPerPage, filter = '' } = options;
    const params = {
      id,
      page,
    };

    if (per_page > -1) {
      params.per_page = per_page;
    }

    if (filter !== '') {
      params.filter = filter;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/tasks/${id}/comments`,
      params,
    });
  },
  addTaskComment({ id, comment }) {
    const fd = new FormData();

    fd.append('comment', comment.comment);

    if (isStr(comment.permissions)) {
      fd.append('permissions', comment.permissions);
    }

    if (isNum(comment.predefined_comment_id)) {
      fd.append('predefined_comment_id', comment.predefined_comment_id);
    }

    comment.attachments.forEach((attachment) => {
      fd.append('attachments[]', attachment);
    });

    return axios.post(`${apiUrl}/tasks/${id}/comments`, fd, formDataConfig);
  },
};
