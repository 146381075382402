export default [
  {
    path: '/groove-widget',
    name: 'groove-widget',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'thread',
          actions: ['view'],
        },
      ],
    },
    component: () =>
      import(
         '../../views/grooveWidget/Index'
      ),
  },
];
