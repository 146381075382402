import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      search = '',
      order = 'asc',
      sort = '',
      status = -1,
    } = options;

    const params = {
      page,
      order,
      sort,
      search,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (status > -1) {
      params.status = status;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/shops`,
      params,
    });
  },
  createItem(shop) {
    return axios({
      method: 'post',
      url: `${apiUrl}/shops`,
      data: {
        ...shop,
      },
    });
  },
  updateItem(shop) {
    return axios({
      method: 'put',
      url: `${apiUrl}/shops/${shop.id}`,
      data: {
        ...shop,
      },
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/shops/${id}`,
    });
  },
  getStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/shops/statuses`,
    });
  },
  getActivityLogs({ page = 1, perPage = maxItemsPerPage, id }) {
    const params = {
      page,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/shops/${id}/activities`,
      params,
    });
  },
  getUris({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/shops/${id}/uris`,
    });
  },
  addUri({ id, uri_id }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/shops/${id}/uris`,
      data: {
        uri_id,
      },
    });
  },
  removeUri({ id, uri_id }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/shops/${id}/uris/${uri_id}`,
    });
  },
};
