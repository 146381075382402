<template>
  <div data-component="user-menu">
    <TermsAndConditionsDialog
      :is-on="isTermsDialogOn"
      :button-label="$t('close')"
      :on-confirm="toggleTermsDialog"
      :on-cancel="toggleTermsDialog"
    />

    <v-list
      rounded
      dense
    >
      <v-list-item>
        <v-list-item-content>
          <router-link :to="`/users/${activeUser.id}/settings`">
            <v-list-item-title class="user-menu__names">
              {{ userName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ activeUser.employee }}
            </v-list-item-subtitle>
          </router-link>
        </v-list-item-content>

        <v-icon
          class="ml-3"
          @click="openLogoutDialog"
        >
          {{ 'logout' }}
        </v-icon>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
  import { isArr, openUrlInNewWindow } from '../utils';
  import TermsAndConditionsDialog from './dialogs/terms/TermsAndConditions';

  export default {
    components: {
      TermsAndConditionsDialog,
    },
    props: {
      mini: {
        type: Boolean,
        default: false,
      },
      openLogoutDialog: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        isLoggingOut: false,
        isTermsDialogOn: false,
      };
    },
    computed: {
      activeUser() {
        return this.$store.getters['auth/user'];
      },
      userName() {
        const { firstname, lastname } = this.activeUser;
        return `${firstname} ${lastname}`;
      },
    },
    methods: {
      isAuthorized({ permissions }) {
        return (
          !isArr(permissions) ||
          this.$store.getters['auth/isAuthorized'](permissions)
        );
      },
      toggleTermsDialog() {
        this.isTermsDialogOn = !this.isTermsDialogOn;
      },
      visitFaq() {
        openUrlInNewWindow(window.open(), import.meta.env.VUE_APP_FAQ_URL);
      },
    },
  };
</script>

<style lang="scss">
@import '../assets/styles/theme';

[data-component='user-menu'] {
  a {
    text-decoration: none;
  }

  .user-menu__names {
    color: map-get($colors, 'text');
  }

  .faq-icon {
    margin-right: 18px;
    cursor: pointer;
  }

  .trigger.select-item {
    margin: 0;

    @media (max-width: map-get($breakpoints, xs)) {
      height: 24px;
    }

    .v-btn__content {
      justify-content: space-between;
    }

    &:not(.active) {
      .icon-toggle {
        transform: rotate(0deg);
        transition: all 0.15s ease-in-out;
      }
    }

    &.active {
      background-color: map-get($colors, primary);

      .icon-toggle {
        transform: rotate(180deg);
        transition: all 0.15s ease-in-out;
      }
    }

    .user-name {
      @media (max-width: map-get($breakpoints, md)) {
        display: none;
      }
    }

    .v-icon.icon-toggle {
      font-size: $text-size-xl;
      transition: all 0.15s ease-in-out !important;
    }

    .icon-avatar {
      @media (max-width: map-get($breakpoints, xs)) {
        font-size: $text-size-lg;
      }
    }

    .icon-toggle {
      margin: 0 0 0 10px;

      @media (max-width: map-get($breakpoints, md)) {
        margin: 0;
      }
    }

    @media (max-width: map-get($breakpoints, md)) {
      font-size: $text-size-sm;
    }
  }
}

.logout-confirm {
  .modal > .modal__backdrop > .modal__content > .modal__header {
    background-color: #000;

    > span {
      color: #fff;
    }
  }

  .modal > .modal__backdrop > .modal__content > .modal__footer {
    display: flex;
    justify-content: center;
  }
}

.user-menu-content {
  border-radius: 0 0 4px 4px !important;

  .menu-item {
    border-bottom: 1px solid map-deep-get($colors, grey, lighten-3);

    &:last-child {
      border: none;
    }
  }

  .v-list {
    padding-bottom: 0;
    border-radius: 0 0 4px 4px !important;
  }

  .v-list-item__title {
    color: map-get($colors, secondary);
  }

  .active-item {
    background-color: map-deep-get($colors, grey, lighten-1);

    .v-list-item__title {
      color: map-deep-get($colors, shades, 'white');
    }

    .v-icon {
      color: map-deep-get($colors, shades, 'white');
    }
  }
}

.settings-terms {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  color: #757575;
  text-decoration: underline;
  border-radius: 0 0 4px 4px;
  cursor: pointer;

  &:hover {
    background-color: #eeeeee;
  }
}
</style>
