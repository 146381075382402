export default [
  {
    path: '/',
    name: 'index',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
    },
    component: () => import('../../views/Index'),
  },
];
