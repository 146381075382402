<template>
  <span class="no-results-box">
    <v-img src="/img/empty_table.svg" alt="no data image, empty moon" />

    <span class="no-results-box__title">{{ title }}</span>
    <span class="no-results-box__subtitle">{{ subtitle }}</span>
    <slot />
  </span>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: 'No data available',
      },
      subtitle: {
        type: String,
        default: '',
      },
    }
  }
</script>

<style lang="scss">
  @import '../../assets/styles/theme';

  .no-results-box {
    align-items: center;
    color: map-get($colors, text);
    display: flex;
    flex-direction: column;
    padding: 0 0 16px;
    width: 100%;

    &__title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 17px;
    }

    &__subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 14px;
      max-width: 315px;
    }

    img {
      margin: 15px 0 35px;
      max-width: 200px;
    }
  }
</style>
