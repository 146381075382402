export default [
  {
    path: '/canned-replies',
    name: 'canned-replies',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'comment',
          actions: ['list-all'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import( '../../views/cannedReplies/Index'),
  },
  {
    path: '/canned-replies/:id(\\d+)',
    name: 'canned-replies-details',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'comment',
          actions: ['view'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/cannedReplies/Single'
      ),
  },
];
