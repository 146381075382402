import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiUrl } from './index';

export default {
  getLimits({ country }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/settings/${country}/limits`,
    });
  },
  setLimits({ country, ...settings }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/settings/${country}/limits`,
      data: {
        ...settings,
      },
    });
  },
  // TODO delete, we don't use it anymore, very old functionality
  setLimitsMulti({ country, limits }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/settings/${country}/limits/multi`,
      data: {
        limits,
      },
    });
  },
  removeLimits({ country, limit }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/settings/${country}/limits${limit}`,
    });
  },
};
