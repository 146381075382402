import router from '../router';
// import { mergeThreadViews } from '@/components/thread/thread-tracker';
// import { observeTwigViews } from './inview';
import { observeTrackTargets } from './target';
import { track } from './broker';
import './directive';
import init from './bookmarklet';

export { track };

export function initTracking() {
  // don't init before router is ready as we track the current route name
  router.onReady(() => {
    // addInterceptor(mergeThreadViews);
    observeTrackTargets(track);
    // observeTwigViews();
  });

  // will activate bookmarklet
  // eslint-disable-next-line no-underscore-dangle
  window.__pepperTrack = async () => {
    init();
  };
}
