// eslint-disable-next-line import/no-cycle
import store from '../store';
// eslint-disable-next-line import/no-cycle
import router from '.';
import { isArr, isStr, isObj } from '../utils';

/**
 * @description Is matching
 * @param path
 * @param urlParts
 * @returns {boolean}
 */
const isMatching = (path, ...urlParts) => {
  let hasMatch = false;

  urlParts.forEach((urlPart) => {
    if (hasMatch) {
      return false;
    }
    hasMatch = path.indexOf(urlPart) > -1;
  });

  return hasMatch;
};

/**
 * @description Requires authentication
 * @param to
 * @returns {boolean}
 */
const requiresAuth = (to) => to.matched.some((record) => record.meta.auth);

/**
 * @description Is authenticated route
 * @param path
 * @returns {boolean}
 */
const isAuthRoute = (path) => isMatching(path, 'login', 'register', 'password');

/**
 * @description Is logged
 * @returns {*}
 */
const isLogged = () => store.getters['auth/isLogged'];

/**
 * @description Is authorized
 * @param to
 * @returns {*}
 */
const isAuthorized = (to) =>
  router.app.$store.getters['auth/isAuthorized'](to.meta.permissions);

/**
 * @description Has permissions
 * @param to
 * @returns {boolean}
 */
const hasPermissions = (to) => isArr(to.meta.permissions);

/**
 * @description Get redirects
 * @returns {*}
 */
const getRedirects = () => router.app.$store.getters['auth/redirects'];

/**
 * @description Redirect unauthorized
 * @param to
 * @param from
 * @param next
 */
export const redirectUnauthorized = (to, from, next) => {
  const userCountries = store.getters['users/countries'];

  // todo check if this is needed
  if (to.query.country && userCountries.length) {
    return next();
  }

  if (!hasPermissions(to) || isAuthorized(to)) {
    return next();
  }

  const { index } = getRedirects();
  router.push(index).catch(() => {});
};

/**
 * @description Redirect unauthenticated
 * @param to
 * @param from
 * @param next
 */
export const redirectUnauthenticated = (to, from, next) => {
  const { index, unauthenticated, authenticated } = getRedirects();

  if (requiresAuth(to) && !isLogged()) {
    if (to && to.path !== '/' && to.path !== '/login') {
      const query = {
        name: to.name,
        path: to.fullPath,
        country: to.query.country,
      };

      router.app.$store.dispatch('auth/updateSourceRoute', query);
    }
    // empty catch() is to prevent the bugsnag to catch an error when there is a simple redirection
    router.push(unauthenticated).catch(() => {});
  } else if (to.path === index) {
    router.push(authenticated).catch(() => {});
  } else {
    next();
  }
};

/**
 * @description Redirect authenticated
 * @param to
 * @param from
 * @param next
 */
export const redirectAuthenticated = (to, from, next) => {
  const { index } = getRedirects();

  if (isAuthRoute(to.path) && isLogged()) {
    router.push(index).catch(() => {});
  }

  next();
};

/**
 * @description Redirect from index
 * @param to
 * @param from
 * @param next
 */
export const redirectFromIndex = (to, from, next) => {
  const { index, authenticated } = getRedirects();

  if (to.path === index) {
    router.push(authenticated).catch(() => {});
  }
  next();
};
