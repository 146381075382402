import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiUrl } from './index';
import { eachKey, isObj } from '@/utils';

export default {
  getLogoSponsorships(params = {}) {
    return axios({
      method: 'get',
      url: `${apiUrl}/logo_sponsorships`,
      params
    });
  },
  getStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/logo_sponsorships/statuses`,
    });
  },
  getUsedCategories(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/logo_sponsorships/used_categories`,
      params,
    });
  },
  createSponsorship(options = {}) {
    const {
      categories,
      image = null,
      ...rest
    } = options;
    const fd = new FormData();
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    if (image) {
      fd.append('logo', image); // Correctly append the image
    }

    eachKey(rest, (key, prop) => {
      if (isObj(prop)) {
        Object.keys(prop).forEach((k) => {
          fd.append(`${key}[${k}]`, prop[k]);
        });
      } else {
        fd.append(key, prop);
      }
    });

    categories.forEach((e, i) => {
      fd.append(`categories[${i}][name]`, e.name);
      fd.append(`categories[${i}][id]`, e.id);
    });

    return axios.post(`${apiUrl}/logo_sponsorships`, fd, config);
  },
  editSponsorship({id, ...params}) {
    const {
      categories,
      image = null,
      ...rest
    } = params;
    const fd = new FormData();
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    if (image) {
      fd.append('logo', image); // Correctly append the image
    }
    eachKey(rest, (key, prop) => {
      if (prop === null) return;
      if (isObj(prop)) {
        Object.keys(prop).forEach((k) => {
          fd.append(`${key}[${k}]`, prop[k]);
        });
      } else {
        fd.append(key, prop);
      }
    });

    categories.forEach((e, i) => {
      fd.append(`categories[${i}][name]`, e.name);
      fd.append(`categories[${i}][id]`, e.id);
    });

    return axios.post(`${apiUrl}/logo_sponsorships/${id}`, fd, config);
  },
  getLogoSponsorship(id) {
    return axios({
      method: 'get',
      url: `${apiUrl}/logo_sponsorships/${id}`,
    });
  },
};
