<template>
  <div data-component="date-picker">
    <template v-if="hasInput">
      <input-custom
        v-model="date"
        type="text"
        class="date-time-field"
        prepend-inner-icon="date_range"
        :label="placeholder"
        :persistent-hint="persistentHint"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        :hint="hint"
        readonly
        :disabled="isDisabled || disabled"
        @input="onInput"
        @blur="onBlur"
        @focus="toggleDatePicker"
      />

      <v-btn
        v-if="hasDate && hasClear && !isDisabled"
        class="trigger clear-date"
        text
        depressed
        @click="clearDate"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </template>

    <flat-pickr
      v-if="!isDisabled"
      ref="datePicker"
      v-model="dateText"
      class="elevation-6"
      :config="dateConfig"
      @change="onChange"
      @on-close="closeAndSelect"
    />
  </div>
</template>

<script>
  // v-input date-time-field v-input--is-readonly v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined
  // v-input date-time-field v-input--is-readonly v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined
  import colors from 'vuetify/es5/util/colors';
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css';
  import { isObj } from '../utils';
  import _ from "lodash";

  export default {
    components: {
      flatPickr
    },
    props: {
      date: {
        type: [String, Array],
        required: true
      },
      hasClear: {
        type: Boolean,
        default: true
      },
      hideDetails: {
        type: Boolean,
        default: true
      },
      defaultDate: {
        type: [String, Array],
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      hint: {
        type: String,
        default: ''
      },
      persistentHint: {
        type: Boolean,
        default: false
      },
      config: {
        type: Object,
        default() {
          return {};
        }
      },
      // TODO rename isDisabled to readonly on refactor
      isDisabled: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      hasInput: {
        type: Boolean,
        default: true
      },
      onChange: {
        type: Function,
        default: () => {}
      },
      onClose: {
        type: Function,
        default: () => {}
      },
      onClear: {
        type: Function,
        default: () => {}
      },
      onInput: {
        type: Function,
        default: () => {}
      },
      onBlur: {
        type: Function,
        default: () => {}
      },
      errorMessages: {
        type: Array,
        default() {
          return [];
        }
      },
    },
    data() {
      return {
        dateText: '',
        dateConfig: {
          monthSelectorType: 'dropdown',
        },
        colors,
        isOn: false
      };
    },
    computed: {
      hasDate() {
        return this.date !== '';
      },
      hasDatePickerInstance() {
        return isObj(this.$refs.datePicker);
      }
    },
    watch: {
      date(nextVal) {
        if (nextVal === '' && this.hasDatePickerInstance) {
          this.clearDate();
        }
      },
      dateText(nextVal) {
        if (nextVal === null) {
          this.dateText = '';
          this.onChange('');
        } else {
          const formattedNextDate = nextVal.replace('to', '-');
          this.onChange(formattedNextDate);
        }
      },
      config(nextVal) {
        if (!this.hasDatePickerInstance) {
          return false;
        }

        if (nextVal.minDate) {
          this.setDatePickerProp('minDate', nextVal.minDate);
        }

        if (nextVal.maxDate) {
          this.setDatePickerProp('maxDate', nextVal.maxDate);
        }
      },
      defaultDate(nextVal) {
        if (!this.hasDatePickerInstance) {
          return false;
        }

        this.setDate(nextVal);
      }
    },
    created() {
      this.dateConfig = {
        ...this.config,
        locale: {
          firstDayOfWeek: 1
        },
        defaultDate: this.defaultDate,
        onOpen: () => {
          this.isOn = true;
        },
        onClose: () => {
          this.isOn = false;
        }
      };
    },
    methods: {
      closeAndSelect() {
        // timeout to trigger close after change
        setTimeout(() => {
          const formattedNextDate =
            this.dateText !== null ? this.dateText.replace('to', '-') : '';
          this.onClose(formattedNextDate);
        }, 0);
      },
      toggleDatePicker() {
        if (this.isDisabled) {
          return false;
        }

        this.isOn = !this.isOn;

        if (this.isOn) {
          this.openDatePicker();
        } else {
          this.closeDatePicker();
        }
      },
      getDatePickerInstance() {
        // eslint-disable-next-line no-underscore-dangle
        return this.$refs.datePicker.$vnode.elm._flatpickr;
      },
      setDate(value) {
        const datePicker = this.getDatePickerInstance();
        datePicker.setDate(value);
      },
      setDatePickerProp(prop, value) {
        if (!this.hasDatePickerInstance) {
          return false;
        }

        const datePicker = this.getDatePickerInstance();
        datePicker.set(prop, value);
      },
      openDatePicker() {
        const datePicker = this.getDatePickerInstance();
        datePicker.open();
      },
      closeDatePicker() {
        const datePicker = this.getDatePickerInstance();
        datePicker.close();
      },
      clearDate() {
        if (!isObj(this.$refs.datePicker)) {
          return false;
        }

        const datePicker = this.getDatePickerInstance();
        datePicker.clear();
        this.onClear();
      }
    }
  };
</script>

<style lang="scss">
  @import '../assets/styles/theme';

  .flatpickr-calendar {
    font-family: $font-family;
    border-radius: $border-radius !important;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);

    &:after,
    &:before {
      display: none;
    }

    &.inline {
      top: 0;
    }
  }

  .flatpickr-input {
    width: 100%;
    min-height: 57px;
    padding: 10px;
    margin: 0 0 30px 0;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border-bottom: 2px solid map-deep-get($colors, grey, base);
    background-color: map-deep-get($colors, shades, 'white');
    outline: none;
  }

  .flatpickr-day {
    &:hover {
      border-color: map-deep-get($colors, orange, lighten-5) !important;
      background-color: map-deep-get($colors, orange, lighten-5);
    }

    &.today {
      font-weight: 700;
      border: none;

      &:hover {
        font-weight: 500;
        background-color: map-deep-get($colors, orange, lighten-5);

        &:not(.startRange):not(.endRange) {
          color: map-get($colors, text) !important;
        }
      }
    }

    &.selected {
      font-weight: 700;

      &:hover {
        color: map-deep-get($colors, shades, 'white');
      }
    }
  }


  .flatpickr-day.selected,
  .flatpickr-day.startRange,
  .flatpickr-day.endRange,
  .flatpickr-day.selected.inRange,
  .flatpickr-day.startRange.inRange,
  .flatpickr-day.endRange.inRange,
  .flatpickr-day.selected:focus,
  .flatpickr-day.startRange:focus,
  .flatpickr-day.endRange:focus,
  .flatpickr-day.selected:hover,
  .flatpickr-day.startRange:hover,
  .flatpickr-day.endRange:hover,
  .flatpickr-day.selected.prevMonthDay,
  .flatpickr-day.startRange.prevMonthDay,
  .flatpickr-day.endRange.prevMonthDay,
  .flatpickr-day.selected.nextMonthDay,
  .flatpickr-day.startRange.nextMonthDay,
  .flatpickr-day.endRange.nextMonthDay {
    background: map-get($colors, primary) !important;
    border-color: map-get($colors, primary) !important;
  }

  .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
  .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
    -webkit-box-shadow: -10px 0 0 map-get($colors, primary) !important;
    box-shadow: -10px 0 0 map-get($colors, primary) !important;
  }

  .flatpickr-day.week.selected {
    -webkit-box-shadow: -5px 0 0 map-get($colors, primary), 5px 0 0 map-get($colors, primary) !important;
    box-shadow: -5px 0 0 map-get($colors, primary), 5px 0 0 map-get($colors, primary) !important;
  }

  .flatpickr-months {
    padding: 12px 0 0 0;
    margin: 0 0 6px 0;
  }

  .flatpickr-current-month {
    padding: 0;
  }

  .flatpickr-months .flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month {
    line-height: 32px;
    height: 44px;
  }

  .flatpickr-current-month {
    .numInputWrapper {
      span.arrowUp:after {
        border-bottom-color: map-deep-get($colors, shades, 'white');
      }

      span.arrowDown:after {
        border-top-color: map-deep-get($colors, shades, 'white');
      }
    }

    span.cur-month {
      font-weight: 400;
    }
  }

  [data-component='date-picker'] {
    position: relative;

    &.disabled {
      .date-time-field {
        cursor: default;
      }

      .flatpickr-input {
        cursor: default;
      }
    }

    .flatpickr-input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .v-text-field__details {
      padding: 0 !important;
    }

    .trigger.clear-date {
      position: absolute;
      top: 4px;
      right: 6px;
      padding: 0;
      margin: 0;
      min-width: auto;
      width: 20px;
      height: 20px;
      min-height: auto;
      color: map-deep-get($colors, grey, base);

      .v-icon:not(.icon-toggle) {
        margin: 0;
      }

      &:before {
        background-color: transparent;
      }
    }
  }
</style>
