// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
import initialState from '../initialState';

const settingsApi = apiClient.merchy.settings;

/**
 * @description Is valid
 * @param settings
 * @returns {boolean}
 */
const isValid = (settings) => {
  const { limits } = settings;

  return isArr(limits);
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial settings state');
  }

  const { limits } = initialState;

  return { limits };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  limits: ({ limits }) => limits,
};

const actions = {
  getLimits: ({ commit, state }, query) =>
    settingsApi.getLimits(query).then((res) => {
      const nextLimits = res.data.data;
      const nextState = {
        ...state,
        limits: nextLimits,
      };

      commit('SET', nextState);

      return nextLimits;
    }),
  setLimits: ({ commit, state }, query) =>
    settingsApi.setLimits(query).then((res) => {
      const nextLimits = res.data.data;
      const nextState = {
        ...state,
        limits: nextLimits,
      };

      commit('SET', nextState);

      return nextLimits;
    }),
  setLimitsMulti: ({ commit, state }, query) =>
    settingsApi.setLimitsMulti(query).then((res) => {
      const nextLimits = res.data.data;
      const nextState = {
        ...state,
        limits: nextLimits,
      };

      commit('SET', nextState);

      return nextLimits;
    }),
  removeLimits: ({ commit, state }, query) =>
    settingsApi.removeLimits(query).then((res) => {
      const nextLimits = res.data.data;
      const nextState = {
        ...state,
        limits: nextLimits,
      };

      commit('SET', nextState);

      return nextLimits;
    }),
  reset: ({ commit }) => commit('SET', initialState.settings),
  set: ({ commit }, settings) => {
    commit('SET', settings);
  },
};

const mutations = {
  SET(state, settings) {
    /* eslint-disable no-param-reassign */
    state.limits = settings.limits;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
