import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiUrl, maxItemsPerPage } from './index';
import { checkRequestParameter } from "@/utils";

export default {
  getClickouts(params = {}) {
    return axios({
      method: 'get',
      url: `${apiUrl}/reports/performance/clickouts`,
      params,
    });
  },
  getEngagements(params = {}) {
    return axios({
      method: 'get',
      url: `${apiUrl}/reports/performance/engagements`,
      params,
    });
  },
  getDeals({page = 1, perPage = maxItemsPerPage, sort = '', ...rest}) {
    const params = {
      ...rest,
      page,
      sort,
      per_page: perPage
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/reports/performance/engagements/deal`,
      params,
    });
  },
  getChartSummary(params = {}) {
    return axios({
      method: 'get',
      url: `${apiUrl}/reports/performance/summary`,
      params,
    });
  },
  getTopDeals(params = {}) {
    return axios({
      method: 'get',
      url: `${apiUrl}/reports/performance/top_deals`,
      params,
    });
  },
  getStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/reports/performance/statuses`,
    });
  },
  getSubmissionTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/reports/performance/submission_types`,
    });
  },

  getInvoices(options = {}) {
    const {
      search,
      created_at,
      countries,
      invoice_period,
      page = 1,
      perPage = maxItemsPerPage,
      sort,
      order,
    } = options;

    const params = {
      page,
      sort,
      order,
      countries
    };

    params.search = checkRequestParameter(search);
    params.created_at = checkRequestParameter(created_at);
    params.invoice_period = checkRequestParameter(invoice_period);

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/reports/invoices`,
      params,
    });
  },
  getDaily(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      sort,
      order,
      contract,
      date_range,
    } = options;

    const params = {
      page,
      sort,
      order,
      date_range,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (contract > -1) {
      params.contract_id = contract;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/reports/daily`,
      params,
    });
  },
  getDailyCpm(options = {}) {
    const {
      advertiser,
      date_range,
      sort,
      order,
      perPage = maxItemsPerPage,
      page = 1,
      businessId,
      merchantId,
    } = options;

    const params = {
      date_range,
      order,
      sort,
      page,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (advertiser > -1) {
      params.advertiser_id = advertiser;
    }

    if (businessId > -1) {
      params.business_id = businessId;
    }

    if (merchantId > -1) {
      params.merchant_id = merchantId;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/reports/cpm`,
      params,
    });
  },
  getDailyAdvanced(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      sort,
      order,
      contract,
      date_range,
    } = options;

    const params = {
      page,
      sort,
      order,
      date_range,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (contract > -1) {
      params.contract_id = contract;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/reports/daily/advanced`,
      params,
    });
  },
  getMonthly(options) {
    const { billing_account_id, country, month, year } = options;
    const params = {
      billing_account_id,
      country,
      month,
      year,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/reports/monthly`,
      params,
    });
  },
  getAccounts() {
    return axios({
      method: 'get',
      url: `${apiUrl}/reports/billing`,
    });
  },
  getContracts() {
    return axios({
      method: 'get',
      url: `${apiUrl}/reports/contracts`,
    });
  },
  getAdvancedContracts() {
    return axios({
      method: 'get',
      url: `${apiUrl}/reports/contracts?advanced=1`,
    });
  },
  downloadPerformanceReport(params) {
    return axios({
      method: 'get',
      responseType: 'blob',
      url: `${apiUrl}/reports/performance/download`,
      params,
    });
  },
  downloadDaily(options = {}) {
    const { sort, order, contract, date_range } = options;
    const params = {
      sort,
      order,
      date_range,
    };

    if (contract > -1) {
      params.contract_id = contract;
    }

    return axios({
      method: 'get',
      responseType: 'blob',
      url: `${apiUrl}/reports/daily/download`,
      params,
    });
  },
  downloadDailyAdvanced(options = {}) {
    const { sort, order, contract, date_range } = options;
    const params = {
      sort,
      order,
      date_range,
    };

    if (contract > -1) {
      params.contract_id = contract;
    }

    return axios({
      method: 'get',
      responseType: 'blob',
      url: `${apiUrl}/reports/daily/advanced/download`,
      params,
    });
  },
  downloadDailyCpm(options = {}) {
    const { sort, order, advertiser, businessId, merchantId, date_range } = options;
    const params = {
      sort,
      order,
      date_range,
    };

    if (advertiser > -1) {
      params.advertiser_id = advertiser;
    }

    if (businessId) {
      params.business_id = businessId;
    }

    if (merchantId > -1) {
      params.merchant_id = merchantId;
    }

    return axios({
      method: 'get',
      responseType: 'blob',
      url: `${apiUrl}/reports/cpm/download`,
      params,
    });
  },
  verifyLink({ hash }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/mail_analytics/${hash}`,
    });
  },
};
