// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const shopsApi = apiClient.merchy.shops;

/**
 * @description Is valid
 * @param users
 * @returns {boolean}
 */
const isValid = (users) => {
  const { activeItem, items, itemsMeta, statuses } = users;

  const isValidActiveItem =
    isObj(activeItem) &&
    isObj(activeItem.log) &&
    isArr(activeItem.log.items) &&
    isObj(activeItem.log.meta);

  return (
    isObj(users) &&
    isArr(items) &&
    isObj(itemsMeta) &&
    isArr(statuses) &&
    isValidActiveItem
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial users state');
  }

  const { activeItem, items, itemsMeta, statuses } = initialState;
  return {
    activeItem,
    items,
    itemsMeta,
    statuses,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  activeItem: ({ activeItem }) => activeItem,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
  statuses: ({ statuses }) => statuses,
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') =>
  store.dispatch('alerts/set', {
    data,
    alertType,
  });

const actions = {
  create: (context, shop) =>
    shopsApi.createItem(shop).then((res) => {
      const nextShop = res.data.data;
      const { name } = nextShop;

      return handleAlerts(
        {
          data: {
            message: `Merchant shop with name "${name}" created successfully.`,
          },
        },
        'success',
      );
    }),
  update: ({ commit, state }, shop) =>
    shopsApi.updateItem(shop).then((res) => {
      const { activeItem } = state;
      const nextShop = res.data.data;
      const { name } = nextShop;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextShop,
        },
      };

      commit('SET', nextState);

      return handleAlerts(
        {
          data: {
            message: `Merchant shop with name "${name}" has been updated successfully.`,
          },
        },
        'success',
      );
    }),
  getItem: ({ commit, state }, query) =>
    shopsApi.getItem(query).then((res) => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...res.data.data,
        },
      };

      return commit('SET', nextState);
    }),
  getItems: ({ commit, state }, query) =>
    shopsApi.getItems(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = {
        items: data,
        itemsMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);

      return nextItems;
    }),
  getStatuses: ({ commit, state }) =>
    shopsApi.getStatuses().then((res) => {
      const nextState = {
        ...state,
        statuses: res.data.data,
      };

      return commit('SET', nextState);
    }),
  setActiveItem: ({ commit, state }, shop) => {
    const nextState = {
      ...state,
      activeItem: {
        ...state.activeItem,
        ...shop,
      },
    };

    return commit('SET', nextState);
  },
  getActivityLogs: ({ commit, state }, query) =>
    shopsApi.getActivityLogs(query).then((res) => {
      const { activeItem } = state;
      const { data, meta } = res.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          log: {
            items: data,
            meta,
          },
        },
      };

      return commit('SET', nextState);
    }),
  getUris: ({ commit, state }, query) =>
    shopsApi.getUris(query).then((res) => {
      const { activeItem } = state;
      const nextUris = res.data.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          uris: nextUris,
        },
      };

      commit('SET', nextState);

      return nextUris;
    }),
  addUri: ({ commit, state }, query) =>
    shopsApi.addUri(query).then((res) => {
      const { activeItem } = state;
      const nextUris = res.data.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          uris: nextUris,
        },
      };

      commit('SET', nextState);

      return nextUris;
    }),
  removeUri: ({ commit, state }, query) =>
    shopsApi.removeUri(query).then((res) => {
      const { activeItem } = state;
      const nextUris = res.data.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          uris: nextUris,
        },
      };

      commit('SET', nextState);

      return nextUris;
    }),
  reset: ({ commit }) => commit('SET', initialState.shops),
  set: ({ commit }, shops) => {
    commit('SET', shops);
  },
};

const mutations = {
  SET(state, shops) {
    /* eslint-disable no-param-reassign */
    state.activeItem = shops.activeItem;
    state.items = shops.items;
    state.itemsMeta = shops.itemsMeta;
    state.statuses = shops.statuses;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
