<template>
  <div data-component="file-upload">
    <button-custom
      color="primary"
      :outlined="outlined"
    >
      <VueUploadComponent
        ref="upload"
        v-model="files"
        :input-id="id"
        :accept="accept"
        @input-file="inputFile"
        @input-filter="inputFilter"
      >
        <v-icon v-if="hasIcon" :class="getIconClass()">
          {{ icon }}
        </v-icon>

        <span class="btn-label">
          {{ triggerLabel }}
        </span>
      </VueUploadComponent>
    </button-custom>
  </div>
</template>

<script>
  import { v1 as uuidv1 } from 'uuid';
  import VueUploadComponent from 'vue-upload-component';
  import { isStr, isObj, isFunc } from '../utils';

  export default {
    components: {
      VueUploadComponent,
    },
    props: {
      outlined: {
        type: Boolean,
        default: false,
      },
      triggerLabel: {
        type: String,
        default: 'Upload',
      },
      icon: {
        type: String,
        default: 'file_upload',
      },
      accept: {
        type: String,
        default: '/*',
      },
      onFilter: {
        type: Function,
        default: undefined,
      },
      onChange: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        id: this.returnRandomId(),
        files: []
      };
    },
    computed: {
      hasIcon() {
        return this.icon !== '';
      },
    },
    methods: {
      returnRandomId() {
        return uuidv1();
      },
      inputFile(newFile) {
        if (!isObj(newFile) || !isStr(newFile.name)) {
          return false;
        }

        this.onChange(newFile);
      },
      inputFilter(newFile, oldFile, prevent) {
        const hasFile = newFile && !oldFile;

        if (hasFile && isFunc(this.onFilter) && this.onFilter(newFile)) {
          return prevent();
        }
      },
      getIconClass() {
        return {
          'rotated-90': this.icon !== 'file_upload',
        };
      },
    },
  };
</script>

<style lang="scss">
@import '../assets/styles/theme';

[data-component='file-upload'] {
  display: inline-block;

  .file-uploads {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .file-uploads.file-uploads-html5 label {
    cursor: pointer !important;
  }
}
</style>
