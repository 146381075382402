<template>
  <v-list-group
    v-if="businessRoles?.length > 1 && !mini"
    :value="false"
    class="change-business"
    color="secondary"
  >
    <template v-slot:activator>
      <v-list-item-title v-text="loggedUser.business?.name"/>
    </template>

    <div class="mb-2 change-business__items">
      <v-list-item
        dense
        v-for="(businessRole, key) in businessRoles"
        :key="businessRole.business_id"
        @click="switchBusiness(businessRole, key)"
        color="primary"
        :input-value="isCurrentBusiness(businessRole)"
      >
        <v-list-item-content>
          {{ businessRole.businessName }}
        </v-list-item-content>

        <v-icon
          v-if="isCurrentBusiness(businessRole)"
          color="primary"
          small
        >
          done
        </v-icon>
      </v-list-item>
    </div>
  </v-list-group>
  <v-list-item
    v-else-if="mini"
    class="v-list change-business change-business__single"
  >
    <v-icon>
      domain
    </v-icon>
  </v-list-item>
  <v-list-item
    v-else
    class="change-business change-business__single"
  >
    <v-list-item-title v-text="loggedUser.business?.name"/>
  </v-list-item>
</template>

<script>
  export default {
    props: {
      user: {
        type: Object,
        default: undefined,
      },
      mini: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      switchButtonsLoading: [],
    }),
    computed: {
      loggedUser() {
        return this.$store.getters['auth/user'];
      },
      businessRoles() {
        if (!this.loggedUser?.available_roles?.length) {
          return [];
        }
        const arr = this.loggedUser.available_roles
          .sort((a, b)=> {
            if (a.business.id === this.loggedUser.last_business_id) return -1;
            if (b.business.id === this.loggedUser.last_business_id) return 1;
            if (a.business.name.toUpperCase() < b.business.name.toUpperCase()) return -1;
            if (a.business.name.toUpperCase() > b.business.name.toUpperCase()) return 1;
          })

        const index = arr.indexOf(item => item.business.id === this.loggedUser.last_buiness_id);

        if (index > -1) {
          arr.splice(0, 0, arr.splice(index, 1)[0]);
        }

        return arr.map((brole) => ({
          role: brole.slug,
          roleName: brole.name,
          business_id: brole.business?.id,
          businessName: brole.business?.name,
          status: brole.status.id,
          statusName: brole.status.name,
        }));
      },
    },
    methods: {
      isCurrentBusiness(businessRole){
        return this.loggedUser.business?.id === businessRole.business_id;
      },
      switchBusiness(businessRole, key) {
        if (this.isCurrentBusiness(businessRole)) {
          return;
        }

        const newBusinessId = businessRole.business_id;
        this.$set(this.switchButtonsLoading, key, 1);

        return this.$store
          .dispatch('businessUsers/switchBusiness', newBusinessId)
          .catch((err) => err);
      },
    },
  }
</script>

<style lang="scss">
  .change-business {
    flex-grow: 0;
    flex-basis: auto;

    .v-list-group__header {
      min-height: 52px;
      padding-left: 24px;
      padding-right: 24px;
    }

    .v-list-item__title {
      font-size: 14px;
      font-weight: 600;
    }

    &__items {
      .v-list-item {
        max-height: 33px;
        min-height: auto;
        padding: 0 44px 0 36px;
      }

      .v-list-item--active {
        font-weight: 700;

        &:before {
          background-color: #fff;
        }
      }

      .v-list-item__content {
        font-size: 14px;
      }
    }
  }
</style>
