import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
// eslint-disable-next-line import/no-cycle
import store from '..';
import ability from '../../permissions/ability';
import { isObj, isArr } from '../../utils';
import initialState from '../initialState';

const configApi = apiClient.merchy.config;
const usersApi = apiClient.merchy.users;

/**
 * @description Is valid
 * @param config
 * @returns {boolean}
 */
const isValid = (config) =>
  isObj(config) &&
  isArr(config.languages) &&
  isArr(config.timezones) &&
  isArr(config.countries) &&
  isArr(config.euCountries) &&
  isArr(config.colors) &&
  isArr(config.notificationAssignments) &&
  isArr(config.notificationAccountTypes);

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial config state');
  }

  const {
    languages,
    timezones,
    countries,
    euCountries,
    colors,
    notificationAssignments,
    notificationAccountTypes,
  } = initialState;
  return {
    languages,
    timezones,
    countries,
    euCountries,
    colors,
    notificationAssignments,
    notificationAccountTypes,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  languages: ({ languages }) => languages,
  timezones: ({ timezones }) => timezones,
  countries: ({ countries }) => countries,
  euCountries: ({ euCountries }) => euCountries,
  colors: ({ colors }) => colors,
  notificationAssignments: ({ notificationAssignments }) =>
    notificationAssignments,
  notificationAccountTypes: ({ notificationAccountTypes }) =>
    notificationAccountTypes,
};

const actions = {
  getLanguages: ({ commit, state }) => {
    return configApi.getLanguages().then((res) => {
      const nextLanguages = _.sortBy(res.data.data, (language) =>
        language.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        languages: nextLanguages,
      };

      commit('SET', nextState);

      return nextLanguages;
    })
  },
  getTimezones: ({ commit, state }) => {
    return configApi.getTimezones().then((res) => {
      const nextTimezones = _.sortBy(res.data.data, (timezone) =>
        timezone.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        timezones: nextTimezones,
      };

      commit('SET', nextState);

      return nextTimezones;
    })
  },
  getCountries: ({ commit, state }) =>
    configApi.getCountries().then((res) => {
      const nextCountries = _.sortBy(res.data.data, (country) =>
        country.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        countries: nextCountries,
      };

      commit('SET', nextState);

      return nextCountries;
    }),
  getEuCountries: ({ state, commit }, query = {}) => {
    const nextQuery = {
      ...query,
      is_eu_member: 1,
    };

    return configApi.getCountries(nextQuery).then((res) => {
      const nextEUCountries = _.sortBy(res.data.data, (country) =>
        country.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        euCountries: nextEUCountries,
      };

      commit('SET', nextState);

      return nextEUCountries;
    });
  },
  getColors: ({ commit, state }) =>
    configApi.getColors().then((res) => {
      const nextColors = _.sortBy(res.data.data, (color) =>
        color.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        colors: nextColors,
      };

      commit('SET', nextState);

      return nextColors;
    }),
  getNotificationAssignments: ({ commit, state }) =>
    usersApi.getAssignmentTypes().then((res) => {
      const nextAssignments = _.sortBy(res.data.data, (assignment) =>
        assignment.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        notificationAssignments: nextAssignments,
      };

      return commit('SET', nextState);
    }),
  getNotificationAccountTypes: ({ commit, state }) =>
    usersApi.getAssignmentTypes().then((res) => {
      const nextAccountTypes = _.sortBy(res.data.data, (accountType) =>
        accountType.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        notificationAccountTypes: nextAccountTypes,
      };

      return commit('SET', nextState);
    }),
  reset: ({ commit }) => commit('SET', initialState.config),
  set: ({ commit }, config) => {
    commit('SET', config);
  },
};

const mutations = {
  SET(state, config) {
    /* eslint-disable no-param-reassign */
    state.languages = config.languages;
    state.timezones = config.timezones;
    state.countries = config.countries;
    state.euCountries = config.euCountries;
    state.colors = config.colors;
    state.notificationAssignments = config.notificationAssignments;
    state.notificationAccountTypes = config.notificationAccountTypes;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
