import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const siteMessagesApi = apiClient.merchy.siteMessages;

/**
 * @description Is valid
 * @param siteMessages
 * @returns {boolean}
 */
const isValid = (siteMessages) => {
  const { activeItem, items, itemsMeta, statuses, presentableItems } = siteMessages;

  return (
    isObj(siteMessages) &&
    isObj(activeItem) &&
    isArr(items) &&
    isObj(itemsMeta) &&
    isArr(statuses) &&
    isArr(presentableItems)
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial siteMessages state');
  }

  const { activeItem, items, itemsMeta, statuses, presentableItems } =
    initialState;
  return {
    activeItem,
    items,
    itemsMeta,
    statuses,
    presentableItems,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  activeItem: ({ activeItem }) => activeItem,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
  statuses: ({ statuses }) => statuses,
  presentableItems: ({ presentableItems }) => presentableItems,
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') =>
  store.dispatch('alerts/set', {
    data,
    alertType,
  });

const actions = {
  create: (context, banner) =>
    siteMessagesApi.createItem(banner).then((res) => {
      const { name } = res.data.data;

      return handleAlerts(
        {
          data: {
            message: `Banner "${name}" created successfully.`,
          },
        },
        'success',
      );
    }),
  getItem: ({ commit, state }, query) =>
    siteMessagesApi.getItem(query).then((res) => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...res.data.data,
        },
      };

      return commit('SET', nextState);
    }),
  update: ({ commit, state }, banner) =>
    siteMessagesApi.updateItem(banner).then((res) => {
      const { activeItem } = state;
      const nextBanner = res.data.data;
      const { name } = nextBanner;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextBanner,
        },
      };

      commit('SET', nextState);

      return handleAlerts(
        {
          data: {
            message: `Banner "${name}" has been updated successfully.`,
          },
        },
        'success',
      );
    }),
  getItems: ({ commit, state }, query) =>
    siteMessagesApi.getItems(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = {
        items: data,
        itemsMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);

      return nextItems;
    }),
  setActiveItem: ({ commit, state }, banner) => {
    const nextState = {
      ...state,
      activeItem: {
        ...state.activeItem,
        ...banner,
      },
    };

    return commit('SET', nextState);
  },
  getStatuses: ({ commit, state }) =>
    siteMessagesApi.getStatuses().then((res) => {
      const nextStatuses = _.sortBy(res.data.data, (status) =>
        status.name.toLowerCase(),
      );
      const nextState = {
        ...state,
        statuses: nextStatuses,
      };

      return commit('SET', nextState);
    }),
  getPresentableItems: ({ commit, state }) =>
    siteMessagesApi.getPresentableItems().then((res) => {
      const nextPresentableItems = res.data.data;
      const nextState = {
        ...state,
        presentableItems: nextPresentableItems,
      };

      return commit('SET', nextState);
    }),
  setDismissed: ({ commit, state }, banner) =>
    siteMessagesApi.setDismissed(banner).then(() => {
      const { presentableItems } = state;
      const nextPresentableItems = presentableItems.filter(
        (item) => item.id !== banner.id,
      );

      const nextState = {
        ...state,
        presentableItems: nextPresentableItems,
      };

      return commit('SET', nextState);
    }),
  trackRedirect: (context, banner) => {
    siteMessagesApi.trackRedirect(banner);
  },
  reset: ({ commit }) => commit('SET', initialState.siteMessages),
  set: ({ commit }, banners) => {
    commit('SET', banners);
  },
};

const mutations = {
  SET(state, banners) {
    /* eslint-disable no-param-reassign */
    state.activeItem = banners.activeItem;
    state.items = banners.items;
    state.itemsMeta = banners.itemsMeta;
    state.statuses = banners.statuses;
    state.presentableItems = banners.presentableItems;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
