import download from 'downloadjs';
// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isArr, isObj } from '@/utils';
import initialState from '../initialState';

const reportsApi = apiClient.merchy.reports;

/**
 * @description Is valid
 * @param settings
 * @returns {boolean}
 */
const isValid = (settings) => {
  const { invoices, daily, dailyCpm, advancedDaily, contracts, advancedContracts,
    engagements, deals, deal, engagementsSingle, chartSummarySingle, dealsMeta, topDeals, chartSummary, statuses, submissionTypes } = settings;

  return (
    isArr(invoices) &&
    isObj(daily) &&
    isObj(dailyCpm) &&
    isObj(advancedDaily) &&
    isArr(contracts) &&
    isArr(advancedContracts) &&

    isArr(engagements) &&
    isArr(engagementsSingle) &&
    isArr(deal) &&
    isArr(deals) &&
    isObj(dealsMeta) &&
    isArr(topDeals) &&
    isArr(chartSummary) &&
    isArr(chartSummarySingle) &&
    isArr(statuses) &&
    isArr(submissionTypes)
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial invoices state');
  }

  const { invoices, daily, dailyCpm, advancedDaily, contracts, advancedContracts,
    engagements, engagementsSingle, deal, chartSummarySingle, deals, dealsMeta, topDeals, chartSummary, statuses, submissionTypes } = initialState;
  return {
    invoices,
    daily,
    dailyCpm,
    advancedDaily,
    contracts,
    advancedContracts,
    engagements,
    deals,
    dealsMeta,
    topDeals,
    chartSummary,
    statuses,
    submissionTypes,
    engagementsSingle,
    deal,
    chartSummarySingle
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  invoices: ({ invoices }) => invoices,
  daily: ({ daily }) => daily,
  dailyCpm: ({ dailyCpm }) => dailyCpm,
  advancedDaily: ({ advancedDaily }) => advancedDaily,
  accounts: ({ accounts }) => accounts,
  contracts: ({ contracts }) => contracts,
  advancedContracts: ({ advancedContracts }) => advancedContracts,

  engagements: ({ engagements }) => engagements,
  engagementsSingle: ({ engagementsSingle }) => engagementsSingle,
  deal: ({ deal }) => deal,
  deals: ({ deals }) => deals,
  dealsMeta: ({ dealsMeta }) => dealsMeta,
  topDeals: ({ topDeals }) => topDeals,
  chartSummarySingle: ({ chartSummarySingle }) => chartSummarySingle,
  chartSummary: ({ chartSummary }) => chartSummary,

  statuses: ({ statuses }) => statuses,
  submissionTypes: ({ submissionTypes }) => submissionTypes,
};

const actions = {
  getClickouts: ({ commit, state }, query) =>
    reportsApi.getClickouts(query).then((res) => {
      return res.data.data;
    }),
  getEngagementsSingle: ({ commit, state }, query) =>
    reportsApi.getEngagements(query).then((res) => {
      const { data } = res.data;
      const nextState = {
        ...state,
        engagementsSingle: data,
      };

      commit('SET', nextState);

      return data;
    }),
  getEngagements: ({ commit, state }, query) =>
    reportsApi.getEngagements(query).then((res) => {
      const { data } = res.data;
      const nextState = {
        ...state,
        engagements: data,
      };

      commit('SET', nextState);

      return data;
    }),
  getDeal: ({ commit, state }, query) =>
    reportsApi.getDeals(query).then((res) => {
      const data = res.data;
      const nextState = {
        ...state,
        deal: data.data,
      };

      commit('SET', nextState);

      return data;
    }),
  getDeals: ({ commit, state }, query) =>
    reportsApi.getDeals(query).then((res) => {
      const data = res.data;
      const nextState = {
        ...state,
        deals: data.data,
        dealsMeta: data.meta,
      };

      commit('SET', nextState);

      return data;
    }),
  getChartSummary: ({ commit, state }, query) =>
    reportsApi.getChartSummary(query).then((res) => {
      const { data } = res.data;
      const nextState = {
        ...state,
        chartSummary: data,
      };

      commit('SET', nextState);

      return data;
    }),
  getChartSummarySingle: ({ commit, state }, query) =>
    reportsApi.getChartSummary(query).then((res) => {
      const { data } = res.data;
      const nextState = {
        ...state,
        chartSummarySingle: data,
      };

      commit('SET', nextState);

      return data;
    }),
  getTopDeals: ({ commit, state }, query) =>
    reportsApi.getTopDeals(query).then((res) => {
      const { data } = res.data;
      const nextState = {
        ...state,
        topDeals: data,
      };

      commit('SET', nextState);

      return data;
    }),
  getStatuses: ({ commit, state }, query) =>
    reportsApi.getStatuses(query).then((res) => {
      const { data } = res.data;
      const nextState = {
        ...state,
        statuses: data,
      };

      commit('SET', nextState);

      return data;
    }),
  getSubmissionTypes: ({ commit, state }, query) =>
    reportsApi.getSubmissionTypes(query).then((res) => {
      const { data } = res.data;
      const nextState = {
        ...state,
        submissionTypes: data,
      };

      commit('SET', nextState);

      return data;
    }),

  getInvoices: ({ commit, state }, query) =>
    reportsApi.getInvoices(query).then((res) => {
      const { data, meta } = res.data;
      const nextState = {
        ...state,
        invoices: data,
      };

      commit('SET', nextState);

      return { data, meta };
    }),
  getDaily: ({ commit, state }, query) =>
    reportsApi.getDaily(query).then((res) => {
      const { data, meta } = res.data;
      const nextDaily = {
        items: data,
        meta,
      };

      const nextState = {
        ...state,
        daily: nextDaily,
      };

      commit('SET', nextState);

      return nextDaily;
    }),
  getDailyCpm: ({ commit, state }, query) =>
    reportsApi.getDailyCpm(query).then((res) => {
      const { data } = res.data;
      const nextDailyCpm = {
        items: data,
      };

      const nextState = {
        ...state,
        dailyCpm: nextDailyCpm,
      };

      commit('SET', nextState);

      return nextDailyCpm;
    }),
  getDailyAdvanced: ({ commit, state }, query) =>
    reportsApi.getDailyAdvanced(query).then((res) => {
      const { data, meta } = res.data;
      const nextAdvancedDaily = {
        items: data,
        meta,
      };

      const nextState = {
        ...state,
        advancedDaily: nextAdvancedDaily,
      };

      commit('SET', nextState);

      return nextAdvancedDaily;
    }),
  getMonthly: ({ commit, state }, query) =>
    reportsApi.getMonthly(query).then((res) => {
      const nextMonthly = res.data.data;
      const nextState = {
        ...state,
        monthly: nextMonthly,
      };

      commit('SET', nextState);

      return nextMonthly;
    }),
  getAccounts: ({ commit, state }, query) =>
    reportsApi.getAccounts(query).then((res) => {
      const nextAccounts = res.data.data;
      const nextState = {
        ...state,
        accounts: nextAccounts,
      };

      commit('SET', nextState);

      return nextAccounts;
    }),
  getContracts: ({ commit, state }, query) =>
    reportsApi.getContracts(query).then((res) => {
      const nextContracts = res.data.data;
      const nextState = {
        ...state,
        contracts: nextContracts,
      };

      commit('SET', nextState);

      return nextContracts;
    }),
  getAdvancedContracts: ({ commit, state }, query) =>
    reportsApi.getAdvancedContracts(query).then((res) => {
      const nextContracts = res.data.data;
      const nextState = {
        ...state,
        advancedContracts: nextContracts,
      };

      commit('SET', nextState);

      return nextContracts;
    }),
  downloadPerformanceReport: (context, query) =>
    reportsApi.downloadPerformanceReport(query).then((res) => {
      const content = res.headers['content-type'];
      return download(res.data, 'Performance Report.csv', content);
    }),
  downloadDaily: (context, query) =>
    reportsApi.downloadDaily(query).then((res) => {
      const content = res.headers['content-type'];
      return download(res.data, 'Daily Report.csv', content);
    }),
  downloadDailyAdvanced: (context, query) =>
    reportsApi.downloadDailyAdvanced(query).then((res) => {
      const content = res.headers['content-type'];
      return download(res.data, 'Daily Advanced Report.csv', content);
    }),
  downloadDailyCpm: (context, query) =>
    reportsApi.downloadDailyCpm(query).then((res) => {
      const content = res.headers['content-type'];
      return download(res.data, 'Daily CPM Report.csv', content);
    }),
  verifyLink: (context, query) =>
    reportsApi.verifyLink(query).then((res) => res),
  reset: ({ commit }) => commit('SET', initialState.reports),
  set: ({ commit }, settings) => {
    commit('SET', settings);
  },
};

const mutations = {
  SET(state, reports) {
    /* eslint-disable no-param-reassign */
    state.invoices = reports.invoices;
    state.daily = reports.daily;
    state.dailyCpm = reports.dailyCpm;
    state.advancedDaily = reports.advancedDaily;
    state.monthly = reports.monthly;
    state.contracts = reports.contracts;
    state.advancedContracts = reports.advancedContracts;

    state.deal = reports.deal;
    state.deals = reports.deals;
    state.dealsMeta = reports.dealsMeta;
    state.topDeals = reports.topDeals;
    state.engagements = reports.engagements;
    state.engagementsSingle = reports.engagementsSingle;
    state.chartSummary = reports.chartSummary;
    state.chartSummarySingle = reports.chartSummarySingle;
    state.statuses = reports.statuses;
    state.submissionTypes = reports.submissionTypes;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
