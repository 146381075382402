<template>
  <div data-component-group="layout" data-component="auth-frame">
    <layout-auth-header />

    <v-layout class="view-wrapper normal">
      <section class="main-content">
        <slot name="content" />
      </section>
    </v-layout>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss">
[data-component-group='layout'] {
  &[data-component='auth-frame'] {
    .view-wrapper {
      main {
        width: 100%;
      }
    }

    .main-content {
      margin: 0 auto;
    }

    .trigger.go-to-home {
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
