export default [
  {
    path: '/internal-users',
    name: 'internal-users',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      // TODO new permissions
      permissions: [
        {
          subject: 'user',
          actions: ['view-nav-link', 'view'],
        },
      ],
    },
    // route level code-splitting
    component: () => import( '../../views/internalUsers/Index.vue'),
  },
  // {
  //   path: '/users/:id',
  //   name: 'user-details',
  //   meta: {
  //     loadState: true,
  //     auth: true,
  //     isAuthPage: false,
  //     permissions: [
  //       {
  //         subject: 'user',
  //         actions: ['view'],
  //       },
  //     ],
  //   },
  //   // route level code-splitting
  //   component: () =>
  //     import( '../../views/users/Single'),
  // },
  // {
  //   path: '/users/:id/settings',
  //   name: 'user-settings',
  //   meta: {
  //     loadState: true,
  //     auth: true,
  //     isAuthPage: false,
  //     permissions: [
  //       {
  //         subject: 'user',
  //         actions: ['see-profile-settings'],
  //       },
  //     ],
  //   },
  //   // route level code-splitting
  //   component: () =>
  //     import(
  //       '../../views/users/Settings'
  //       ),
  // },
  // {
  //   path: '/users/:id/invite',
  //   name: 'invite-users',
  //   meta: {
  //     loadState: true,
  //     auth: true,
  //     isAuthPage: false,
  //     permissions: [
  //       {
  //         subject: 'user',
  //         actions: ['invite'],
  //       },
  //     ],
  //   },
  //   // route level code-splitting
  //   component: () =>
  //     import( '../../views/users/Invite'),
  // },
];
