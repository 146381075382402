import axios from 'axios';

// native navigator.sendBeacon with axios fallback if size limit is exceeded
// eslint-disable-next-line import/prefer-default-export
export default function sendBeacon(url) {
  const axiosInstance = axios.create();
  axiosInstance.defaults.withCredentials = false;
  axiosInstance.defaults.headers.common = [];

  // navigator.sendBeacon returns false if size limit is exceeded (sum of all queued beacon requests)
  return axiosInstance.get(url);
};
