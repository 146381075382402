/**
 * @responsibility
 * provide consistent payload values for different tracker services
 *
 * Many decisions are motivated by need to reduce request payload size (redundant chars, encode bloat).
 */

import mapValues from 'lodash/mapValues';
import { v4 as uuidv4 } from 'uuid';
import { assignPrefixed } from '../utils/object';
import { isNull, isStr, isUndef } from '../utils';

export const TrackContext = {
  name: '',
  data: null
}

export const TrackService = {
  Ocular: 'ocular',
  GA: 'ga',
}

/**
 * TrackAction contains all required information to generate a track request
 */
export const TrackAction = {
  type: '', // event type that triggered the action, e.g. 'click'
  target: TrackContext, // context where action originated
  context: [], // parent contexts of target (outermost first)
  interaction: false, // true iff user actively triggered the action as sign of interest (affects bounce rate)
  services: [], // where the request will be sent to
  constructor() {}
}

/* eslint-disable no-param-reassign */

// e.g. 'view_thread'
// _${action.target.name}
export const createActionName = (action) => `${action.type}`;

// e.g. 'top.hottestThreads'
export const stringifyContextPath = (contextPath) =>
  contextPath.map(parent => parent.name).join('.');

export function assignActionData(target, action) {
  // action.context.forEach(context => assignContextData(target, context));
  //
  const {data} = action.target;
  const dataKeys = Object.keys(data);

  if (!isUndef(dataKeys)) {
    dataKeys.forEach(key => {
      action[key] = data[key];
    });
  }

  if (isUndef(action.unitName)) {
    target.unit_name = ''
  } else {
    const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    target.unit_name = camelToSnakeCase(action.unitName)
  }

  if (isUndef(action.unitType)) {
    target.unit_type = ''
  } else {
    target.unit_type = action.unitType
  }

  if (isUndef(action.unitId)) {
    target.unit_id = ''
  } else {
    target.unit_id = action.unitId
  }

  if (isUndef(action.dataLength) || !isStr(action.dataLength)) {
    target.data_length = ''
  } else {
    target.data_length = action.dataLength.length
  }

  assignUuidField(target);

  return target;
}

function assignUuidField (target) {
  let uuidSession = sessionStorage.getItem('uuidSession');
  if (!isNull(uuidSession)) {
    target.uuid = uuidSession;
  }
  else {
    uuidSession = uuidv4();
    sessionStorage.setItem('uuidSession', uuidSession)
    target.uuid = uuidSession;
  }
}

// adds data from all contexts with prefixed keys e.g. { 'hottestThreads.mode': 'horizontal', 'thread.id': '5' }
export function assignContextData(target, context) {
  return assignPrefixed(target, booleanToNumber(context.data), `${context.name}.`);
}

function booleanToNumber(data) {
  return mapValues(data, value => (typeof value === 'boolean' ? Number(value) : value));
}
