import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiUrl } from './index';

export default {
  // Businesses START
  getBusiness(id) {
    return axios({
      method: 'get',
      url: `${apiUrl}/loyalty_program/businesses/${id}`,
    });
  },
  getBusinesses(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/loyalty_program/businesses`,
      params,
    });
  },
  createBusiness(data) {
    return axios({
      method: 'post',
      url: `${apiUrl}/loyalty_program/businesses`,
      data,
    });
  },
  updateBusiness(data) {
    return axios({
      method: 'put',
      url: `${apiUrl}/loyalty_program/businesses/${data.id}`,
      data,
    });
  },
  // Businesses END

  // Billing Accounts START
  getBillingAccount(id) {
    return axios({
      method: 'get',
      url: `${apiUrl}/loyalty_program/billings/${id}`,
    });
  },
  getBillingAccounts(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/loyalty_program/billings`,
      params,
    });
  },
  createBillingAccount(data) {
    return axios({
      method: 'post',
      url: `${apiUrl}/loyalty_program/billings`,
      data,
    });
  },
  updateBillingAccount(data) {
    return axios({
      method: 'put',
      url: `${apiUrl}/loyalty_program/billings/${data.id}`,
      data,
    });
  },
  // Billing Accounts END

  // Invoices START
  getInvoice(id) {
    return axios({
      method: 'get',
      url: `${apiUrl}/loyalty_program/invoices/${id}`,
    });
  },
  getInvoices(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/loyalty_program/invoices`,
      params,
    });
  },
  downloadInvoice({ id }) {
    return axios({
      method: 'get',
      responseType: 'blob',
      url: `${apiUrl}/loyalty_program/invoices/${id}/download`,
    });
  },
  // Invoices END
};
