/**
 * @responsibility
 * add capability for capturing DOM events and triggering track actions to track contexts
 */

// eslint-disable-next-line max-classes-per-file
import {
  getTrackContext,
  setTrackContext,
  removeTrackContext,
  getTrackContextPath,
  // TrackContextElement,
} from './context';
import { observeView, unobserveView } from './inview';
import {
  // TrackContext,
  TrackAction,
  TrackService
} from './payload';

const isProduction = process.env.NODE_ENV === 'production';

// supported DOM events (modifications here require also update in bookmarklet styles)
export const TRACK_EVENTS = ['view', 'click', 'focus', 'change', 'submit', 'blur'];

export const TrackEventType = '';

export const ElemTrackAction = TrackAction;

export const TrackEvent = Event;

// for track targets we concat event type & track service into a combined type (e.g. 'click-ga')
// but the default service (ocular) is omitted (e.g. 'click' instead of 'click-ocular')
export const EventTypeWithService = '';

const eventAttributes = TRACK_EVENTS.map(getTrackTargetAttributeName);

/**
 * Initialises the tracking. The given callback will be called everytime a track action is triggered by a track target.
 */
export function observeTrackTargets(callback) {
  function handler(event) {
    const trackTarget = findTrackTarget(event);

    if (!trackTarget) return; // either no track target exists in the event path, or the event type doesn't match

    callback(getTrackAction(event.type, trackTarget));
  }

  TRACK_EVENTS.forEach(eventType => {
    // use capturing phase to prevent issues with missing parent context when tracked elements are removed in the event target phase (e.g. close modal button)
    document.addEventListener(eventType, handler, true);
  });

  // Safari dispatches middle mouse click not as auxclick, but as click. Here we equalise that across all browsers, which benefits link click tracking.
  document.addEventListener(
    'auxclick',
    event => {
      if (event.button !== 1) return;
      handler({ type: 'click', target: event.target });
    },
    true,
  );
}

/**
 * Marks given elem as track target. The target will trigger a track action when it captures one of the given events.
 */
export function addTrackTarget(elem, events, name, data) {
  events.forEach(eventType => addTargetEvent(elem, eventType));
  setTrackContext(elem, name, data);
}

/**
 * Removes track target from given elem.
 */
export function removeTrackTarget(elem) {
  removeTrackContext(elem);
  eventAttributes.forEach(attribute => elem.removeAttribute(attribute));
  unobserveView(elem);
}

/**
 * Returns the closest element with a track context that has the given event type, otherwise null
 */
export function findTrackTarget(event) {
  // exclude window & document, but include html & svg elements
  if (!(event.target instanceof Element)) return null;

  return event.target.closest(`[${getTrackTargetAttributeName(event.type)}]`);
}

/**
 * Returns a new track action for given track context & event type.
 */
export function getTrackAction(type, elem) {
  return {
    type,
    target: getTrackContext(elem),
    context: getTrackContextPath(elem.parentElement),
    interaction: type !== 'view',
    services: getTargetEventServices(type, elem),
  };
}

// must only be used to add events initially, update of existing events will fail if not preceded by removeTrackTarget
function addTargetEvent(elem, eventType) {
  const [type, service = ''] = eventType.split('-');

  validateEventType(type, service);

  // click with ocular (default): data-t-click=""
  // click with google analytics: data-t-click="ga"
  // click with ocular & google analytics: data-t-click="ocular,ga"
  const attributeName = getTrackTargetAttributeName(type);
  const prevServices = elem.getAttribute(attributeName);
  const services =
    prevServices === null ? service : `${prevServices || TrackService.Ocular},${service || TrackService.Ocular}`;
  elem.setAttribute(attributeName, services);

  // observe custom events
  if (type === 'view') {
    observeView(elem);
  }
}

function getTargetEventServices(type, elem) {
  return (elem.getAttribute(getTrackTargetAttributeName(type)) || TrackService.Ocular).split(',')
}

export function getTrackTargetAttributeName(eventType) {
  return `data-t-${eventType}`;
}

function validateEventType(type, service) {
  // avoid validation overhead in production
  if (isProduction) return;

  if (!TRACK_EVENTS.includes(type)) {
    throw Error(`Invalid track event: ${type}, please use: ${TRACK_EVENTS.join('|')}`);
  }
  if (service === TrackService.Ocular) {
    throw Error(`Invalid track modifier: ${type}-ocular, please omit default service '-ocular'`);
  }
  if (service && !Object.values(TrackService).includes(service)) {
    throw Error(`Invalid track service: ${service}}`);
  }
  if (type === 'view' && service === TrackService.GA) {
    throw Error('The \'view\' track event is not allowed for google analytics due to quota limits, please use ocular');
  }
}
