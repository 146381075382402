/* eslint-disable */

/**
 * @responsibility
 * send track events to ocular
 */
import { createActionName, assignActionData } from './payload';
import sendBeacon from './util';

import store from '../store';

const endpoint = import.meta.env.VUE_APP_OCULAR_TRACKING_URL;

const OcularEventData = {
  event: '',
  context: '',
}

const OcularPageData = {
  url: '',
  route: '',
  uuid: '',
  userId: '',
  ab: '',
  personalized: '',
  md5Session: '',
}

export const OcularQuery = OcularEventData & OcularPageData;

// TODO: BE ocularThreadImpressionDefaults should be replaced by a blacklist to allow to disable certain events (e.g. view_thread)
const enabled = function () { return !!store.default.state.application.ocularThreadImpressionDefaults; };

const createOcularPageData = () => {
  // TODO: BE should expose event defaults independently
  const pixelParams = {};

  const data = {
    url: location.href,
  };

  const userId = store.state.auth.user.id;

  // user can opt out of tracking
  if (userId) {
    data.user_id = userId;
  }
  if (pixelParams.is_personalized === '1') {
    data.personalized = '1';
  }
  return data;
};

function createOcularEventData(trackAction) {
  let context = trackAction.target.name;
  const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `.${letter.toLowerCase()}`);

  const data = {
    event: trackAction.target.data.event,
    unit_action: createActionName(trackAction),
    context: camelToSnakeCase(context),
  };

  assignActionData(data, trackAction);

  return data;
}

export function createOcularQuery(trackAction) {
  return Object.assign(createOcularEventData(trackAction), createOcularPageData());
}

export function trackOcular(trackAction) {
  // if (!enabled())
  //   return false;
  // console.log(createOcularQuery(trackAction));
  sendBeacon(`${endpoint}?${new URLSearchParams(createOcularQuery(trackAction))}`);
  return true;
}
