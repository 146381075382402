import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      order = 'asc',
      sort = '',
      search = '',
      role = '',
      user_type = 2,
      businessId = -1,
      pending = 0,
      status = ''
    } = options;

    const params = {
      page,
      order,
      sort,
      search,
      role,
      user_type,
      pending,
      status
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (businessId > -1) {
      params.business = businessId;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/users`,
      params,
    });
  },
  getUsersCount() {
    return axios({
      method: 'get',
      url: `${apiUrl}/users/external/count`,
    });
  },
  getInternalItems(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      order = 'asc',
      sort = '',
      search = '',
      role = '',
      user_type = 1,
      businessId = -1,
      pending = 0,
      status = '',
      countries
    } = options;

    const params = {
      allowed_countries: countries,
      page,
      order,
      sort,
      search,
      role,
      user_type,
      pending,
      status
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (businessId > -1) {
      params.business = businessId;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/users`,
      params,
    });
  },
  createUser(user) {
    return axios({
      method: 'post',
      url: `${apiUrl}/users`,
      data: {
        ...user,
      },
    });
  },
  updateUser(user) {
    return axios({
      method: 'put',
      url: `${apiUrl}/users/${user.id}`,
      data: {
        ...user,
      },
    });
  },
  updateUserOptions(user) {
    return axios({
      method: 'post',
      url: `${apiUrl}/users/${user.id}/options`,
      data: {
        ...user.options,
      },
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/users/${id}`,
    });
  },
  getRoles(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/users/roles`,
      params
    });
  },
  getUsersRolesByCountry(params) {
    return axios({
      method: 'get',
      url: `${apiUrl}/users/search`,
      params
    });
  },
  getUserCountries() {
    return axios({
      method: 'get',
      url: `${apiUrl}/users/countries`,
    });
  },
  getUserStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/users/statuses`,
    });
  },
  getActivityLogs({ page = 1, perPage = maxItemsPerPage, id }) {
    const params = {
      page,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/users/${id}/activities`,
      params,
    });
  },
  getSettings({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/users/${id}/settings`,
    });
  },
  updateSettings({ id, settings }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/users/${id}/settings`,
      data: {
        ...settings,
      },
    });
  },
  getNotifications({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/users/${id}/notifications`,
    });
  },
  addNotification({ id, ...rest }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/users/${id}/notifications`,
      data: {
        ...rest,
      },
    });
  },
  removeNotification({ id, country_notify }) {
    return axios({
      method: 'delete',
      url: `${apiUrl}/users/${id}/notifications/${country_notify}`,
    });
  },
  getNotificationAccountTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/users/notifications/account_types`,
    });
  },
  getAssignmentTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/users/notifications/assignments`,
    });
  },
  logIntoAccount({ id }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/users/${id}/login`,
    });
  },
  updateUserStatus({ id, status, reason }) {
    const data = {
      status,
      reason,
    };

    return axios({
      method: 'patch',
      url: `${apiUrl}/users/${id}/status`,
      data,
    });
  },
  getManagers({
    page = 1,
    perPage = -1,
    search = '',
    sort = '',
    order = 'asc',
  } = {}) {
    const params = {
      page,
      sort,
      order,
      search,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/users/managers`,
      params,
    });
  },
  getAssignableUsers({ target_country, page = 1, perPage = -1, search } = {}) {
    const params = {
      target_country,
      page,
      search,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/users/assignable`,
      params,
    });
  },
  getSubRoles() {
    return axios({
      method: 'get',
      url: `${apiUrl}/business_users/roles`,
    });
  },
};
