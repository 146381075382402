import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import router from '../router';

const isProdEnv = process.env.NODE_ENV === 'production';

if (isProdEnv) {
  const analyticsId = import.meta.env.VUE_APP_GOOGLE_ANALYTICS_ID;

  Vue.use(VueAnalytics, {
    id: analyticsId,
    router,
  });
}
