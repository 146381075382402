// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
// eslint-disable-next-line import/no-cycle
import store from '..';
import { isObj, isArr } from '../../utils';
import initialState from '../initialState';

const popupsApi = apiClient.merchy.popups;

/**
 * @description Is valid
 * @param popups
 * @returns {boolean}
 */
const isValid = (popups) =>
  isObj(popups) &&
  isArr(popups.items) &&
  isObj(popups.itemsMeta) &&
  isArr(popups.presentableItems) &&
  isObj(popups.visiblePopup);

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial popups state');
  }

  const { items, itemsMeta, presentableItems, visiblePopup } = initialState;
  return {
    items,
    itemsMeta,
    presentableItems,
    visiblePopup,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  presentableItems: ({ presentableItems }) => presentableItems,
  visiblePopup: ({ visiblePopup }) => visiblePopup,
};

const actions = {
  getItems: ({ commit, state }, query) =>
    popupsApi.getItems(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = {
        items: data,
        itemsMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);

      return nextItems;
    }),
  setActiveItem: ({ commit, state }, popup) => {
    const nextState = {
      ...state,
      activeItem: {
        ...state.activeItem,
        ...popup,
      },
    };

    return commit('SET', nextState);
  },
  getPresentableItems: ({ commit, state }) =>
    popupsApi.getPresentableItems().then((res) => {
      const nextPresentableItems = res.data.data;

      const nextState = {
        ...state,
        presentableItems: nextPresentableItems,
      };

      return commit('SET', nextState);
    }),
  setVisiblePopup: ({ commit, state }, popup) => {
    const { presentableItems } = state;
    const nextVisiblePopup = presentableItems.find(
      ({ type }) => type.name === popup.name,
    );

    const nextState = {
      ...state,
      visiblePopup: {
        ...state.visiblePopup,
        ...nextVisiblePopup,
      },
    };

    return commit('SET', nextState);
  },
  hidePopup: ({ commit, state }, popup) => {
    const nextState = {
      ...state,
      visiblePopup: {},
    };

    if (popup.dismiss) {
      store.dispatch('popups/setDismissed', popup);
    }

    return commit('SET', nextState);
  },
  setPopupFormData: ({ commit, state }, query) =>
    popupsApi.setPopupFormData(query).then(() => {
      const { presentableItems } = state;
      const nextPresentableItems = presentableItems.filter(
        (item) => item.id !== query.id,
      );

      const nextState = {
        ...state,
        presentableItems: nextPresentableItems,
        visiblePopup: {},
      };

      return commit('SET', nextState);
    }),
  setDismissed: ({ commit, state }, popup) =>
    popupsApi.setDismissed(popup).then(() => {
      const { presentableItems } = state;
      const nextPresentableItems = presentableItems.filter(
        (item) => item.id !== popup.id,
      );

      const nextState = {
        ...state,
        presentableItems: nextPresentableItems,
      };

      return commit('SET', nextState);
    }),
  reset: ({ commit }) => commit('SET', initialState.popups),
  set: ({ commit }, popups) => {
    commit('SET', popups);
  },
};

const mutations = {
  SET(state, popups) {
    /* eslint-disable no-param-reassign */
    state.items = popups.items;
    state.itemsMeta = popups.itemsMeta;
    state.presentableItems = popups.presentableItems;
    state.visiblePopup = popups.visiblePopup;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
