import Vue from 'vue';
import isPlainObject from 'lodash/isPlainObject';
import { Wormhole, PortalTarget } from 'portal-vue';

/**
 * merges objects recursively into observed vue state via Vue.set; arrays are replaced, not merged
 */
export const mergeIntoObservable = (state, value = null, propName = null) => {
  if (isPlainObject(value) && (propName === null || state.hasOwnProperty(propName))) {
    const srcValue = propName == null ? state : state[propName];
    if (srcValue !== undefined && srcValue !== null) {
      for (const [subProp, subValue] of Object.entries(value)) {
        mergeIntoObservable(srcValue, subValue, subProp);
      }
      return;
    }
  }
  if (value !== null) {
    Vue.set(state, propName, value);
  }
};

/**
 * Returns portal source component for the portal target on the given DOM elem
 */
export function getPortalSource(elem: Element, test?: (vm: Vue) => boolean): Vue | null {
  // @ts-ignore
  const vm: PortalTarget = findElementVm(elem, vm => vm.ownTransports && (!test || test(vm)));
  const fromId = vm?.ownTransports[0]?.from;
  return fromId ? Wormhole.sources[fromId][0] : null;
}

/**
 * Multiple vue components (renderless) can reference the same DOM element. This function returns the first vue instance
 * of the given elem, that passes the given test callback.
 */
function findElementVm(elem: Element, test: (vm: Vue) => boolean): Vue | null {
  // elem.__vue__ references the outermost vm
  // renderless components usually only accept slot content with single root, so for simplicity only the first child will be checked
  // @ts-ignore
  for (let vm = elem?.__vue__; vm && vm.$el === elem; vm = vm.$children[0]) {
    if (test(vm)) return vm;
  }
  return null;
}
