import axios from 'axios';
import _ from 'lodash';
import store from '../store';
import { getToken } from '../api/merchy';
import { isStr, isObj } from '../utils';

const isTestMode = process.env.NODE_ENV === 'test';
const maxParallelRequests = import.meta.env.VUE_APP_API_MAX_PARALLEL_REQUESTS;
const postponedRequestsDelay = 500; // ms
let refreshSubscribers = [];
let parallelRequests = 0;
let requestQueue = [];
let isFetchingAccessToken = false;

/**
 * @description Postpone request
 * @param url
 * @param method
 * @param params
 * @param callback
 */
const postponeRequest = (url, method, params, callback) => {
  const isUniqueRequest =
    refreshSubscribers.filter(
      (subscriber) =>
        subscriber.url === url &&
        _.isEqual(subscriber.params, params) &&
        subscriber.method === method,
    ).length === 0;

  if (isUniqueRequest) {
    refreshSubscribers.push({ url, method, callback });
  }
};

/**
 * @description Call postponed requests
 */
const callPostponedRequests = () => {
  refreshSubscribers.forEach(({ callback }) => callback());
  refreshSubscribers = [];
};

/**
 * @description Replace request token
 * @param request
 * @returns {*}
 */
const replaceRequestToken = (request) => {
  const { headers } = request;

  const nextToken = getToken();

  headers.Authorization = `Bearer ${nextToken}`;

  return {
    ...request,
    headers,
  };
};

/**
 * @description Transform postponed request
 * @param request
 * @param resolve
 */
const transformPostponedRequest = (request, resolve) => {
  const { url, method, params } = request;

  postponeRequest(url, method, params, () => {
    const nextRequest = replaceRequestToken(request);
    resolve(axios(nextRequest));
  });
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') =>
  store.dispatch('alerts/set', {
    data,
    alertType,
  });

/**
 * @description Add to request queue
 * @param config
 * @param resolve
 */
const addToRequestQueue = (config, resolve) => {
  requestQueue.push(() => resolve(config));
};

/**
 * @description Call postponed requests
 */
const callRequestQueue = () => {
  if (requestQueue.length > 0) {
    const [firstInQueue, ...remainingRequests] = requestQueue;
    firstInQueue();
    requestQueue = remainingRequests;
  }

  if (parallelRequests > 0) {
    parallelRequests -= 1;
  }
};

/**
 * @description Headers
 * @param key
 * @param value
 * @param valueKey
 */
const headers = (key, value, valueKey) => {
  if (!value || (typeof valueKey !== 'undefined' && !value[valueKey])) {
    return;
  }

  let header = value;

  if (valueKey) {
    header = value[valueKey];
  }

  axios.defaults.headers.common[key] = header;
};

/**
 * @description Set CSRF token
 * @param resHeaders
 */
const setCsrfToken = (resHeaders) => {
  const csrfToken = resHeaders['x-csrf-token'];

  if (isStr(csrfToken)) {
    headers('X-CSRF-Token', csrfToken);
  }
};

/**
 * @description Attach CSRF token
 * @param config
 * @param csrfToken
 * @returns {FormData|string}
 */
const attachCsrfToken = (config, csrfToken) => {
  let requestData = config.data;

  if (isStr(requestData)) {
    // json
    const parsedData = JSON.parse(config.data);
    parsedData['X-CSRF-Token'] = csrfToken;
    requestData = JSON.stringify(parsedData);
  } else if (requestData instanceof FormData) {
    // form data
    requestData.append('X-CSRF-Token', csrfToken);
  } else if (isObj(requestData)) {
    // object
    requestData['X-CSRF-Token'] = csrfToken;
  }

  return requestData;
};

/**
 * @description Request interceptor
 * @param config
 * @returns {*}
 */
const requestInterceptor = (config) => {
  const augmentedConfig = { ...config };

  if (config.method !== 'get') {
    const csrfToken = axios.defaults.headers.common['X-CSRF-Token'];
    augmentedConfig.data = attachCsrfToken(config, csrfToken);
  }

  const nextConfig = replaceRequestToken(augmentedConfig);

  if (parallelRequests < maxParallelRequests) {
    parallelRequests += 1;
    return nextConfig;
  }

  // add request to request queue
  // eslint-disable-next-line
  return new Promise((resolve) => addToRequestQueue(nextConfig, resolve));
};

/**
 * @description Response success handler
 * @param res
 * @returns {*}
 */
const responseSuccessHandler = (res) => {
  // TODO: Errors should not fall through the axios global error handler to the success handler. Investigate and implement a better solution
  // TODO: Maybe we need to send a bugsnag report here?
  // handle errors that leak through
  if (res.data.status === 'error') {
    return responseErrorHandler({
      response: res,
      config: res.config,
    });
  }

  callRequestQueue();
  setCsrfToken(res.headers);

  return res;
};

/**
 * @description Standardize error obj
 * @param res
 * @returns {{statusText}|*}
 */
const standardizeErrorObj = (res) => {
  res.data.message = res.data.message || res.statusText;
  res.data.data = res.data.data || {};
  res.data.data.message = res.data.data.message || res.data.message;

  return res;
};

/**
 * @description Is unauthenticated error
 * @param res
 * @returns {boolean}
 */
// TODO: Consider using a more strict evaluation, not relying on string comparison
const isUnauthenticatedErr = (res) =>
  res.status === 401 &&
  (res.data.data.message === 'Unauthenticated.' ||
    res.data.data.message === 'Unauthorized');

/**
 * @description Response error handler
 * @param err
 * @returns {*}
 */
const responseErrorHandler = (err) => {
  const isLogged = store.getters['auth/isLogged'];
  callRequestQueue();

  // handle generic error
  if (!isObj(err.response) || !isObj(err.response.data)) {
    handleAlerts({
        message: 'Network error. Please try again.',
    }).catch((err) => err);

    return Promise.reject(err);
  }

  // standardize response
  const res = standardizeErrorObj(err.response);

  if (isTestMode) {
    return Promise.reject(err);
  }

  // handle download reports error
  // blob response overwrites the configuration message with "Internal Server Error"
  // so we return a hardcoded error message for such response type
  if (res.request.responseType === 'blob') {
    const errorMessage =
      'The report you are trying to download is too big. Please use the filtering option and select a shorter time range.';
    res.data.message = errorMessage;
    res.data.data.message = errorMessage;
  }

  // handle authenticated/authorized/public error
  if (!isUnauthenticatedErr(res)) {
    handleAlerts({
      data: res.data,
      params: res.config.params || res.config.data,
    }).catch((err) => err);
    return Promise.reject(err);
  }

  // handle unauthenticated/unauthorized error
  if (!isFetchingAccessToken && isLogged) {
    isFetchingAccessToken = true;

    // attempt to refresh token
    const query = {
      refresh_token: getToken('refresh_token'),
    };
    store
      .dispatch('auth/refreshToken', query)
      .then(() =>
        // delay calling postponed requests, to give the server some time to refresh the token
        setTimeout(() => {
          isFetchingAccessToken = false;
          callPostponedRequests();
        }, postponedRequestsDelay),
      )
      .catch(() => store.dispatch('auth/redirectInvalidToken'));
  }

  // postpone all requests while refreshing token
  return new Promise((resolve) =>
    // eslint-disable-next-line
    transformPostponedRequest(err.config, resolve),
  );
};

// use custom interceptor
axios.interceptors.request.use(requestInterceptor);

// use custom response handlers
axios.interceptors.response.use(responseSuccessHandler, responseErrorHandler);
