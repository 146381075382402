import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const billingApi = apiClient.merchy.billing;

/**
 * @description Is valid
 * @param billing
 * @returns {boolean}
 */
const isValid = (billing) => {
  const { activeItem, items, itemsMeta, conflicts, conflictsMeta } = billing;

  const isValidActiveItem = isObj(activeItem)
    && isObj(activeItem.log)
    && isArr(activeItem.log.items)
    && isObj(activeItem.log.meta);

  return isObj(billing)
    && isArr(items)
    && isObj(itemsMeta)
    && isArr(conflicts)
    && isObj(conflictsMeta)
    && isValidActiveItem;
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = initialState => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial billing state');
  }

  const { activeItem, items, itemsMeta, conflicts, conflictsMeta } = initialState;
  return {
    activeItem,
    items,
    itemsMeta,
    conflicts,
    conflictsMeta
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  activeItem: ({ activeItem }) => activeItem,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
  conflicts: ({ conflicts }) => conflicts,
  conflictsMeta: ({ conflictsMeta }) => conflictsMeta
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') => (
  store.dispatch('alerts/set', {
    data,
    alertType
  })
);

const actions = {
  create: (context, query) => (
    billingApi.createItem(query)
      .then(res => {
        handleAlerts({
          data: {
            message: 'Billing account created successfully.'
          }
        }, 'success');
        return res;
      })
  ),
  update: ({ commit, state }, query) => (
    billingApi.updateItem(query)
      .then((res) => {
        const { activeItem } = state;
        const nextItem = res.data.data;

        const nextState = {
          ...state,
          activeItem: {
            ...activeItem,
            ...nextItem
          }
        };

        commit('SET', nextState);

        handleAlerts({
          data: {
            message: 'Billing account updated successfully.'
          }
        }, 'success');
        return res;
      })
  ),
  getItem: ({ commit, state }, query) => (
    billingApi.getItem(query).then((res) => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...res.data.data
        }
      };

      return commit('SET', nextState);
    })
  ),
  getItems: ({ commit, state }, query) => (
    billingApi.getItems(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = {
        items: data,
        itemsMeta: meta
      };

      const nextState = {
        ...state,
        ...nextItems
      };

      commit('SET', nextState);

      return nextItems;
    })
  ),
  getConflicts: ({ commit, state }, query) => (
    billingApi.getItems({ ...query, conflicts: 1 }).then((res) => {
      const { data, meta } = res.data;

      const nextState = {
        ...state,
        conflicts: data,
        conflictsMeta: meta
      };

      return commit('SET', nextState);
    })
  ),
  getStatuses: ({ commit, state }) => (
    billingApi.getStatuses().then((res) => {
      const nextStatuses = _.sortBy(res.data.data, status => status.name.toLowerCase());
      const nextState = {
        ...state,
        statuses: nextStatuses
      };

      return commit('SET', nextState);
    })
  ),
  setActiveItem: ({ commit, state }, nextActiveItem) => {
    const nextState = {
      ...state,
      activeItem: {
        ...state.activeItem,
        ...nextActiveItem
      }
    };

    return commit('SET', nextState);
  },
  getActivityLogs: ({ commit, state }, query) => (
    billingApi.getActivityLogs(query).then((res) => {
      const { activeItem } = state;
      const { data, meta } = res.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          log: {
            items: data,
            meta
          }
        }
      };

      return commit('SET', nextState);
    })
  ),
  getInvoiceEmails: ({ commit, state }, query) => (
    billingApi.getInvoiceEmails(query).then((res) => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          invoice_emails: res.data.data
        }
      };

      return commit('SET', nextState);
    })
  ),
  updateInvoiceEmails: (context, query) => (
    billingApi.updateInvoiceEmails(query).then(() => (
      handleAlerts({
        data: {
          message: 'Invoice emails have been updated successfully.'
        }
      }, 'success')
    ))
  ),
  updateOwners: (context, query) => (
    billingApi.updateOwners(query).then(() => (
      handleAlerts({
        data: {
          message: 'Owners have been updated successfully.'
        }
      }, 'success')
    ))
  ),
  uploadCSV: (context, query) => (
    billingApi.uploadCSV(query).then(() => (
      handleAlerts({
        data: {
          message: 'CSV file uploaded successfully.'
        }
      }, 'success')
    ))
  ),
  vatCheck: (context, query) => (
    billingApi.vatCheck(query)
      .then(res => res)
      .catch(err => err)
  ),
  reset: ({ commit }) => (
    commit('SET', initialState.billing)
  ),
  set: ({ commit }, billing) => {
    commit('SET', billing);
  }
};

const mutations = {
  SET(state, billing) {
    /* eslint-disable no-param-reassign */
    state.activeItem = billing.activeItem;
    state.items = billing.items;
    state.itemsMeta = billing.itemsMeta;
    state.statuses = billing.statuses;
    state.conflicts = billing.conflicts;
    state.conflictsMeta = billing.conflictsMeta;
  }
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
