/**
 * @responsibility
 * allows to mark vue dom element as track context or track target
 */

import Vue from 'vue';
import { setTrackContext, hasTrackContext } from './context';
import { addTrackTarget, removeTrackTarget } from './target';

/**
 * @example track context
 * <div v-track:sideBar>
 *
 * @example track context with data
 * <div v-track:thread="{id: 1}">
 *
 * @example click target for ocular
 * <button v-track:threadLink.click>
 *
 * @example click target for Google Analytics & view target for ocular
 * <input v-track:threadLink.click-ga.view>
 *
 * @example dynamic context name
 * <input v-track:[contextName]>
 */

/**
 * Adds track context name, data & events for given elem.
 * Previously added name & data & events will be removed before.
 */
function bind(element, { arg: name, value: data, modifiers: eventMap }) {
  // v-track can be applied multiple times on the same element (from within different vue components)
  // here we make sure that the last added directive (outermost) will get precedence and previous are removed
  if (hasTrackContext(element)) {
    removeTrackTarget(element);
    targetEventCache.delete(element);
  }

  const events = Object.keys(eventMap);

  if (events.length) {
    addTrackTarget(element, events, name, data);
    targetEventCache.set(element, events.join(','));
  } else {
    setTrackContext(element, name, data);
  }
}

/**
 * Updates track context name & data.
 */
function update(element, binding) {
  // ignore v-track with different events from other vue contexts (event modifiers never change)
  const events = Object.keys(binding.modifiers).join(',');
  const prevEvents = targetEventCache.get(element) || '';
  if (prevEvents !== events) return;

  const { arg: name, value: data } = binding;
  setTrackContext(element, name, data);
}

function unbind(element) {
  removeTrackTarget(element);
}

const targetEventCache = new WeakMap(); // optimise for frequent directive updates

if(process.env.NODE_ENV !== 'test') {
  Vue.directive('track', { bind, update, unbind });
}
