/**
 * @responsibility
 * allow to intercept values via added callbacks (hooks)
 *
 * @description
 * - value will be updated with callback return
 * - value won't be touched if callback returns undefined
 * - all following interceptions are ignored if callback returns null
 *
 * @example
 * interceptor = new Interceptor();
 *
 * interceptor.add(value => value === 'a' ? null : undefined);
 * interceptor.add(value => value === value.startsWith('a') ? value.toUpperCase() : undefined);
 *
 * interceptor.intercept('a') -> 'null'
 * interceptor.intercept('aa') -> 'AA'
 * interceptor.intercept('b') -> 'b'
 */

// export type InterceptorCb = (value) => InterceptorResult | Promise;

// type InterceptorResult = T | null | undefined;

// eslint-disable-next-line import/prefer-default-export
export class Interceptor {
  constructor() {
    this.interceptors = [];
  }

  add(interceptor) {
    this.interceptors.push(interceptor);
    return this;
  }

  async intercept(value) {
    this.interceptors.forEach(async (interceptor) => {
      const nextValue = await interceptor(value);

      if (nextValue === undefined) return; // keep value

      // eslint-disable-next-line no-param-reassign
      value = nextValue;

      // if (value === null) return; // ignore all following interceptions
    })

    return value;
  }
}
