// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const packagesApi = apiClient.merchy.packages;

/**
 * @description Is valid
 * @param packages
 * @returns {boolean}
 */
const isValid = (packages) => {
  const { activeItem, items, itemsMeta, queueTypes } = packages;

  return (
    isObj(packages) &&
    isObj(activeItem) &&
    isArr(items) &&
    isObj(itemsMeta) &&
    isArr(queueTypes)
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial packages state');
  }

  const { activeItem, items, itemsMeta, queueTypes } = initialState;
  return {
    activeItem,
    items,
    itemsMeta,
    queueTypes,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  activeItem: ({ activeItem }) => activeItem,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
  queueTypes: ({ queueTypes }) => queueTypes,
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') =>
  store.dispatch('alerts/set', {
    data,
    alertType,
  });

const actions = {
  create: (context, packageItem) =>
    packagesApi.createItem(packageItem).then((res) => {
      const { name } = res.data.data;

      return handleAlerts(
        {
          data: {
            message: `Package "${name}" created successfully.`,
          },
        },
        'success',
      );
    }),
  getItem: ({ commit, state }, query) =>
    packagesApi.getItem(query).then((res) => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...res.data.data,
        },
      };

      return commit('SET', nextState);
    }),
  update: ({ commit, state }, packageItem) =>
    packagesApi.updateItem(packageItem).then((res) => {
      const { activeItem } = state;
      const nextPackage = res.data.data;
      const { name } = nextPackage;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextPackage,
        },
      };

      commit('SET', nextState);

      return handleAlerts(
        {
          data: {
            message: `Package "${name}" has been updated successfully.`,
          },
        },
        'success',
      );
    }),
  getItems: ({ commit, state }, query) =>
    packagesApi.getItems(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = {
        items: data,
        itemsMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);

      return nextItems;
    }),
  setActiveItem: ({ commit, state }, packageItem) => {
    const nextState = {
      ...state,
      activeItem: {
        ...state.activeItem,
        ...packageItem,
      },
    };

    return commit('SET', nextState);
  },
  getLabels: ({ commit, state }, query) =>
    packagesApi.getLabels(query).then((res) => {
      const { activeItem } = state;
      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          labels: res.data.data,
        },
      };

      return commit('SET', nextState);
    }),
  addLabels: (context, query) =>
    packagesApi.addLabels(query).then(() => {
      actions.getLabels(context, query);

      return handleAlerts(
        {
          data: {
            message: 'Labels added successfully.',
          },
        },
        'success',
      );
    }),
  removeLabels: (context, query) =>
    packagesApi.removeLabels(query).then(() => {
      actions.getLabels(context, query);

      return handleAlerts(
        {
          data: {
            message: 'Labels removed successfully.',
          },
        },
        'success',
      );
    }),
  getPackageSubmissionsLimits: ({ commit, state }, query) =>
    packagesApi.getPackageSubmissionsLimits(query).then((res) => {
      const { activeItem } = state;
      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          packageSubmissionLimits: res.data.data,
        },
      };

      return commit('SET', nextState);
    }),
  addPackageSubmissionLimit: (context, query) =>
    packagesApi.addPackageSubmissionLimit(query).then(() => {
      actions.getPackageSubmissionsLimits(context, query);

      return handleAlerts(
        {
          data: {
            message: 'Package submission limit added successfully.',
          },
        },
        'success',
      );
    }),
  removePackageSubmissionLimit: (context, query) =>
    packagesApi.removePackageSubmissionLimit(query).then(() => {
      actions.getPackageSubmissionsLimits(context, query);

      return handleAlerts(
        {
          data: {
            message: 'Package submission limit removed successfully.',
          },
        },
        'success',
      );
    }),
  getQueueTypes: ({ commit, state }) =>
    packagesApi.getQueueTypes().then((res) => {
      const nextQueueTypes = res.data.data;
      const nextState = {
        ...state,
        queueTypes: nextQueueTypes,
      };

      return commit('SET', nextState);
    }),
  getQueues: ({ commit, state }, query) =>
    packagesApi.getQueues(query).then((res) => {
      const { activeItem } = state;
      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          queues: res.data.data,
        },
      };

      return commit('SET', nextState);
    }),
  addQueue: (context, query) =>
    packagesApi.addQueue(query).then(() => {
      actions.getQueues(context, query);

      return handleAlerts(
        {
          data: {
            message: 'Queue added successfully.',
          },
        },
        'success',
      );
    }),
  removeQueue: (context, query) =>
    packagesApi.removeQueue(query).then(() => {
      actions.getQueues(context, query);

      return handleAlerts(
        {
          data: {
            message: 'Queue removed successfully.',
          },
        },
        'success',
      );
    }),
  reset: ({ commit }) => commit('SET', initialState.packages),
  set: ({ commit }, packages) => {
    commit('SET', packages);
  },
};

const mutations = {
  SET(state, packages) {
    /* eslint-disable no-param-reassign */
    state.activeItem = packages.activeItem;
    state.items = packages.items;
    state.itemsMeta = packages.itemsMeta;
    state.queueTypes = packages.queueTypes;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
