// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
import initialState from '../initialState';

const schedulesApi = apiClient.merchy.schedules;

/**
 * @description Is valid
 * @param bumps
 * @returns {boolean}
 */
const isValid = (bumps) => {
  const { items, itemsMeta, filters, pages } = bumps;

  return isArr(items) && isObj(itemsMeta) && isArr(filters) && isArr(pages);
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial bumps state');
  }

  const { items, itemsMeta, filters, pages } = initialState;
  return {
    items,
    itemsMeta,
    filters,
    pages,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  filters: ({ filters }) => filters,
  pages: ({ pages }) => pages,
};

const actions = {
  getItems: ({ commit, state }, query) =>
    schedulesApi.getBumps(query).then((res) => {
      const { data, meta } = res.data;
      const nextItems = {
        items: data,
        itemsMeta: meta,
      };

      const nextState = {
        ...state,
        ...nextItems,
      };

      commit('SET', nextState);

      return nextItems;
    }),
  getItem: (context, query) =>
    schedulesApi.getBump(query).then((res) => res.data.data),
  createItem: (context, query) =>
    schedulesApi.createBump(query).then((res) => res.data.data),
  updateItem: (context, query) =>
    schedulesApi.updateBump(query).then((res) => res.data.data),
  removeItem: (context, query) =>
    schedulesApi.removeBump(query).then((res) => res.data.data),
  getItemDates: (context, query) =>
    schedulesApi.getBumps(query).then((res) =>
      res.data.data.map((item) => ({
        id: item.id,
        start_date: item.action_time,
      })),
    ),
  getFilters: ({ commit, state }, query) =>
    schedulesApi.getBumpFilters(query).then((res) => {
      const nextFilters = res.data.data;
      const nextState = {
        ...state,
        filters: nextFilters,
      };

      commit('SET', nextState);

      return nextFilters;
    }),
  getPages: ({ commit, state }, query) =>
    schedulesApi.getBumpPages(query).then((res) => {
      const nextPages = res.data.data;
      const nextState = {
        ...state,
        pages: nextPages,
      };

      commit('SET', nextState);

      return nextPages;
    }),
  reset: ({ commit }) => commit('SET', initialState.bumps),
  set: ({ commit }, bumps) => {
    commit('SET', bumps);
  },
};

const mutations = {
  SET(state, bumps) {
    /* eslint-disable no-param-reassign */
    state.items = bumps.items;
    state.itemsMeta = bumps.itemsMeta;
    state.filters = bumps.filters;
    state.pages = bumps.pages;
  },
};

export default (initialState) => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
