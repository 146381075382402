export default {
  accountingApps: {
    items: [],
  },
  alerts: {
    info: [],
    success: [],
    warning: [],
    error: [],
  },
  auth: {
    token: {},
    user: {},
    role: {},
    permissions: [],
    redirects: {
      index: '/',
      unauthenticated: '/login',
      authenticated: '/threads',
    },
    sourceRoute: {},
  },
  siteMessages: {
    activeItem: {},
    items: [],
    itemsMeta: {},
    statuses: [],
    presentableItems: [],
  },
  billing: {
    activeItem: {
      log: {
        items: [],
        meta: {},
      },
    },
    items: [],
    itemsMeta: {},
    conflicts: [],
    conflictsMeta: {},
  },
  bumps: {
    items: [],
    itemsMeta: {},
    filters: [],
    pages: [],
  },
  businesses: {
    activeItem: {
      settings: {},
      billingAccounts: [],
      billingCountriesByStatus: [],
      labels: [],
      comments: [],
      campaigns: [],
      threadSubmissions: [],
      enabledPromotions: [],
      threadSubmissionLimits: [],
      notifications: [],
      keyAccounts: [],
      accountManagers: [],
      businessAdvertisers: [],
      billingAccountAdvertisers: [],
      businessMerchants: [],
      businessCpmPrices: [],
      businessFreeImpressions: [],
      businessPlacements: [],
      notes: [],
      log: {
        items: [],
        meta: {},
      },
    },
    items: [],
    itemsMeta: {},
    statuses: [],
    notificationTypes: [],
    enabledCountries: [],
  },
  businessUsers: {
    items: [],
    itemsMeta: {},
    limits: [],
    enabledPromoCountries: [],
    submissionLimits: {},
    settings: {},
    statuses: [],
  },
  cannedReplies: {
    activeItem: {},
    items: [],
    itemsMeta: {},
  },
  config: {
    languages: [],
    timezones: [],
    countries: [],
    euCountries: [],
    colors: [],
    notificationAssignments: [],
    notificationAccountTypes: [],
  },
  contracts: {
    activeItem: {
      log: {
        items: [],
        meta: {},
      },
      prices: [],
    },
    contractTypes: [],
    domains: [],
    items: [],
    itemsMeta: {},
    expiringItems: [],
    expiringItemsMeta: {},
    statuses: [],
    paymentTypes: [],
  },
  creatorProgram: {
    activeBillingAccount: {},
    activeBusiness: {},
    activeInvoice: {},
    businesses: [],
    businessesMeta: {},
    billingAccounts: [],
    billingAccountsMeta: {},
    invoices: [],
    invoicesMeta: {},
  },
  events: {
    assets: [],
    assetsMeta: {},
    activeAsset: {},
    assetsColours: [],
    assetsImageTypes: [],
    promotions: [],
    promotionsMeta: {},
    activePromotion: {},
    promotionsStatuses: [],
    promotionsPlacements: [],
    promotionsTypes: [],
  },
  invoices: {
    accounts: [],
    activeAccount: [],
    activeItem: {
      log: {
        items: [],
        meta: {},
      },
    },
    items: [],
    itemsMeta: {},
    statuses: [],
  },
  labels: {
    activeItem: {},
    items: [],
    itemsMeta: {},
    systemItems: [],
    systemItemsMeta: {},
  },
  locale: {
    language: 'en',
  },
  networks: {
    items: [],
    itemsMeta: {},
    statuses: [],
    linkedCountries: [],
  },
  packages: {
    activeItem: {
      labels: [],
      packageSubmissionLimits: [],
      queues: [],
    },
    items: [],
    itemsMeta: {},
    queueTypes: [],
  },
  payments: {
    activeItem: {},
    items: [],
    itemsMeta: {},
    statuses: [],
  },
  pepper: {
    merchants: [],
    groups: [],
    events: [],
  },
  pins: {
    items: [],
    itemsMeta: {},
    platforms: [],
    visibilities: [],
    pages: [],
    pageObjects: [],
  },
  popups: {
    activeItem: {},
    items: [],
    itemsMeta: {},
    presentableItems: [],
    visiblePopup: {},
  },
  promotions: {
    activeItem: {},
    items: [],
    itemsMeta: {},
    activeLineItem: {},
    advertisers: [],
    advertisersMeta: {},
    statuses: [],
    orderStatuses: [],
    lineStatuses: [],
    types: [],
    costTypes: [],
    placements: [],
    geo: [],
    devices: [],
    targetGroups: [],
    targetUsers: [],
    freqTimeUnits: [],
    threads: [],
    businessMerchants: [],
    forecast: [],
    accountTypes: [],
    contractNames: [],
    orderTypes: [],
  },
  reports: {
    invoices: [],
    daily: {
      items: [],
      meta: {},
    },
    dailyCpm: {
      items: [],
      meta: {},
    },
    advancedDaily: {
      items: [],
      meta: {},
    },
    monthly: [],
    accounts: [],
    contracts: [],
    advancedContracts: [],

    engagements: [],
    engagementsSingle: [],
    deal: [],
    deals: [],
    dealsMeta: {},
    topDeals: [],
    chartSummary: [],
    chartSummarySingle: [],
    statuses: [],
    submissionTypes: [],
  },
  sponsorships: {
    items: [],
    itemsMeta: {},
    statuses: [],
  },
  schedules: {
    threads: [],
    threadsMeta: [],
  },
  settings: {
    limits: [],
  },
  shops: {
    activeItem: {
      log: {
        items: [],
        meta: {},
      },
    },
    items: [],
    itemsMeta: {},
    statuses: [],
  },
  tasks: {
    items: [],
    itemsMeta: {},
    filters: [],
    statuses: [],
    types: [],
    actions: [],
  },
  threads: {
    activeItem: {
      pendingComment: '',
      labels: [],
      merchants: [],
      comments: [],
      duplicates: [],
      log: {
        items: [],
        meta: {},
      },
      hasUpdatedStatus: false,
    },
    items: [],
    itemsMeta: {},
    priorities: [],
    archived: {
      items: [],
      meta: {},
    },
    statuses: [],
    filterStatuses: [],
    categories: [],
    accountTypes: [],
    submissionStatuses: [],
    commentPermissions: [],
    priceComparison: [],
    discountTypes: [],
    queues: [],
    isDirtyDeal: false,
    isDirtyCampaign: false,
  },
  uris: {
    activeItem: {
      log: {
        items: [],
        meta: {},
      },
    },
    items: [],
    itemsMeta: {},
  },
  users: {
    activeItem: {
      settings: {},
      notifications: [],
      log: {
        items: [],
        meta: {},
      },
    },
    items: [],
    itemsMeta: {},
    internalItems: [],
    internalItemsMeta: {},
    pendingItems: [],
    pendingItemsMeta: {},
    roles: [],
    countries: [],
    statuses: [],
    managers: [],
    notificationTypes: [],
    assignable: [],
    assignmentTypes: [],
    subRoles: [],
  },
};
