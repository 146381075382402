import apiClient from '../../api';
import initialState from '../initialState';
import { isObj, isArr } from '@/utils';
import store from '..';

const eventsApi = apiClient.merchy.events;

/**
 * @description Is valid
 * @param items
 * @returns {boolean}
 */
const isValid = (items) => {
  const {
    assets,
    assetsMeta,
    activeAsset,
    assetsColours,
    assetsImageTypes,
    promotions,
    promotionsMeta,
    activePromotion,
    promotionsStatuses,
    promotionsPlacements,
    promotionsTypes,
  } = items;

  return isArr(assets)
    && isObj(assetsMeta)
    && isObj(activeAsset)
    && isArr(assetsColours)
    && isArr(assetsImageTypes)
    && isArr(promotions)
    && isObj(promotionsMeta)
    && isObj(activePromotion)
    && isArr(promotionsStatuses)
    && isArr(promotionsPlacements)
    && isArr(promotionsTypes);
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = initialState => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial events state');
  }

  const {
    assets,
    assetsMeta,
    activeAsset,
    assetsColours,
    assetsImageTypes,
    promotions,
    promotionsMeta,
    activePromotion,
    promotionsStatuses,
    promotionsPlacements,
    promotionsTypes
  } = initialState;

  return {
    assets,
    assetsMeta,
    activeAsset,
    assetsColours,
    assetsImageTypes,
    promotions,
    promotionsMeta,
    activePromotion,
    promotionsStatuses,
    promotionsPlacements,
    promotionsTypes,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  assets: ({ assets }) => assets,
  assetsMeta: ({ assetsMeta }) => assetsMeta,
  activeAsset: ({ activeAsset }) => activeAsset,
  assetsColours: ({ assetsColours }) => assetsColours,
  assetsImageTypes: ({ assetsImageTypes }) => assetsImageTypes,
  promotions: ({ promotions }) => promotions,
  promotionsMeta: ({ promotionsMeta }) => promotionsMeta,
  activePromotion: ({ activePromotion }) => activePromotion,
  promotionsStatuses: ({ promotionsStatuses }) => promotionsStatuses,
  promotionsPlacements: ({ promotionsPlacements }) => promotionsPlacements,
  promotionsTypes: ({ promotionsTypes }) => promotionsTypes,
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') => (
  store.dispatch('alerts/set', {
    data,
    alertType
  })
);

const actions = {
  // Assets
  getAssetsColors: ({ commit, state }, query) => (
    eventsApi.getAssetsColors(query).then((res) => {
      commit('SET', {
        ...state,
        assetsColours: res.data.data
      });

      return res.data.data;
    }).catch(e => Promise.reject(e))
  ),
  getAssetsImageTypes: ({ commit, state }, query) => (
    eventsApi.getAssetsImageTypes(query).then((res) => {
      commit('SET', {
        ...state,
        assetsImageTypes: res.data.data
      });

      return res.data.data;
    }).catch(e => Promise.reject(e))
  ),
  createAsset: (_, query) => (
    eventsApi.createAsset(query).then((res) => {
      handleAlerts({
        data: {
          message: 'Asset created successfully.'
        }
      }, 'success')

      return res;
    }).catch(e => Promise.reject(e))
  ),
  updateAsset: (_, query) => (
    eventsApi.updateAsset(query).then((res) => {
      handleAlerts({
        data: {
          message: 'Asset updated successfully.'
        }
      }, 'success')

      return res;
    }).catch(e => Promise.reject(e))
  ),
  getAsset: ({ commit, state }, query) => (
    eventsApi.getAsset(query).then((res) => {
      const nextActiveAsset = {
        ...res.data.data
      };

      const nextState = {
        ...state,
        activeAsset: nextActiveAsset
      };

      commit('SET', nextState);

      return nextActiveAsset;
    }).catch(e => Promise.reject(e))
  ),
  getAssets: ({ commit, state }, query) => (
    eventsApi.getAssets(query).then((res) => {
      const {data, meta} = res.data;

      const nextItems = {
        assets: data,
        assetsMeta: meta
      };

      const nextState = {
        ...state,
        ...nextItems
      };

      commit('SET', nextState);

      return nextItems;
    }).catch(e => Promise.reject(e))
  ),
  setActiveAsset: ({ commit, state }, asset) => {
    commit('SET', {
      ...state,
      activeAsset: asset
    });
  },

  // Promotions
  createPromotion: (_, query) => (
    eventsApi.createPromotion(query).then((res) => {
      handleAlerts({
        data: {
          message: 'Promotion created successfully.'
        }
      }, 'success')

      return res;
    }).catch(e => Promise.reject(e))
  ),
  updatePromotion: (_, query) => (
    eventsApi.updatePromotion(query).then((res) => {
      handleAlerts({
        data: {
          message: 'Promotion updated successfully.'
        }
      }, 'success')

      return res;
    }).catch(e => Promise.reject(e))
  ),
  getPromotion: ({ commit, state }, query) => (
    eventsApi.getPromotion(query).then((res) => {
      const nextActivePromotion = {
        ...res.data.data
      };

      const nextState = {
        ...state,
        activePromotion: nextActivePromotion
      };

      commit('SET', nextState);

      return nextActivePromotion;
    }).catch(e => Promise.reject(e))
  ),
  getPromotions: ({ commit, state }, query) => (
    eventsApi.getPromotions(query).then((res) => {
      const {data, meta} = res.data;

      const nextItems = {
        promotions: data,
        promotionsMeta: meta
      };

      const nextState = {
        ...state,
        ...nextItems
      };

      commit('SET', nextState);

      return nextItems;
    }).catch(e => Promise.reject(e))
  ),
  setActivePromotion: ({ commit, state }, promotion) => {
    commit('SET', {
      ...state,
      activePromotion: promotion
    });
  },
  getPromotionsStatuses: ({ commit, state }, query) => (
    eventsApi.getPromotionsStatuses(query).then((res) => {
      commit('SET', {
        ...state,
        promotionsStatuses: res.data.data
      });

      return res.data.data;
    }).catch(e => Promise.reject(e))
  ),
  getPromotionsPlacements: ({ commit, state }, query) => (
    eventsApi.getPromotionsPlacements(query).then((res) => {
      commit('SET', {
        ...state,
        promotionsPlacements: res.data.data
      });
      return res.data.data;
    }).catch(e => Promise.reject(e))
  ),
  getPromotionsTypes: ({ commit, state }, query) => (
    eventsApi.getPromotionsTypes(query).then((res) => {
      commit('SET', {
        ...state,
        promotionsTypes: res.data.data
      });
      return res.data.data;
    }).catch(e => Promise.reject(e))
  ),
  reset: ({ commit }) => (
    commit('SET', initialState.events)
  ),
  set: ({ commit }, events) => {
    commit('SET', events);
  }
};

const mutations = {
  SET(state, events) {
    /* eslint-disable no-param-reassign */
    state.assets = events.assets;
    state.assetsMeta = events.assetsMeta;
    state.activeAsset = events.activeAsset;
    state.assetsColours = events.assetsColours;
    state.assetsImageTypes = events.assetsImageTypes;
    state.promotions = events.promotions;
    state.promotionsMeta = events.promotionsMeta;
    state.activePromotion = events.activePromotion;
    state.promotionsStatuses = events.promotionsStatuses;
    state.promotionsPlacements = events.promotionsPlacements;
    state.promotionsTypes = events.promotionsTypes;
  }
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
