<template>
  <div v-if="hasCountries" class="platform-select">
    <autocomplete-custom
      class="autocomplete__one-line"
      v-model="selectedCountries"
      :items="countries"
      ref="platformSelect"
      :hide-details="hideDetails === false ? false : !errorMessages.length > 0"
      :multiple="isMultiple"
      prepend-inner-icon="public"
      item-text="platform"
      :disabled="disabled"
      no-data-text="No available platforms"
      :readonly="readonly"
      :attach="attach"
      item-value="code"
      :label="customLabel"
      :error-messages="errorMessages"
      :search-input.sync="countrySearch"
      disable-lookup
      @change="onChange"
    >
      <template v-if="!isMultiple" slot="prepend-inner">
        <PlatformIcon
          v-if="selectedCountries?.length > 0"
          :country="selectedCountries"
        />
        <v-icon v-else>
          public
        </v-icon>
      </template>

      <template v-slot:item="{ item }">
        <div class="v-list-item__content">
          <div class="v-list-item__title d-flex align-center">
            <v-checkbox
              v-if="isMultiple"
              :value="selectedCountries.includes(item.code)"
              hide-details
              clearable
              :ripple="false"
            />
            <PlatformIcon :country="item?.code" />
            <span class="ml-2">{{ item.platform }}</span>
          </div>
        </div>
      </template>
    </autocomplete-custom>
  </div>
</template>

<script>
import PlatformIcon from "@/components/PlatformIcon.vue";
import countriesOrder from '@/config/countriesOrder';

export default {
  props: {
    attach: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customCountries: {
      type: Array,
      default: () => [],
    },
    customLabel: {
      type: String,
      default: 'Platform',
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
    isMultiple: {
      type: Boolean,
      default: true,
    },
    isReplaceEmptyCountries: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [String, Array],
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    PlatformIcon
  },
  data() {
    return {
      selectedCountries: [],
      countrySearch: '',
    };
  },
  computed: {
    userCountries() {
      return this.$store.getters['users/countries'];
    },
    countries() {
      const arr = []
      if (this.customCountries.length > 0 || !this.isReplaceEmptyCountries) {
        arr.push(...this.customCountries)
      } else {
        arr.push(...this.userCountries)
      }

      arr.sort(function(a, b) {
        return countriesOrder.indexOf(a.code) - countriesOrder.indexOf(b.code);
      });

      return arr;
    },
    hasCountries() {
      return this.userCountries.length > 0;
    },
  },
  created() {
    this.selectedCountries = this.value;
  },
  watch: {
    value(newVal) {
      this.selectedCountries = newVal;
    }
  },
  methods: {
    onChange() {
      return this.$emit('input', this.selectedCountries);
    },
  },
};
</script>

<style lang="scss">
@import '../assets/styles/theme';

.platform-select {
  display: inline-block;
  position: relative;
  width: 100%;
}
</style>
