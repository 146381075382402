export default [
  { path: '/tasks', redirect: '/' },
  { path: '/tasks/*', redirect: '/' }
  // {
  //   path: '/tasks',
  //   name: 'tasks',
  //   meta: {
  //     loadState: true,
  //     auth: true,
  //     isAuthPage: false,
  //     permissions: [
  //       {
  //         subject: 'task',
  //         actions: ['list-all'],
  //       },
  //     ],
  //   },
  //   component: () => import('../../views/tasks/Index'),
  // },
  // {
  //   path: '/tasks/my',
  //   name: 'my-tasks',
  //   meta: {
  //     loadState: true,
  //     auth: true,
  //     isAuthPage: false,
  //     permissions: [
  //       {
  //         subject: 'task',
  //         actions: ['list-all'],
  //       },
  //     ],
  //   },
  //   component: () => import('../../views/tasks/My'),
  // },
  // {
  //   path: '/tasks/unassigned',
  //   name: 'unassigned-tasks',
  //   meta: {
  //     loadState: true,
  //     auth: true,
  //     isAuthPage: false,
  //     permissions: [
  //       {
  //         subject: 'task',
  //         actions: ['list-all'],
  //       },
  //     ],
  //   },
  //   component: () => import('../../views/tasks/Unnasigned'),
  // },
  // {
  //   path: '/tasks/:id(\\d+)',
  //   name: 'task-details',
  //   meta: {
  //     loadState: true,
  //     auth: true,
  //     isAuthPage: false,
  //     permissions: [
  //       {
  //         subject: 'task',
  //         actions: ['list-all'],
  //       },
  //     ],
  //   },
  //   component: () => import('../../views/tasks/Single'),
  // },
];
