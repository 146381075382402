export default [
  {
    path: '/payments',
    name: 'payments',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'payment',
          actions: ['list-all'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import( '../../views/payments/Index'),
  },
  {
    path: '/payments/:id(\\d+)',
    name: 'payment-details',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'payment',
          actions: ['view'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/payments/Single'
      ),
  },
];
