import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';

export default {
  getItems(options = {}) {
    const {
      page = 1,
      perPage = maxItemsPerPage,
      order = 'asc',
      sort = '',
      role = '',
      status = '',
      search = '',
      date_filter = '',
    } = options;

    const params = {
      page,
      sort,
      order,
      search,
    };

    if (role !== '') {
      params.role = role;
    }

    if (status !== '') {
      params.status = status;
    }

    if (date_filter !== '') {
      params.date_filter = date_filter;
    }

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/business_users/users`,
      params,
    });
  },
  getMulticountryMerchants() {
    return axios({
      method: 'get',
      url: `${apiUrl}/business_users/merchants`,
    });
  },
  getLimits() {
    return axios({
      method: 'get',
      url: `${apiUrl}/business_users/limits`,
    });
  },
  getSubmissionLimits({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/business_users/${id}/submission_limits`,
    });
  },
  getEnabledCountriesForPromotion() {
    return axios({
      method: 'get',
      url: `${apiUrl}/business_users/promotions`,
    });
  },
  getFile({ type = 'faq.pdf' } = {}) {
    const params = {
      type,
    };

    return axios({
      method: 'get',
      responseType: 'blob',
      url: `${apiUrl}/business_users/files`,
      params,
    });
  },
  getSettings() {
    return axios({
      method: 'get',
      url: `${apiUrl}/business_users/settings`,
    });
  },
  setSettings(query) {
    const { user, status, role } = query;

    return axios({
      method: 'post',
      url: `${apiUrl}/business_users/${user}/settings`,
      data: {
        status,
        role,
      },
    });
  },
  updateSettings({ settings }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/business_users/settings`,
      data: {
        ...settings,
      },
    });
  },
  changeLastBusinessId(business_id) {
    return axios({
      method: 'put',
      url: `${apiUrl}/business_users/${business_id}`,
    });
  },
  inviteUser({ user }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/business_users/invitations`,
      data: {
        ...user,
      },
    });
  },
  inviteBusinessUser(token) {
    return axios({
      method: 'post',
      url: `${apiUrl}/register/invited/${token}`,
    });
  },
  getStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/business_users/statuses`,
    });
  },
  acceptTerms() {
    return axios({
      method: 'post',
      url: `${apiUrl}/business_users/terms`,
    });
  },
  updateEnabledCommunication(query) {
    const { userId, enabled_communication } = query;

    return axios({
      method: 'post',
      url: `${apiUrl}/business_users/${userId}/account_settings`,
      data: {
        enabled_communication
      },
    });
  }
};
