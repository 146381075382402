<template>
  <div data-component-group="popup-dialog" data-component="feed-popup">
    <base-add-dialog
      icon="add_alert"
      :title="feedTitle"
      is-fullscreen
      :is-on="isFeedModalOn"
      :on-close="hideFeedModal"
    >
      <template slot="content">
        <component :is="feedForm" :on-cancel="hideFeedModal" />
      </template>
    </base-add-dialog>
  </div>
</template>

<script>
  import GoogleFeedForm from '../../forms/feeds/GoogleShopping';
  import popups from '../../../config/popups';
  import { isObj, isNum, isBool } from '../../../utils';

  export default {
    components: {
      GoogleFeedForm,
    },
    props: {
      feed: {
        type: Object,
        required: true,
      },
      isOn: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        feedConfig: {},
        isFeedModalOn: false,
        popups,
      };
    },
    computed: {
      feedTitle() {
        return this.$t(this.feedConfig.title);
      },
      feedForm() {
        return this.feedConfig.component;
      },
    },
    watch: {
      isOn(nextVal) {
        this.isFeedModalOn = nextVal;
      },
      feed: {
        handler(nextFeedItem) {
          if (isObj(nextFeedItem) && isNum(nextFeedItem.id)) {
            this.setFeedConfig();
          }
        },
        immediate: true,
      },
    },
    methods: {
      setFeedConfig() {
        const feedName = this.feed.type.name;

        this.feedConfig = this.popups.find(({ name }) => name === feedName);
      },
      hideFeedModal(dismiss = false) {
        const popup = {
          id: this.feed.id,
        };

        if (isBool(dismiss)) {
          popup.dismiss = dismiss;
        }

        return this.$store.dispatch('popups/hidePopup', popup);
      },
    },
  };
</script>
