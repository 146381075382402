export default [
  {
    path: '/contracts',
    name: 'contracts',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'contract',
          actions: ['list-all'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import( '../../views/contracts/Index'),
  },
  {
    path: '/contracts/:id(\\d+)',
    name: 'contract-details',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'contract',
          actions: ['view'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import(
         '../../views/contracts/Single'
      ),
  },
  {
    path: '/contracts/billing/:id(\\d+)',
    name: 'contract-redirect',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [{
        subject: 'contract',
        actions: ['view']
      }]
    },
    // route level code-splitting
    component: () => import( '../../views/contracts/Single'),
  },
];
