export default [
  {
    path: '/pins',
    name: 'pins',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'pin',
          actions: ['list-all'],
        },
      ],
    },
    component: () => import('../../views/pins/Index'),
  },
  {
    path: '/pins/running',
    name: 'running-pins',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'pin',
          actions: ['list-all'],
        },
      ],
    },
    component: () => import('../../views/pins/Index'),
  },
  {
    path: '/pins/:country/:id(\\d+)',
    name: 'pin-details',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'pin',
          actions: ['list-all'],
        },
      ],
    },
    component: () => import('../../views/pins/Single'),
  },
];
