export default [
  {
    path: '/site-messages',
    name: 'site-messages',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'banner',
          actions: ['list-all'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import( '../../views/siteMessages/Index'),
  },
  {
    path: '/site-messages/:id(\\d+)',
    name: 'site-messages-details',
    meta: {
      loadState: true,
      auth: true,
      isAuthPage: false,
      permissions: [
        {
          subject: 'banner',
          actions: ['view'],
        },
      ],
    },
    // route level code-splitting
    component: () =>
      import( '../../views/siteMessages/Single' ),
  },
];
